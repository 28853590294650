import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React from "react";
import './shareButtons.css'

const ShareButtons = (props) => {
  const referralData = props.referralData || {}

  return <div className="shareButtons">
    <a id="share--facebook" target="_blank" rel='noopener noreferrer'
      title="share via facebook"
      href={`https://www.facebook.com/sharer/sharer.php?u=${referralData.facebook ? referralData.facebook.message : ""}`}
      data-action="facebook" data-message="<%= referralData %>/b">
      <FacebookIcon />
    </a>
    <a id="share--email" target="_blank" rel='noopener noreferrer'
      title="share via email"
      href={`mailto:?subject=${referralData.email ? referralData.email.subject : ""}&body=${referralData.email ? referralData.email.body : ""}`}>
      <EmailIcon />
    </a>
    <a id="share--twitter" target="_blank" rel='noopener noreferrer'
      title="share via twitter"
      href={`https://twitter.com/intent/tweet?text=${referralData.twitter ? referralData.twitter.message : ""}`}>
      <TwitterIcon />
    </a>
    <a id="share--whatsapp" target="_blank" rel='noopener noreferrer'
      title="share via whatsapp"
      href={`https://wa.me/?text=${referralData.whatsapp ? referralData.whatsapp.message : ""}`}>
      <WhatsAppIcon />
    </a>
  </div>
}

export default ShareButtons
