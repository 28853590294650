import React, { Suspense } from 'react';
import './App.css';
import Order_V2 from './components/order_V2/index.js'
import Account from './components/account/index.js'
import AboutYou from './components/aboutYou/index.js'
import AboutYourChild from './components/aboutYourChild/index.js'
import CardDesign from './components/cardDesign2/index.js'
import OrderPreview from './components/orderPreview/index'
import VerifyEmail from './components/verifyEmail/index'
import FailSoft from './components/failSoft/index'
import FailHard from './components/failHard/index'
import UploadDocument from './components/uploadDocument/index'
import Success from './components/success/index'
import KycConfirm from './components/kycConfirm/index'
import Login from './components/login/index'
import Dashboard from './components/dashboard/index'
import EmailConfirm from './components/emailConfirm/index'
import Checkout from './components/checkout/index'
import AdditionalOrder from './components/additionalOrder/index'
import ReplaceCard from './components/replaceCard/index'
import Challenge from './components/challenge/index'
import ForgotPassword from './components/forgotPassword/index'
import ResetPassword from './components/resetPassword/index'
import ResetUsername from './components/resetUsername/index'
import OpenBankingConsent from './components/OpenBankingConsent/index'
import NotFound from './components/notFound/index'
import Error from './components/Error/index'
import { AppFooter } from './components/layout/index'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './store/store'
import ErrorBoundary from "./common/errorBoundary";
import { Trackers } from "./common/trackers";
import Signup from './components/signup/index';
import Gifter from './components/gifting/index';
import GifterPayment from './components/gifting/payment'
import ThankYouPage from './components/activate/activateCard.js';

function App() {
  return (
    <ErrorBoundary >
      <Provider store={store} >
        <Router>
          <Trackers>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route path="/v2/" exact component={Order_V2} />
                <Route path="/v2/about-you" exact component={AboutYou} />
                <Route path="/v2/account" exact component={Account} />
                <Route path="/v2/card-design" exact component={CardDesign} />
                <Route path="/v2/about-your-child" exact component={AboutYourChild} />
                <Route path="/v2/order-preview" exact component={OrderPreview} />
                <Route path="/v2/verify-email" exact component={VerifyEmail} />
                <Route path="/v2/fail-soft" exact component={FailSoft} />
                <Route path="/v2/fail-hard" exact component={FailHard} />
                <Route path="/v2/document-upload" exact component={UploadDocument} />
                <Route path="/v2/success" exact component={Success} />
                <Route path="/v2/kyc-confirm" exact component={KycConfirm} />
                <Route path="/v2/email-confirm" exact component={EmailConfirm} />
                <Route path="/v2/login" exact component={Login} />
                <Route path="/v2/checkout" exact component={Checkout} />
                <Route path="/v2/dashboard" exact component={Dashboard} />
                <Route path="/v2/add-child" exact component={AdditionalOrder} />
                <Route path="/v2/replace-card" exact component={ReplaceCard} />
                <Route path="/v2/challenge" exact component={Challenge} />
                <Route path="/v2/help/forgot-password" exact component={ForgotPassword} />
                <Route path="/v2/help/forgot-password/:token" exact component={ForgotPassword} />
                <Route path="/v2/help/reset-password/:token" exact component={ResetPassword} />
                <Route path="/v2/help/reset-username" exact component={ResetUsername} />
                <Route path="/v2/help/forgot-username" exact component={ResetUsername} />
                <Route path="/v2/consent" exact component={OpenBankingConsent} />
                <Route path="/v2/signup" exact component={Signup} />
                <Route path="/v2/signup" exact component={Signup} />
                {/* <Route path="/v2/c" exact component={Gifter} /> */}
                <Route path="/v2/c/:code" exact component={Gifter} />
                <Route path="/v2/gifter" exact component={GifterPayment} />
                <Route path="/v2/activate" exact component={ThankYouPage} />
                <Route path="/v2/error/:code" exact component={Error} />
                <NotFound />
              </Switch>
            </Suspense>
          </Trackers>
          <AppFooter />
        </Router>
      </Provider>
     </ErrorBoundary>
  );
}

export default App;
