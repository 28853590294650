import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  btnOrange: {
    color: '#fff',
    fontWeight: '600',
    fontSize: '14px',
    width: '100%',
    backgroundColor: '#ff8300',
    border: '1px solid #ff8300'
  },
  btnGrey: {
    background: 'rgba(0,0,0,0.6)',
    border: '1px solid rgba(0,0,0,0.1)',
    color: '#fff',
    fontWeight: '600',
    fontSize: '14px',
    width: '100%'
  },
  address: {
    background: 'rgb(255, 131, 0)',
    // border: '1px solid rgba(0,0,0,0.1)',
    color: '#fff',
    fontWeight: '600',
    fontSize: '14px',
    width: '100%',
    boxShadow: 'none !important',
    border: 'none !important',
    marginTop: '3px'
  }
}));

function AuxButton(props) {
  return <DefaultButton {...props} color='grey'/>
}

function DefaultButton(props) {
  const classes = useStyles();
  let styleClass = props.aux ? classes.aux : props.color === 'grey' ? classes.btnGrey : classes.btnOrange;

  return (
    props.btnType ?
      <Button variant="contained" className={styleClass} onClick={props.onClick}
        disabled={props.disabled} id={props.id}>
        {props.name}
      </Button> :
      <Button variant="contained" className={styleClass} onClick={props.onClick}
        disabled={props.disabled} id={props.id} type="submit">
        {props.name}
      </Button>
  );
}

export {DefaultButton, AuxButton} ;

