import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ChallengeForm from './challengeForm';
// import { connect } from 'react-redux';

// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import {orderSteps} from '../../common/constants'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { scaChallenge } from "../../common/api";
import Loader from '../../ui-components/loader/loader'
import { AppHeader } from '../layout/index'
import {  getAccountStatus, setAccountId } from "../../common/constants";
import { postAPI } from "../../common/api";
import ConfirmPopup from '../../ui-components/Dialog/cancelledCards'

const Login = (props) => {
  const { t } = useTranslation();
  const { history, loginOk } = props
  const [initialValues] = useState({ code: "", label: "" })
  // const { checkout } = props.history.location.state || false
  const [error, setError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [nonceValue, setNonce] = useState('')
  const [confirmPopUp, setConfirmPopUp] = useState(false)

  const validationSchema = Yup.object({
    code: Yup.string()
      .required(`${t('field-rqd')} ${t('code-placeholder')}`),
  });


  const { nonce, email } = history.location.state || {}


  useEffect(() => {
    setNonce(nonce)
  }, [nonce])

  const submitValues = (values) => {
    setError('')
    // if (values.code.length !== 6) {
    //   setError('Invalid code')
    //   return

    // }


    // let sum = 0;
    // let value = values.code

    // while (value) {
    //   sum += value % 10;
    //   value = Math.floor(value / 10);
    // }

    // let getLastDigit = (str, num) => {
    //   return num.toString();
    // };
    // let lastone = +[...getLastDigit`${values.code}`].pop();
    // sum = sum - lastone
    // if (sum % 10 !== lastone) {
    //   setError('Invalid code')
    //   return
    // }


    scaChallenge('v2/challenge', values, nonceValue).then((data) => {
      if (data.error_id) {

        setError(data.user_message)
        return

      }

      if (data.next.includes("challenge")) {
        setError("Invalid code, Please check your email for new code.")
        setNonce(data.nonce)
        return

      }
      if (data.next.includes('consent')) {
        getConsent()
        return
      }

      loginOk()
      setAccountId(data.account_id)
      if (data.next.includes('gifter')) {
        history.goBack()
      } else if (getAccountStatus(data))
        setConfirmPopUp(true)
      else
        history.push({
          pathname: '/v2/dashboard',
          account_id: data.account_id
        })

    })


  }


  const getConsent = () => {
    if (!sessionStorage.getItem('consent_id'))
      return
    let body = { "consent_id": sessionStorage.getItem('consent_id') }
    postAPI('v2/openbanking/consent', body).then((data) => {
      if (data.error_id)
        setErrorMessage(data.user_message)
      if (data.location) {
        sessionStorage.removeItem('consent_id')
        window.location.href = data.location
      }
    })

  }


  return (
    <>
      <AppHeader />
      <div id={'challengeScreen'}>
        <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
          <h4 className="screen-title">{t('challenge.title')}</h4>
          <h5 className="screen-subtitle">{t('challenge.subtitle')}</h5>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={submitValues}>{
              (props) =>
                <ChallengeForm {...props} error={error} errorMessage={errorMessage} email={email} codeChange={() => { setError('') }}
                />
            }
          </Formik>
          {confirmPopUp && <ConfirmPopup No={() => {
            setConfirmPopUp(false);
            history.goBack();
          }} Yes={() => {
            loginOk();
            history.push('/v2/dashboard')
          }} />}

          <Loader />
        </Grid>
      </div>
    </>
  );
}


const mapDispatchToProps = dispatch => {
  return {
    loginOk: (user) => dispatch({ type: "LOGIN_OK", user: user }),
    loginFail: () => dispatch({ type: "LOGIN_FAILED" }),
  }
};
export default connect(null, mapDispatchToProps)(Login);
