import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import queryString from 'query-string';
import './index.css'
import loveEnvelope from '../../assets/images/love-envelope-swish.svg'
import { getAPI } from '../../common/api';
import { AppHeader } from '../layout/index'
import ShareButtons from "../shareButtons";
import { trackConversion } from "../../common/trackers";
import { getAccountId } from '../../common/constants';
import { DefaultButton } from '../../ui-components/button';

const useStyles = makeStyles(() => ({
    center: {
        margin: 'auto'
    },
    change: {
        marginTop: '15px',
        marginRight: '13px',
        fontWeight: 500,
        color: 'rgb(255, 131, 0)',
        float: 'right'
    },
    btn: {
        maxWidth: "250px",
        margin: 'auto'
    }
}));

const Success = (props) => {
    const { t } = useTranslation();
    const classes = useStyles()
    let params = queryString.parse(props.location.search)
    const { replacement, additional } = props.history.location.state || {}
    // const replacement = undefined
    // const additional = undefined
    const [referralData, setReferralData] = useState({})

    useEffect(() => {
        getAPI(`referrer/ref-${params.ref_code}?channel_code=`).then(data => {
            // setReferralData(data)
        })
        // let account_id = referralData.account_id
        // console.log(account_id)
        const account_id = getAccountId()
        getAPI(`account/${account_id}/refer`).then(data => {
            setReferralData(data)
        })

    }, [params.ref_code])

    useEffect(() => {
        trackConversion('CardOrderSuccess', 0)
    })

    return (
        <>
            <AppHeader success={true} />
            <Grid item xs={12} sm={10} md={8} lg={6} className={classes.center}>

                <Card className='customCard'>

                    <div className="module module-quite-tight">
                        <div className='padDiv'>
                            <div className="order-success">
                                <h3 className='tick'><span className="icon-tick"></span></h3>
                                <p className="nomargin" style={{ fontSize: '22px', display: 'flex', justifyContent: 'center' }}><b>{t('success.title')}</b></p>
                                <p style={{ color: 'rgba(0, 0, 0, 0.87)', textAlign: 'center' }}>{t('success.card-arrival')}</p>
                                {(replacement || additional) ? <div className="col-sm-10 col-sm-offset-1 col-lg-6 col-lg-offset-3">
                                    <div >
                                        <div>
                                            <p><img src={loveEnvelope} alt="" style={{ height: '50px' }} /></p>
                                        </div>


                                    </div>
                                    <div className="row">
                                        <h4 style={{ margin: '15px 0 0', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            {t('success.success1')} {replacement ? 'replacement' : 'additional'} {t('success.success2')}</h4>
                                    </div>
                                </div> :
                                    <div className="col-sm-10 col-sm-offset-1 col-lg-6 col-lg-offset-3">
                                        <div className="row">
                                            <div>
                                                <p style={{ display: 'flex', justifyContent: 'center' }}><img src={loveEnvelope} alt="" style={{ height: '50px' }} /></p>
                                                <h4>{t('success.invite')} £{params.super_bonus_amount}!</h4>
                                            </div>
                                            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <p className="nomargin"
                                                    style={{ textTransform: 'uppercase', color: '#9C9C9C', fontWeight: 'bold', fontSize: '12px' }}>{t('success.invite-code')}:</p>
                                                <p className="nomargin" style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '24px', padding: '5px', border: '1px #E3E3E3 solid', marginTop: '5px', width: '100%', textAlign: 'center' }}>{params.ref_code}</p>
                                            </div>
                                            <ShareButtons referralData={referralData} />
                                        </div>
                                        <div className="row">
                                            <p style={{ margin: '15px 0 0', color: 'rgba(0, 0, 0, 0.87)', textAlign: 'center' }}>
                                                {t('success.referral')}
                                                {/* {params.bonus_amount !== params.super_bonus_amount &&
                                                    <span>If your friends sign up to Osper within 48 hours, the normal referral bonus of £{params.bonus_amount} gets doubled!</span>
                                                } */}
                                            </p>
                                        </div>
                                    </div>}
                                <br />
                                <div className={classes.btn}>
                                    <DefaultButton onClick={() => props.history.push('/v2/dashboard')} name={t('next')} />
                                </div>
                                {/* <a href='/v2/dashboard'>{t('success.back-to-dashboard')}</a> */}
                            </div>
                        </div>
                    </div>




                </Card></Grid>
        </>)


}



export default (withTranslation()(Success));

