import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
// const { t } = this.props;
import { AppHeader } from '../layout/index'

const useStyles = makeStyles(() => ({

    container: {
        marginTop: '60px',
        fontWeight: 500,
        textAlign: 'center',
        '& h2': {
            fontSize: '40px'
        },
        '& h3': {
            fontSize: '30px'
        },
        '& p': {
            fontSize: '20px'
        }
    }
}));


const NotFound = () => {

    const { t } = useTranslation();
    const classes = useStyles()


    return (
        <>
            <AppHeader />


            <div className={classes.container}>
                <span className="icon-warning" style={{ fontSize: '50px' }}></span>
                <br />
                <br />
                <h3>404</h3>
                <br />
                <h2>{t('notFound.not_exist')}</h2>
                <br />
                <p>{t('notFound.message1')} <a href="/">{t('notFound.message2')}</a> {t('notFound.message2')} <a href="mailto:help@osper.com">help@osper.com</a>.</p>
                <br />
            </div>
        </>
    )

}


export default (withTranslation()(NotFound));

