import React, { useEffect } from 'react';
import TextField from '../../ui-components/textField/textField';
import { FormRowWithLabel, FormLabel, ErrorMessage } from '../formRows/formRows';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import FocusUtils from "../../common/focusUtils";
import { keyPressCommon } from '../../common/constants'



function DOB(props) {
    const { t } = useTranslation();

    const {
        values: {
            day,
            month,
            year,
        },
        errors,
        touched,
        handleChange,
        dobChange,
        ageError,
        isChild,
        values,
        handleBlur,
        handleOnChange,

    } = props;


    const dobChanges = (e) => {
        handleBlur(e)
        if (isChild) {
            dobChange(e,values)
            return
        }
        const currentYear = parseInt(new Date().getFullYear())
        if (!values.day || values.day > 31 || !values.month || values.month > 12
            || !values.year || values.year < (currentYear - 100) || values.year > (currentYear - 18))
            return
        dobChange(e)


    }

    const keyPress = (e) => {
        keyPressCommon(e, focus)
    }


    const keyUp = (e) => {
        if (
            focus &&
            focus.isCompleted(e.target) &&
            focus.isNumeric(e.keyCode)
        ) {
            focus.focusNext(e.target)
            e.preventDefault()
        }
    }
    let focus
    useEffect(
        () => {
            focus = FocusUtils()
        }
    )
    

    return (
        <FormRowWithLabel>

            <FormLabel>{t('date-of-birth')}</FormLabel>
            <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <TextField id="day"
                        name="day"
                        placeholder='dd'
                        helperText={touched.day ? errors.day : ""}
                        error={touched.day && Boolean(errors.day)}
                        value={day || day === 0 ? day : ''}
                        onKeyDown={keyPress}
                        onKeyUp={keyUp}
                        onChange={(e) => { if (e.target.value.length <= 2) { handleChange(e); } }}
                        variant="filled"
                        type="number"
                        onBlur={dobChanges}
                        maxLength={2}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <TextField id="month"
                        name="month"
                        placeholder='mm'
                        helperText={touched.month ? errors.month : ""}
                        error={touched.month && Boolean(errors.month)}
                        value={month || month === 0 ? month : ''}
                        onKeyDown={keyPress}
                        onKeyUp={keyUp}
                        type="number"
                        onChange={(e) => { if (e.target.value.length <= 2) { handleChange(e); } }}
                        variant="filled"
                        onBlur={dobChanges}
                        maxLength={2}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <TextField id="year"
                        name="year"
                        placeholder='yyyy'
                        helperText={touched.year ? errors.year : ""}
                        error={touched.year && Boolean(errors.year)}
                        value={year || year === 0 ? year : ''}
                        onKeyDown={keyPress}
                        onKeyUp={keyUp}
                        type="number"
                        onChange={(e) => { if (e.target.value.length <= 4) { handleChange(e); handleOnChange("age") } }}
                        variant="filled"
                        onBlur={dobChanges}
                        maxLength={4}
                    />
                </Grid>
            </Grid>
            {ageError && <ErrorMessage
                name={`year`}
                error={ageError} />}
        </FormRowWithLabel>
    );
}




export default DOB;
