import React, {  useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import { DefaultButton } from '../../ui-components/button/index';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import { FormRowWithLabel, FormLabel, FormSpacer, ErrorMessage } from '../../ui-components/formRows/formRows';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import PasswordWithSuggest from "../../ui-components/textField/passwordWithSuggest";
import TermsChecklist from '../account/termsChecklist';
import Grid from '@material-ui/core/Grid';
// import "react-datepicker/dist/react-datepicker.css";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FocusUtils from "../../common/focusUtils";
import DOB from '../../ui-components/dob';
import { keyPressCommon } from '../../common/constants';

const useStyles = makeStyles(() => ({
    root: {
        '& > *': {
        },
    },
}));

const SignupForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    // const [invitecodeValue, setInviteCode] = useState('')
    // const [invitecodeMessage, setInviteCodeMessage] = useState('')
    const {
        values: {
            first_name,
            last_name,
            email,
            // confirmEmail,
            password,
            // source,
            marketing,
            gdpr,
            address1,
            address2,
            city,
            // mobile,
            // display_name,
            postcode,
            gender
        },
        errors,
        values,
        touched,
        validateField,
        fieldChange,
        handleSubmit,
        handleChange,
        handleBlur,
        emailError,
        emailChange,
        passwordSuggestion,
        setPasswordSuggestion,
        validateEmail,
        // setFieldValue,
        getAddressesForPostCode,
        addresses,
        selectAddress,
        // numberError,
        postCodeError,
        error,
        childName
    } = props;

    // const handleNumberChange = (e, type) => {
    //     handleBlur(e)
    //     validateField(e.target.value, type)
    // }

    const handleNumberOnChange = (e, type) => {
        e.preventDefault()
        handleChange(e)
        fieldChange(type)
    }



    const keyPress = (e) => {
        keyPressCommon(e, focus)
    }


    const handleEmailChange = (e) => {
        handleBlur(e)
        validateEmail(e.target.value)
    }
    const handleEmailOnChange = (e) => {
        handleChange(e)
        emailChange(e.target.value)
    }

    // const checkReferralCode = (e) => {
    //     handleBlur(e)
    //     setInviteCodeMessage('')
    //     if (e.target.value === '') {
    //         setInviteCode('')
    //         return
    //     }
    //     getAPI(`referrer/ref-${e.target.value}?channel_code=`).then(data => {
    //         switch (data.error_id) {
    //             case 'NOT_FOUND':
    //                 setInviteCode('fail')
    //                 break
    //             case 400:
    //                 setInviteCode('fail')
    //                 break
    //             default:
    //                 if (data.referral_link) {
    //                     setInviteCode('success')
    //                 }
    //                 if (data.campaign_details && data.campaign_details.campaign_type === 'marketing')
    //                     setInviteCodeMessage(data.campaign_details.campaign_text)
    //                 break
    //         }
    //     })
    // }

    const checkPassword = (e) => {
        handleBlur(e)
        if (e.target.value.length < 8)
            return
        if (e.target.value === '') {
            setPasswordSuggestion('')
            return
        }
        let body = {
            password: e.target.value,
            user_inputs: [first_name, last_name, email]
        }
        props.checkPassword(body)
    }

    let focus
    useEffect(() => {
        focus = FocusUtils()
    })

    return (
        <Card className={classes.cardcustom}>

            <div className='padDiv'>

                <FormRowWithLabel>
                    <FormLabel></FormLabel>
                    <p>{`${t('gifter.new-loader1')} ${childName} ${t('gifter.new-loader2')}`}</p>
                </FormRowWithLabel>

                <FormRowWithLabel>
                    <FormLabel>{t('first-name')}</FormLabel>
                    <TextField id="first_name"
                        name="first_name"
                        placeholder={t('first-name')}
                        helperText={touched.first_name ? errors.first_name : ""}
                        error={touched.first_name && Boolean(errors.first_name)}
                        value={first_name}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="given-name"
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel>{t('last-name')}</FormLabel>
                    <TextField id="last_name"
                        name="last_name"
                        placeholder={t('last-name')}
                        helperText={touched.last_name ? errors.last_name : ""}
                        error={touched.last_name && Boolean(errors.last_name)}
                        value={last_name}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="family-name"
                    />
                </FormRowWithLabel>
                <FormSpacer />
                <FormRowWithLabel>
                    <FormLabel>{t('email')}</FormLabel>
                    <TextField id="email"
                        name="email"
                        placeholder={t('email')}
                        // helperText={touched.email ? errors.email : ""}
                        error={(touched.email && Boolean(errors.email)) || emailError}
                        value={email}
                        onKeyDown={keyPress}
                        onChange={handleEmailOnChange}
                        variant="filled"
                        onBlur={handleEmailChange}
                    // autoComplete="email"
                    />
                    <ErrorMessage name="email" error={emailError} />
                </FormRowWithLabel>
                {/* <FormRowWithLabel>
                    <FormLabel></FormLabel>
                    <TextField id="confirmEmail"
                        name="confirmEmail"
                        placeholder={t('confirm-email')}
                        helperText={touched.confirmEmail ? errors.confirmEmail : ""}
                        error={touched.confirmEmail && Boolean(errors.confirmEmail)}
                        value={confirmEmail}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                    // autoComplete="email"
                    />
                </FormRowWithLabel> */}
                <FormRowWithLabel>
                    <FormLabel>{t('password')}</FormLabel>
                    {/* <PasswordWithSuggest
                        id="password"
                        name="password"
                        placeholder={t('password')}
                        pattern={['all', 'all', 'all', 'all']}
                        suggestOnInit={true}
                        error={(touched.password && Boolean(errors.password)) || passwordSuggestion}
                        value={password}
                        onKeyDown={keyPress}
                        onChange={(e) => { setPasswordSuggestion(''); handleChange(e) }}
                        onDataChange={(value) => { setPasswordSuggestion(''); setFieldValue('password', value) }}
                        onBlur={checkPassword}
                        buttonLabel={t('suggest-password')}
                    /> */}
                    <TextField
                        id="password"
                        name="password"
                        type="password"
                        placeholder={t('password')}
                        error={(touched.password && Boolean(errors.password)) || passwordSuggestion}
                        value={password}
                        onKeyDown={keyPress}
                        onChange={(e) => { setPasswordSuggestion(''); handleChange(e) }}
                        onBlur={checkPassword}
                    // autoComplete="password"
                    />
                    <ErrorMessage name="password" error={passwordSuggestion} />
                </FormRowWithLabel>
                <FormSpacer />

                <DOB {...props} handleOnChange={fieldChange} dobChange={() => validateField(`${values.day}-${values.month}-${values.year}`, 'age')} />
                <FormRowWithLabel>
                    <FormLabel  optional='yes' noMargin={true}>{t('gender')}</FormLabel>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <RadioGroup aria-label="gender"
                            row
                            id="gender"
                            name="gender"
                            value={gender}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            variant="filled"
                            onBlur={handleBlur}
                        >

                            <FormControlLabel value="FEMALE" control={<Radio color="default" />} label={t('female')} />
                            <FormControlLabel value="MALE" control={<Radio color="default" />} label={t('male')} />
                        </RadioGroup>
                    </FormControl>
                    <ErrorMessage name="gender" />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel>{t('post-code')}</FormLabel>
                    <div className="postcode-component">
                        <TextField id="postcode"
                            name="postcode"
                            placeholder={t('post-code-placeholder')}
                            // helperText={touched.postcode ? errors.postcode : ""}
                            error={touched.postcode && Boolean(errors.postcode)}
                            value={postcode}
                            onKeyDown={keyPress}
                            onChange={(e) => handleNumberOnChange(e, "postcode")}
                            variant="filled"
                            onBlur={handleBlur}
                            autoComplete="billing postal-code"
                        />
                        <DefaultButton onClick={() => getAddressesForPostCode(postcode)}
                            name={t('find-address')}
                            id="postcode-btn"
                            address={true} />
                    </div>
                    <ErrorMessage name="postcode" error={postCodeError} />

                </FormRowWithLabel>
                {addresses && addresses.length > 0 && <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='address-container center'>
                        <ul className='address-selection' >
                            {addresses && addresses.map((address, i) => {
                                return (<li key={i} onClick={() => selectAddress(address, postcode, values)}>
                                    {address.address1}, {address.address2}, {address.city}

                                </li>)
                            })}
                        </ul>
                    </Grid>
                </Grid>}
                <FormRowWithLabel>
                    <FormLabel>{t('street-address-1')}</FormLabel>
                    <TextField id="address1"
                        name="address1"
                        placeholder={t('street-address-1-placeholder')}
                        helperText={touched.address1 ? errors.address1 : ""}
                        error={touched.address1 && Boolean(errors.address1)}
                        value={address1}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing address-line1"
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel optional='yes'>{t('street-address-2')}</FormLabel>
                    <TextField id="address2"
                        name="address2"
                        placeholder={t('street-address-2-placeholder')}
                        helperText={touched.address2 ? errors.address2 : ""}
                        error={touched.address2 && Boolean(errors.address2)}
                        value={address2}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing address-line2"
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel>{t('city')}</FormLabel>
                    <TextField id="city"
                        name="city"
                        placeholder={t('city-placeholder')}
                        helperText={touched.city ? errors.city : ""}
                        error={touched.city && Boolean(errors.city)}
                        value={city}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing locality"
                    />
                </FormRowWithLabel>
                <FormSpacer />
                {/* <FormRowWithLabel>
                    <FormLabel>{t('mobile-number')}</FormLabel>
                    <TextField id="mobile"
                        name="mobile"
                        placeholder={t('mobile-number-placeholder')}
                        // helperText={touched.mobile ? errors.mobile : ""}
                        error={touched.mobile && Boolean(errors.mobile)}
                        value={mobile || "07"}
                        onKeyDown={keyPress}
                        onChange={(e) => handleNumberOnChange(e, "number")}
                        variant="filled"
                        onBlur={(e) => handleNumberChange(e, "number")}
                        maxLength={11}
                        autoComplete="tel"
                    />
                    <ErrorMessage name="mobile" error={numberError} />

                </FormRowWithLabel>
                <FormSpacer /> */}

                {/* <FormRowWithLabel>
                    <FormLabel>{t('display-name')}</FormLabel>
                    <TextField id="display_name"
                        name="display_name"
                        placeholder={`${gender === 'FEMALE' ? 'Aunt' : 'Uncle'}`}
                        helperText={touched.display_name ? errors.display_name : ""}
                        error={touched.display_name && Boolean(errors.display_name)}
                        value={display_name}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                    />
                </FormRowWithLabel> */}
                {/* <FormRowWithLabel>
                    <br />
                    <span className="help">{t('display-name-placeholder') + ' ' + (gender === 'FEMALE' ? 'Aunt' : 'Uncle')}</span>
                </FormRowWithLabel> */}
                {/* <FormRowWithLabel>
                    <FormLabel optional='yes'>{t('invite-code')}</FormLabel>
                    <TextField
                        name="source"
                        placeholder={t('invite-code')}
                        id='referral-code'
                        className={invitecodeValue}
                        helperText={touched.source ? errors.source : ""}
                        error={touched.source && Boolean(errors.source)}
                        value={source}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={checkReferralCode}

                    />

                </FormRowWithLabel> */}
                {/* <FormRowWithLabel>
                    <></>
                    {invitecodeValue !== 'success' && <span className='help'>{t('invite-code-note')}</span>
                    }
                    {invitecodeValue === 'success'
                        && <>{invitecodeMessage ? <p className="referral-input-success">
                            <b>{invitecodeMessage}</b>
                        </p>
                            : <p className="referral-input-success">
                                <b>{t('invite-code-success-note1')}</b>
                                {t('invite-code-success-note2')}
                            </p>}</>}
                </FormRowWithLabel> */}

                {/* <FormSpacer /> */}
                <FormRowWithLabel className='checkbox'>
                    <FormLabel />
                    < FormControlLabel
                        id="gdprLabel"
                        control={< Checkbox checked={gdpr}
                            onChange={handleChange}
                            name="gdpr"
                            id="gdpr"
                            error={touched.gdpr && Boolean(errors.gdpr)} />}
                        label={t('gifter.consent')}
                        error={touched.gdpr && Boolean(errors.gdpr)}
                    />
                    <TermsChecklist />
                    <ErrorMessage name='marketing' />
                    <ErrorMessage name='gdpr' />
                </FormRowWithLabel>
                <FormRowWithLabel className='checkbox'>
                    <FormLabel />
                    < FormControlLabel id="marketingLabel"
                        control={< Checkbox checked={marketing} onChange={handleChange} name="marketing"
                            id="marketing"
                            error={touched.marketing && Boolean(errors.marketing)} />}
                        label={t('account.marketingConsent')}
                        error={touched.marketing && Boolean(errors.marketing)}
                    />
                </FormRowWithLabel>

                {error && <FormRowWithLabel>
                    <FormLabel></FormLabel>
                    <p className='errorMessage'>{error}</p>
                </FormRowWithLabel>}
                <FormSpacer />
                <FormRowWithLabel className='next-button-container'>
                    <FormLabel />
                    <DefaultButton onClick={handleSubmit} name={t('signup.continue')} id='next-btn'></DefaultButton>
                </FormRowWithLabel>
            </div>
        </Card>
    );
}

export default SignupForm;
