import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Loader from '../../ui-components/loader/loader'
import { DefaultButton } from '../../ui-components/button/index';
import Card from '@material-ui/core/Card';
// import './index.css'
import { AppHeader } from '../layout/index'
import { FormRowWithLabel, FormLabel, FormSpacer, ErrorMessage } from "../../ui-components/formRows/formRows";
import { trackConversion } from "../../common/trackers";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TermsChecklist from './termsChecklist'
import { getAPI } from '../../common/api'
import queryString from 'query-string';
import { Formik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    center: {
        margin: 'auto'
    },
    change: {
        marginTop: '15px',
        marginRight: '13px',
        fontWeight: 500,
        color: 'rgb(255, 131, 0)',
        float: 'right',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    policyIcon: {
        color: '#ccddff',
        width: '3em',
        height: '3em',
    }
}));

const OrderPreview = (props) => {
    const { t } = useTranslation();
    // const [error, setError] = useState(false);
    const [consent, setConsent] = useState({})
    const classes = useStyles()

    const initialValues = { checked: false }


    const validationSchema = Yup.object({
        checked: Yup.bool().oneOf([true], t('consent.terms-and-conditions')),

    });


    useEffect(() => {
        let params = queryString.parse(props.location.search)
        sessionStorage.setItem('consent_id', params['consent-id'])
        getAPI(`v2/openbanking/consent?consent_id=${params['consent-id']}`).then((data) => {
            setConsent(data)
        })

    }, [props.location.search])

    const handleSubmit = () => {
        props.history.push('/v2/login')
    }

    useEffect( () => {
        trackConversion('OpenBankingConsent',0)
      })


    return (
        <>
            <AppHeader />
            <div id={'consesntGrandScreen'}>
                <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
                    <h4 className="screen-title">{`${t('consent.title1')} ${consent.app || ''} ${t('consent.title2')}`}</h4>
                    <h5 className="screen-subtitle">{t('consent.subtitle')}</h5>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >{(props) => <Card className={classes.cardcustom}>
                        <div className='padDiv'>

                            <FormRowWithLabel className='checkbox'>

                                <FormLabel />
                                <TermsChecklist scope={consent.scope} />
                                < FormControlLabel
                                    id="checkedLabel"
                                    control={< Checkbox checked={props.values.checked}
                                        onChange={props.handleChange}
                                        name="checked"
                                        id="checked"
                                        error={props.touched.checked && Boolean(props.errors.checked)}
                                    />}
                                    // }
                                    label={t('consent.condition')}
                                    error={props.touched.checked && Boolean(props.errors.checked)}
                                />


                                <ErrorMessage name='checked' />
                            </FormRowWithLabel>
                            <FormSpacer />
                            <Grid container className='next-button-container'>
                                <FormRowWithLabel className='next-button-container'>
                                    <FormLabel />
                                    <DefaultButton onClick={props.handleSubmit} name={t('consent.next-btn')} id='next-btn'></DefaultButton>
                                </FormRowWithLabel>
                            </Grid>
                            {/* <p className='errorMessage'>{error}</p> */}
                            <Loader />
                        </div>
                    </Card>

                        }</Formik>
                </Grid>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    Children: state.children,
    Kyc: state.kyc,
    Order: state.order

})

const mapDispatchToProps = dispatch => {
    return {
        saveKyc: (value) => dispatch({ type: "SET_KYC", value }),
        saveChildren: (value) => dispatch({ type: "SET_CHILDREN", value }),
        clearSteps: () => dispatch({ type: "CLEAR_STEP" }),

    }
};



export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderPreview));
