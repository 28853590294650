import React, { useEffect, useState } from "react";
import "./activateCard.css";
import { postAPI } from "../../common/api";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Logo from "./../../assets/images/osper-logo-white.png";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

// Define the ThankYouPage component
const ThankYouPage = () => {
  // Initialize the translation hook
  const { t } = useTranslation();

  // Get the location object from the router
  const location = useLocation();

  //Spinner Style from material UI
  const classes = useStyles();

  // Parse query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Extract the 'id' parameter from the query parameters
  const id = queryParams.get("id");

  // Initialize state to keep track of activation status
  const [status, setStatus] = useState("loading");

  // useEffect hook to fetch activation data when the component mounts
  useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
      try {
        // Make a POST request to activate the account
        postAPI(`account/${id}/activate`, {}).then((data) => {
          // Update the status based on the response
          setStatus(data?.Message);
        });
      } catch (error) {
        // Log any errors to the console
        console.error(error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  // Function to determine the content to render based on the status
  const getContent = (message) => {
    switch (message) {
      case "Card already cancelled":
      case "Unable to activate the account":
      case "No active card available for this user":
        return (
          <div className="failed-content">
            <h1>{t("activate-card.already-cancelled-title")}</h1>
            <p>{t("activate-card.already-cancelled-sub1")}</p>
          </div>
        );
      case "Already activated":
        return (
          <div className="failed-content">
            <h1>{t("activate-card.already-activated-title")}</h1>
            <p>{t("activate-card.already-activated-sub1")}</p>
          </div>
        );
      case "No Inactive account identified":
        return (
          <div className="failed-content">
            <h1>{t("activate-card.invalid-account-number-title")}</h1>
            <p>{t("activate-card.invalid-account-number-sub1")}</p>
          </div>
        );
      case "loading":
        return (
          <div className="failed-content">
            <div className={classes.root}>
              <CircularProgress />
            </div>
          </div>
        );
      case "Activated successfully ":
        return (
          <div className="activate-content">
            <h1>{t("activate-card.title")}</h1>
            <p>{t("activate-card.sub1")}</p>
            <p>{t("activate-card.sub2")}</p>
            <p>{t("activate-card.sub3")}</p>
          </div>
        );
      case "Subscription created and activated successfully":
        return (
          <div className="activate-content">
            <h1>{t("activate-card.title")}</h1>
            <p>{t("activate-card.sub1")}</p>
            <p>{t("activate-card.sub2")}</p>
            <p>{t("activate-card.sub3")}</p>
          </div>
        );
      default:
        return (
          <div className="failed-content">
            <h1>{t("activate-card.failed-title")}</h1>
            <p>{t("activate-card.failed-sub1")}</p>
          </div>
        );
    }
  };

  // Render the ThankYouPage component
  return (
    <div>
      <div className="activate-container">
        <div className="activate-header">
          <a href="/" className="activate-logo">
            <img src={Logo} height={"28px"} alt="Osper Logo" />
          </a>
        </div>
        <div className="activate-flex-row-center">{getContent(status)}</div>
      </div>
    </div>
  );
};

// Export the ThankYouPage component wrapped with translation HOC
export default withTranslation()(ThankYouPage);
