import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import LoginForm from './loginForm';
// import { connect } from 'react-redux';

// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { postAPI, getAPI, scaLogin } from "../../common/api";
import Loader from '../../ui-components/loader/loader'
import { AppHeader } from '../layout/index'
import { getConsent, getAccountStatus, setAccountId } from "../../common/constants";
import ConfirmPopup from '../../ui-components/Dialog/cancelledCards'

const Login = (props) => {
  const { t } = useTranslation();
  const initialUsername = props.Kyc.email || ''
  const initialPassword = props.Kyc.password || ''
  const [initialValues] = useState({ username: initialUsername, password: initialPassword })
  const [error, setError] = useState('')
  const [isSca, setIsSca] = useState(false)
  const [data, setData] = useState(false)
  const [confirmPopUp, setConfirmPopUp] = useState(false)
  const { history, loginOk, loginFail } = props
  const consent = sessionStorage.getItem('consent_id')

  const validationSchema = Yup.object({
    username: Yup.string()
      .required(`${t('field-rqd')} ${t('user-name-placeholder')}`),
    password: Yup.string()
      .required(`${t('field-rqd')} ${t('password-placeholder')}`),
  });

  useEffect(() => {

    getSca()

  }, [])

  const getSca = () => {
    getAPI(consent ? 'v2/login?next=consent' : 'v2/login').then((data) => {
      debugger
      if (data.error_id === 'SCA_DISABLED') {
        setIsSca(true)
      }
      else { setData(data) }
    })
  }

  const submitValues = (values, { resetForm }) => {
    setError('')
    if (consent && isSca) {
      setError(t('consent.scaError'))
      return
    }

    if (isSca) {
      login(values)
      return
    }

    scaLogin(consent ? 'v2/login?next=consent' : 'v2/login', values, data.nonce).then((data) => {

      if (!data) {
        setError("server failed")
        return
      }
      if (data.error_id) {
        if (data.error_id === 'SCA_DISABLED') {
          if (consent) {
            setError(t('consent.scaError'))
            return
          }
          login(values)
          return
        }
        setError(data.user_message)
        return
      }
      resetForm()
      setAccountId(data.account_id)
      if (getAccountStatus(data))
        setConfirmPopUp(true)
      else if (data.next.includes('dashboard')) {
        loginOk()
        history.push({
          pathname: '/v2/dashboard',
          account_id: data.account_id
        })
      } else if (data.next.includes('checkout')) {
        loginOk()
        history.push('/v2/checkout')
      } else if (data.next.includes('consent')) {
        getConsent()
      } else {
        history.push({
          pathname: '/v2/challenge',
          state: {
            account_id: data.account_id,
            nonce: data.nonce,
            email: data.email,
          }
        })
      }
    })

  }

  const login = (values) => {
    debugger
    postAPI('login', values).then((data) => {
      if (data.error_id) {
        setError(data.user_message)
        loginFail()
        return
      }
      setAccountId(data.account_id)
      if (data.account_type !== 'parent') {
        setError(t('login.parent-child-error'))
        loginFail()
      } else if (!data.email_verified || !data.kyc_passed) {
        setError(t('login.kyc-error'))
        loginFail()
      } else if (getAccountStatus(data))
        setConfirmPopUp(true)
      else {
        loginOk()
        history.push({
          pathname: '/v2/dashboard',
          account_id: data.account_id
        })
      }
    })
  }

  return (
    <>
      <AppHeader />
      <div id={'loginScreen'}>
        <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
          <h4 className="screen-title">{t('login.title')}</h4>
          <h5 className="screen-subtitle">{t('login.subtitle')}</h5>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={submitValues}>{
              (props) =>
                <LoginForm {...props} error={error} />
            }
          </Formik>
          {confirmPopUp && <ConfirmPopup No={() => { setConfirmPopUp(false); getSca(); }} Yes={() => {
            loginOk();
            history.push('/v2/dashboard')
          }} />}
          <Loader />
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  Kyc: state.kyc
})
const mapDispatchToProps = dispatch => {
  return {
    loginOk: (user) => dispatch({ type: "LOGIN_OK", user: user }),
    loginFail: () => dispatch({ type: "LOGIN_FAILED" }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
