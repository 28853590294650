import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import ReactPixel from "./facebookPixel";

const Trackers = (props) => {
  const screensOrder = props.screensOrder;
  const location = useLocation();
  const startTime = Date.now();
  const OsperLog = {
    pageView: (...args) => { /*console.log(args)*/ },
    trackEvent: (...args) => { /*console.log(args)*/ }
  }

  useEffect(() => {
    trackPageView()
    trackPageViewToEvent()
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener('focusIn', trackFocusEvent)
    return function cleanup() {
      document.removeEventListener('focusIn', trackFocusEvent)
    }
  }, []
  )

  const mapLocationToPageName = {
    "/v2/": "CardOrderPageLoaded",
    "/v2/about-you": "AboutYou",
    "/v2/card-design": "CardDesign",
    "/v2/about-your-child": "AboutYourChild",
    "/v2/order-preview": "OrderPreview",
    "/v2/verify-email": "VerifyEmail",
    "/v2/fail-soft": "KycFailSoft",
    "/v2/fail-hard": "KycFailHard",
    "/v2/document-upload": "KycDocumentUpload",
    "/v2/success": "Success",
    "/v2/kyc-confirm": "KycConfirm",
    "/v2/email-confirm": "EmailVerified",
    "/v2/login": "Login",
    "/v2/checkout": "Checkout",
    "/v2/dashboard": "Dashboard",
    "/v2/add-child": "AddCard",
    "/v2/replace-card": "ReplaceCard",
    "/v2/challenge": "LoginChallenge",
    "/v2/help/reset-password": "ResetPassword",
    "/v2/help/reset-username": "ResetUsername",
    "/v2/help/forgot-password": "ForgotUsername"
  }

  const mapLocationToEvent = (screensOrder === 'B') ?
{
    "/v2/": "01.CardOrderPageLoaded",
    "/v2/card-design": "01.CardDesignLoaded",
    "/v2/about-your-child": "02.AboutYourChildLoaded",
    "/v2/account": "03.AccountSetupLoaded",
    "/v2/about-you": "04.AboutYouLoaded",
    "/v2/order-preview": "05.OrderPreviewLoaded",
    "/v2/verify-email": "06.VerifyEmailLoaded",
    "/v2/fail-soft": "07B.KycFailSoftLoaded",
    "/v2/fail-hard": "07C.KycFailHardLoaded",
    "/v2/document-upload": "08.KycDocumentUploadLoaded",
    "/v2/success": "11.CardOrderSuccess",
    "/v2/kyc-confirm": "09.KycConfirm",
    "/v2/email-confirm": "06B.EmailVerified",
    "/v2/checkout": "10.CheckoutLoaded"
  } : {
    "/v2/": "01.CardOrderPageLoaded",
    "/v2/account": "01.AccountSetupLoaded",
    "/v2/about-you": "02.AboutYouLoaded",
    "/v2/card-design": "03.CardDesignLoaded",
    "/v2/about-your-child": "04.AboutYourChildLoaded",
    "/v2/order-preview": "05.OrderPreviewLoaded",
    "/v2/verify-email": "06.VerifyEmailLoaded",
    "/v2/fail-soft": "07B.KycFailSoftLoaded",
    "/v2/fail-hard": "07C.KycFailHardLoaded",
    "/v2/document-upload": "08.KycDocumentUploadLoaded",
    "/v2/success": "11.CardOrderSuccess",
    "/v2/kyc-confirm": "09.KycConfirm",
    "/v2/email-confirm": "06B.EmailVerified",
    "/v2/checkout": "10.CheckoutLoaded"
  }

  const trackPageView = () => {
    const page = mapLocationToPageName[location.pathname] || location.pathname;
    ReactPixel && ReactPixel.pageView(page);
    window.gtag('event', 'page_view', {
      page_title: page,
      page_location: location.href,
      page_path: location.pathname,
      send_to: window.googleTrackers.analytics
    })
    OsperLog.pageView(page);
  }

  const trackPageViewToEvent = () => {
    const name = mapLocationToEvent[location.pathname] || '';
    if (name) {
      const data = {
        elapsedTimeSecs: (Date.now() - startTime) / 1000
      }
      ReactPixel && ReactPixel.trackCustom(name, data)
      window.gtag('event', 'loaded', {
        event_category: name,
        send_to: window.googleTrackers.analytics
      });
    }
  }

  const findErrorElement = ( el ) => {
    if(!el) return;
    let found;
    let ancestor = el.parentElement;
    while(ancestor && (!found || !found.length)){
      found = ancestor.querySelectorAll('.error,.Mui-error.MuiFormHelperText-filled')
      ancestor = ancestor.parentElement;
    }
    if(!found || !found.length || found.length > 1) return; // if found more than one, the ancestor is too high in the hierarchy
    let msg = found[0].innerText;
    trackValidationEvent( el.id, msg )
  }

  const trackFocusEvent = (e) => {
    const target = e.target;
    setTimeout( () => { findErrorElement(target)}, 5000);
    const event = {
      page: location.pathname,
      to: e.target && e.target.getAttribute('id'),
      from: e.relatedTarget && e.relatedTarget.getAttribute('id'),
      timestamp: Math.round(e.timeStamp * 0.001) // time from loading in seconds
    }
    ReactPixel && ReactPixel.trackCustom(e.type, event)
    window.gtag('event', event.page + ': ' + event.to, {
      event_category: e.type,
      event_label: 'from: ' + event.from,
      value: event.timestamp,
      send_to: window.googleTrackers.analytics
    });
  }

  const trackValidationEvent = (id, msg) => {
    const maxLen = 25;
    const msgAlphaOnly = msg.toLowerCase().replace(/[^a-z]/g,'');
    const event = {
      type: 'validation',
      page: location.pathname,
      to: id,
      msg: msgAlphaOnly.length > maxLen ? msgAlphaOnly.substr(0, maxLen) : msgAlphaOnly
    }
    ReactPixel && ReactPixel.trackCustom(event.type, event)
    window.gtag('event', event.page + ': ' + event.to, {
      event_category: event.type,
      event_label: event.msg,
      send_to: window.googleTrackers.analytics
    });
  }

  return <div onFocusCapture={trackFocusEvent}>{props.children}</div>

}

const trackConversion = (name, value) => {

  let trackerId = window.googleTrackers.conversions[name] || 'AW-967742788';

  window.gtag('event', 'conversion', {'send_to': trackerId, 'value': value});
  console.log('conversion ' + name + '; ' + value);
}

const trackEvent = (name) => {
    const event = {
      page: window.location.pathname,
      name: name
    }
    ReactPixel && ReactPixel.trackCustom(name, event)
    window.gtag('event', 'marker', {
      event_category: 'marker',
      event_label: name,
      send_to: window.googleTrackers.analytics
    });
}

// const mapStateToProps = state => {
//   return {
//     screensOrder: state.order
//   }
// }

export { Trackers, trackConversion, trackEvent }
