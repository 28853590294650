import { ACCOUNT_ID } from "../common/constants"

const OSPER_CARD_ORDER = 'osperCardOrderState'

const restoreStateFromCache = () => {
  // to share data between tabs localstorage is used.
  const cached = sessionStorage.getItem(OSPER_CARD_ORDER) || localStorage.getItem(OSPER_CARD_ORDER)
  if (cached) {
    try {
      const deserialised = JSON.parse(cached)
      delete (deserialised.Loader)
      return deserialised
    } catch (e) {
      console.error(e)
    }
  }
  return null
}
const cacheState = (state) => {
  try {
    localStorage.setItem(OSPER_CARD_ORDER, JSON.stringify(state))
    sessionStorage.setItem(OSPER_CARD_ORDER, JSON.stringify(state))
  } catch (e) {
    console.error(e)
  }

}

window.onunload = () => {
  // since localstorage doesnt have expiry , we clear it on tab close
  sessionStorage.setItem(OSPER_CARD_ORDER, localStorage.getItem(OSPER_CARD_ORDER));
  if (localStorage.getItem("decrypted_data"))
    sessionStorage.setItem("decrypted_data", localStorage.getItem("decrypted_data"))
  if (localStorage.getItem(ACCOUNT_ID))
    sessionStorage.setItem(ACCOUNT_ID, localStorage.getItem(ACCOUNT_ID));
  localStorage.clear()
}

window.onload = () => {
  // since we cleared localstorage on tab close and if more than one tab is open , we have to reassign the values to the localstorage
  localStorage.setItem(OSPER_CARD_ORDER, sessionStorage.getItem(OSPER_CARD_ORDER));
  if (sessionStorage.getItem("decrypted_data"))
    localStorage.setItem("decrypted_data", sessionStorage.getItem("decrypted_data"))
  if (sessionStorage.getItem(ACCOUNT_ID))
    localStorage.setItem(ACCOUNT_ID, sessionStorage.getItem(ACCOUNT_ID));
}

const initialState = restoreStateFromCache() || {
  currentStep: -1,
  steps: [
    /* steps are added by the order_V2 component using ADD_STEPS */
  ],
  kyc: {
    first_name: "",
    last_name: "",
    email: "",
    confirmEmail: "",
    password: "",
    source: "",
    postcode: "",
    address1: "",
    address2: "",
    city: "",
    mobile: "",
    // date_of_birth: null,
    display_name: "",
    day: null, month: null, year: null,
    gender: "",
    gdpr: false,
    marketing: false,
  },
  children: [],
  designs: [],
  Loader: false,
  fail: false,
  sessionAuthenticated: false,
  tc: false,
  addChildSuccess: false,
  order: 'A',
  gifterStatus: 'Login'
}
const newStep = (id) => {
  return {
    id: id,
    title: (id + '.title'),
    label: (id + '.label'),
    url: '/v2/' + id,
    completed: false,
    active: false
  }
}
const resolveNextStep = (state) => {
  return state.currentStep + 1
}

export const reducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case 'SET_KYC':
      newState.kyc = { ...action.value };
      break;
    case 'SET_CHILDREN':
      newState.children = action.value;
      break;
    case 'ADD_STEPS':
      // action.value.forEach(newId => {
      //   const exists = (newState.steps.some(step => step.id === newId))
      //   if (!exists) newState.steps.push(newStep(newId))
      // })
      // if (newState.steps && action.value.length === newState.steps.length) {
      // const arr1 = action.value.concat().sort();
      // const arr2 = newState.steps.concat().sort();
      //   console.log(arr1,arr2)
      //   let isSame = true
      //   for (let i = 0; i < arr1.length; i++) {
      //     if (arr1[i] !== arr2[i].id) {
      //       isSame = false
      //       break;
      //     }
      //   }
      //   if (!isSame) {
      //     action.value.forEach(newId => {
      //       const exists = (newState.steps.some(step => step.id === newId))
      //       if (!exists) newState.steps.push(newStep(newId))
      //     })
      //   }

      // } else {
      newState.steps = []
      action.value.forEach(newId => {
        newState.steps.push(newStep(newId))
      })
      //  }
      if (newState.steps.length > 0 && newState.currentStep < 0) newState.currentStep = 0;
      break;
    case 'SET_DESIGNS':
      newState.designs = action.value;
      break;
    case 'SET_ORDER':
      newState.order = action.value;
      break;
    case 'SET_STEP':
      newState.currentStep = action.value;
      break;
    case 'SAVE_STEP':
      let stepUpdated = { ...newState.steps[action.value.index], ...action.value }
      newState.steps[action.value.index] = stepUpdated;
      break;
    case 'CLEAR_STEP':
      newState.steps.map(step => step.completed = false)
      break;
    case 'NEXT_STEP':
      const next = resolveNextStep(newState);
      newState.currentStep = next;
      break;
    case 'API_BUSY':
      newState.Loader = action.blockUI;
      break;
    case 'API_IDLE':
      newState.Loader = false;
      break;
    case 'API_FAIL':
      newState.Loader = false;
      newState.fail = action.value;
      break;
    case 'API_FAIL_HIDE':
      newState.fail = '';
      break;
    case 'LOGIN_OK':
      newState.sessionAuthenticated = true;
      break;
    case 'LOGIN_FAILED':
      newState.sessionAuthenticated = false;
      break;
    case 'LOGOUT_OK':
      newState.sessionAuthenticated = false;
      break;
    case 'SET_TC':
      newState.tc = action.value;
      break;
    case 'ADD_CHILD_SUCCESS':
      newState.addChildSuccess = action.value;
      break;
    case 'CHANGE_GIFTER':
      newState.gifterStatus = action.value;
      break;
    default: break
  }
  cacheState(newState);
  return newState;
};




