import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation, useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
const useStyles = makeStyles(() => ({
    center: {
        margin: 'auto'
    },
    change: {
        marginTop: '15px',
        marginRight: '13px',
        fontWeight: 500,
        color: 'rgb(255, 131, 0)',
        float: 'right'
    }
}));


const FailHard = (props) => {

    const { t } = useTranslation();
    const classes = useStyles()


    return (
        <div>
            <div className="module-content heading">
                <div className="container">

                    <h4><span className="icon-tick" style={{ fontSize: '20px', marginRight: '5px' }}></span>We are sorry!</h4>
                    {/* </div> */}
                </div>
            </div>
            <Grid item xs={12} sm={10} md={8} lg={6} className={classes.center}>

                <Card className='customCard'>

                    <div className='padDiv'>
                        {/*     <div className="module module-orange module-background module-w-navigation home-bg">
                        <div >
                            <div className="ghost"></div><div className="row">
                                <div className="col-xs-12 xs-align-center">
                                    <div className="ghost"></div>
                                    <div className="module-content order-content-header">
                                        <h3>Thanks &mdash; we're checking your details...</h3>

                                        <div className="row">
                                            <div className="col-xs-12 col-lg-8 col-lg-offset-2">
                                                <p>We need to do some checks on the identity details you provided, you'll hear from us within two weeks.
                If you have any questions please contact us on <a href="mailto:help@osper.com">help@osper.com</a>.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                        <div className="module module-white module-quite-tight">

                            <div className="module-content align-center">
                                <div className="row">
                                    <Grid item xs={12} sm={10} md={10} lg={10} className={classes.center}>

                                        <h4 className="visible-xs" style={{ lineHeight: "1.4" }}>{t('fail_hard_message1')}</h4>
                                        <h3 className="hidden-xs">{t('fail_hard_message')}</h3>

                                        {/* <p>We need to do some checks on the identity details you provided, you'll hear from us within two weeks.
                If you have any questions please contact us on <a href="mailto:help@osper.com">help@osper.com</a>.</p> */}

                                        {/* <p className="text-gray">(Please make sure you check your spam folder too.)</p> */}

                                        <div className="small-print">
                                            <p>
                                                {/* We need to do some checks on the identity details you provided, you'll hear from us within two weeks. */}
                                                {t('contact_us')} <a href="mailto:help@osper.com">help@osper.com.</a></p>
                                        </div>
                                    </Grid>
                                </div>
                            </div>

                        </div>

                    </div></Card></Grid >
        </div>
    )

}



export default (withTranslation()(FailHard));



