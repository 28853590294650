import React, { useEffect, useRef, useState } from "react";
import './gallery.css'

const Gallery = ({ onSelect, height, isVisible, initItems, replacement }) => {
  // const itemsOrderFn = (a, b) => {
  //   if (a.groupname > b.groupname) return 1
  //   if (a.groupname < b.groupname) return -1
  //   if (a.label > b.label) return 1
  //   if (a.label < b.label) return -1
  //   return 0
  // }

  const [galleryHeight, setGalleryHeight] = useState(0)
  const [items, setItems] = useState([...initItems.sort()])
  const [filters, setFilters] = useState([])
  const handleResize = (h) => {
    setGalleryHeight(h)
  }
  const filterMapping = {
    groupname: {
      labelFn: i => i.groupname,
      testFn: (label) => (i) => label === i.groupname
    }
    /*

    // commenting out for now as price and groupname result in the same filter until we have more brands
    price: {
      labelFn: i => i.price === '£0.00' ? 'included' : 'extra-fee',
      testFn: (label) => (i) =>
        (label === 'included' && i.price === '£0.00') ||
        (label === 'extra-fee' && i.price !== '£0.00')
    }

     */
  }
  const createFilters = (items, mapping) => {
    const filters = [{
      prop: '',
      label: 'all',
      testFn: (_) => true,
      enabled: true
    }]
    items.forEach(item => {
      for (let prop in mapping) {
        let propMap = mapping[prop]
        let filter = {
          prop: prop,
          label: propMap.labelFn(item),
          testFn: propMap.testFn(propMap.labelFn(item)),
          enabled: false
        }
        // Make sure the filter options are unique (by label only)
        if (!filters.some(f => f.label === filter.label)) {
          filters.push(filter)
        }
      }
    })
    filters.sort((a, b) => {
      if (a.prop > b.prop) return 1
      if (a.prop < b.prop) return -1
      if (a.label > b.label) return 1
      if (a.label < b.label) return -1
      return 0
    })
    return filters;
  }
  /*
  assumes only one filter active at a time. Otherwise the logic to apply them becomes
  complex, ie. filters with the same "prop" should be joined by OR, while the ones
  of different "prop" - by AND. For instance, if we have:
  price: included, groupname: nickelodeon, groupname: ngk,
  the result filter should be:
  (price: included) AND (groupname:nickelodeon OR groupname: ngk)
   */
  const toggleFilter = i => {
    const newFilters = [...filters]
    newFilters.forEach(f => f.enabled = false)
    if (newFilters.length > i) {
      newFilters[i].enabled = !newFilters[i].enabled
      if (!newFilters[i].enabled) newFilters[0].enabled = true
    }
    applyFilters(items, newFilters)
    setFilters(newFilters)
  }

  const applyFilters = (fromItems, filters) => {
    const items = [...fromItems]
    const hasFilters = filters.length === 0
    items.forEach(item => {
      item.visible = (item.visible === false) ? false : true // normalize the property (default to true)
      let staysVisible = hasFilters || filters.some( // any enabled filter's testFn returns true
        f => f.enabled && f.testFn(item)
      )
      item.fadeOut = item.visible && !staysVisible
    })
    setItems(items)
  }

  const onTransitionEnd = (e) => {
    const newItems = [...items]
    const item = newItems[e.target.dataset.index]
    item.visible = false
    item.fadeOut = false
    setItems(newItems)
  }

  const GalleryBody = ({ height, replacement }) => {
    const itemsRef = useRef(null)
    useEffect(() => {
      if (itemsRef.current) {
        handleResize(itemsRef.current.clientHeight)
      }
    }, [])

    return !!isVisible ?
      <div className='galleryContainer' id="gallery">
        <GalleryFilters filters={filters} onClickFilter={toggleFilter} />
        <div ref={itemsRef} className="galleryItems" >
          {items.map((design, i) => <GalleryItem key={design.card_design_id}
            design={design}
            data-index={i}
            onTransitionEnd={onTransitionEnd}
            replacement={replacement}
            onSelect={onSelect} />)}
        </div>
        <div className="galleryBackdrop" style={{ height: height }}></div>
      </div> : null
  }

  useEffect(() => {
    const fs = createFilters(initItems, filterMapping)
    setFilters(fs)
    applyFilters(items, fs)
  }, [initItems])

  return <GalleryBody height={galleryHeight} items={items} replacement={replacement} />
}

const GalleryFilters = ({ filters, onClickFilter }) => {
  const onClick = (e) => {
    onClickFilter(e.target.dataset.index)
  }
  return <ul className="filters">
    {filters.map((f, i) => <li
      key={i}
      className={f.enabled ? 'enabled' : ''}
      data-index={i}
      onClick={onClick}>
      {f.label}
    </li>
    )}
  </ul>
}

const CardInfo = (props) => {
  const { design, replacement } = props
  // const isReplacingCard = true /* TODO: should be passed or set otherwise when we're in ReplacementCard flow */
  return <div className='card-info'>
    {/*    <span className='group-name'>{design.groupname}</span>
    <span className='design-name'>{design.label}</span>*/}
    {replacement ?
      <span className='price'>replacement fee: {design.replacement_card_fee ? design.replacement_card_fee : 'free'}</span> :
      <span className='price'>card cost: {design.price ? design.price : 'included'}</span>
    }
  </div>
}

const GalleryItem = ({ design, onSelect, replacement }) => {
  const cssClass = 'galleryTile ' +
    (design.visible ? '' : 'hidden ') +
    (design.fadeOut ? 'fadeOut ' : '')
  const onClick = (e) => {
    onSelect(parseInt(e.target.dataset.card_design_id))
  }

  return <div className={cssClass}>
    <CardPreview design={design}
      data-card_design_id={design.card_design_id}
      onClick={onClick}
    />
    <CardInfo design={design} replacement={replacement} />
  </div>
}

const CardPreview = (props) => {
  let { design, style, index, ...otherProps } = props;
  let localStyle = {
    backgroundImage: 'url(' + design?.url + ')'
  }
  return <div className='card-preview-container' style={style}>
    <div className='card-preview' style={localStyle} {...otherProps}></div>
    <div className='card-preview-overlay'></div>
  </div>
}
export { Gallery, CardPreview }
