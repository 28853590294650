import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import { DefaultButton } from '../../ui-components/button/index';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import { FormRowWithLabel, FormLabel, FormSpacer } from '../../ui-components/formRows/formRows';

const useStyles = makeStyles(() => ({
    root: {
        '& > *': {
        },
    },
}));

const LoginForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        values: {
            username,
            password
        },
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        error,
        gifter
    } = props;
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }

    return (
        <Card className={classes.cardcustom}>

            <div className='padDiv'>
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}>
                    <FormRowWithLabel>
                        <FormLabel>{t('email-address')}</FormLabel>
                        <TextField id="username"
                            name="username"
                            placeholder={t('user-name-placeholder')}
                            helperText={touched.username ? errors.username : ""}
                            error={touched.username && Boolean(errors.username)}
                            value={username}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            variant="filled"
                            onBlur={handleBlur}
                        />
                    </FormRowWithLabel>
                    <FormRowWithLabel>
                        <FormLabel>{t('password')}</FormLabel>
                        <TextField id="password"
                            name="password"
                            placeholder={t('password-placeholder')}
                            helperText={touched.password ? errors.password : ""}
                            error={touched.password && Boolean(errors.password)}
                            value={password}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            variant="filled"
                            onBlur={handleBlur}
                            type='password'
                        />
                    </FormRowWithLabel>
                    {error && <FormRowWithLabel>
                        <FormLabel></FormLabel>
                        <p className='errorMessage'>{error}</p>
                    </FormRowWithLabel>}
                    {!gifter && <FormRowWithLabel>
                        <FormLabel></FormLabel>
                        <p>Use your parent login details, not your child's.</p>
                    </FormRowWithLabel>}
                    <FormSpacer/>
                    <FormRowWithLabel className='next-button-container'>
                        <FormLabel />
                        <DefaultButton onClick={handleSubmit} name={t('login.title')} id='next-btn'></DefaultButton>
                    </FormRowWithLabel>
                    <FormRowWithLabel>
                        <FormLabel />
                        <br /><br /><a href='/v2/help/forgot-password'>Forgot Your Password?</a>
                    </FormRowWithLabel>
                </form>
            </div>
        </Card>
    );
}

export default LoginForm;
