import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordForm from './forgotPasswordFormForm';
// import { connect } from 'react-redux';

// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import {orderSteps} from '../../common/constants'
import { useTranslation } from 'react-i18next';
import { postAPI } from "../../common/api";
import Loader from '../../ui-components/loader/loader'
import { AppHeader } from '../layout/index'
import { Trans, withTranslation } from 'react-i18next';
import { getEmail } from '../../common/constants'
const ResetPassword = (props) => {
  const { t } = useTranslation();

  const [initialValues] = useState({ username_or_email: '' })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState(false)

  const validationSchema = Yup.object({
    username_or_email: Yup.string()
      .required(`${t('field-rqd')} ${t('osper-username')}`),
  });



  const submitValues = (values) => {
    setError('')
    postAPI('help/forgot-password', values).then((data) => {
      if (data.email) {
        setSuccess(true)
        setEmail(data.email)
        return
      } else if (data.data === 'Success') {
        setSuccess(true)
        setEmail(getEmail(values.username_or_email))
        return
      }
      if (data.error_id) {
        setError(data.user_message)
        return
      }
    })

  }

  return (
    <>
      <AppHeader />
      <div id={'resetPasswordScreen'}>
        <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
          <h4 className="screen-title">{t('forgot-password.title')}</h4>
          <h5 className="screen-subtitle">{t('forgot-password.subtitle')}</h5>
          {success ? <p className='subHead'><Trans i18nKey="mail-sent">
            {t('forgot-password.email_confirmation')} {email}
          </Trans></p> : <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={submitValues}>{
              (props) =>
                <ForgotPasswordForm {...props} error={error} />
            }
          </Formik>}
          <Loader />
        </Grid>
      </div>
    </>
  );
}

export default withTranslation()(ResetPassword);
