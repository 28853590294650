import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/const";

const useStyles = makeStyles((theme) => ({
  banner: {
    margin: "0",
    display: "flex",
    backgroundColor: "#db6044",
    alignItems: "center",
    justifyContent: "center",
    height: "52px",
    padding: "2px",
    fontSize: "16px",
    fontWeight: "600",
    color: "#ffffff",
  },
  link: {
    textDecoration: "none",
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff", // Override hover color to white
    },
    '&:visited': {
      color: "#ffffff", // Set color to white for visited links
    },
  },
}));

const BannerInfo = () => {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      We've updated our Osper Card Limits:&nbsp;
      <a
        className={classes.link}
        href={`${baseUrl}terms/osper-card-limits.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        click here
      </a>
      &nbsp;to review the changes
    </div>
  );
};

export default BannerInfo;
