import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { postAPI } from './api.js'

export default class ErrorBoundary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };

  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  backendLog(msg) {
    try {
      postAPI('log', { action: 'all', properties: msg }, false)
    } catch (e) {
      console.error("LOG_BACK_ERROR", msg, e);
    }
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error("FATAL ERROR", error, errorInfo);
    this.backendLog(errorInfo);
    this.setState({ errorInfo });
  }
  render() {
    return this.state.hasError ? <ErrorScreen info={this.state.errorInfo} /> : this.props.children;
  }

}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

  /*
  The following part has to stay in hardcoded English as when it shows,
  the React bindings may be lost and the translations may not work at all
  so we need a crude message
   */
const ErrorScreen = (props) => {
  const info = props ? JSON.stringify(props.info) : ''
  return <div className='errorScreen'>
    <h1>Something has gone very wrong!</h1>
    <div>
      <p>We are sorry! Osper's team got notified and will make sure it's fixed soon.</p>
      <p>Please <a href={window.location.href}>reload the page</a> and try again.</p>
      <p>If you are stuck, use the in-app chat icon in the bottom right corner to contact us.</p>
      <small>{info}</small>
    </div>
  </div>

}
