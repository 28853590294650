import React from 'react';


import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorBoundary from "../../common/errorBoundary";
import { useTranslation } from 'react-i18next';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Loader(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        const { type } = event || {}

        if ((props.Fail === t('session-expired')|| props.Fail ===  t('no-session')) && type !== 'click') {

            setTimeout(
                () => props.apiFailHide(),
                1500
            );
            return

        }
        props.apiFailHide()

    };


    return (
        <ErrorBoundary>
            <Backdrop className={classes.backdrop} open={props.Loader || false}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={props.Fail ? true : false} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {typeof props.Fail === "string" ? props.Fail : "ERROR: " + JSON.stringify(props.Fail)}
                </Alert>
            </Snackbar>
        </ErrorBoundary>
    );
}
const mapStateToProps = (state) => ({
    Loader: state.Loader,
    Fail: state.fail,
});
const mapDispatchToProps = dispatch => {
    return {
        apiFailHide: () => dispatch({ type: "API_FAIL_HIDE" })
    }
};



export default connect(mapStateToProps, mapDispatchToProps)(Loader);
