import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { DefaultButton } from '../../ui-components/button/index';
import { getIn, FieldArray } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
// import "react-datepicker/dist/react-datepicker.css";
// import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import { FormRowWithLabel, FormLabel, FormSpacer, ErrorMessage } from '../../ui-components/formRows/formRows';
import FocusUtils from "../../common/focusUtils";
import './aboutYourChild.css'
import { CHILD_MAX_YEAR, CHILD_MIN_YEAR } from '../../common/constants';

const useStyles = makeStyles({

    checkbox: {
        marginTop: '30px'
    },
    root: {
        width: '100%',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& hr': {
            margin: '3em 0'
        }
    },

    icon: {
        fontFamily: '"Museo Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important',
        fontSize: '15px',
        width: '100%',
        // height: 'auto',
        padding: '5px 0px',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: 'orange',
        color: 'white',
        'input:hover ~ &': {
            backgroundColor: 'orange',
        },
    },
    btnIndicator: {
        fontSize: '25px',
        margin: '0 12px',
        fontWeight: 'bold',
        position: 'absolute',
        top: '-5px'
    },
    btnIndicatorAdd: {
        fontSize: '20px',
        marginLeft: '-20px',
        fontWeight: 'bold',
        position: 'absolute',
        top: '-1px'
    },
    cardDesign: {
        width: '100%',
        display: 'grid',
        maxWidth: '392px',
        margin: 'auto'
    }

});


const AboutYourChildForm = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        validateField,
        // fieldChange,
        updateUsername,
        setUsername,
        values,
        error,
        values: {
            children
        }
    } = props;


    const handleFieldChange = (e, type, i) => {
        let value = e.target.value
        let tests = {
            'name': value => (value.length > 2),
            'username': value => (value.length > 1),
            'email': value => ((/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(value)),
            'number': value => ((/^07[0-9]{9}$/).test(value)),
            'password': value => (value.length > 6)
        }
        let success = tests[type] && tests[type](value);
        handleBlur(e)
        if (success) {
            //props.saveChildren(children)
            validateField(e.target.value, type, i, values,false)
        }
    }
    const handleOnChange = (e, type, i, child, error, arrayHelpers) => {
        if (type === 'age' && e.target.value.length > 4)
            return
        if (type === 'username')
            setUsername(i)

        if (type === 'name') {
            delete child.nameError
        }
        else if (type === 'number') {
            delete child.numberError
        } else if (type === 'age') {
            delete child.ageError
        } else if (type === 'email') {
            delete child.emailError
        } else if (type === 'username') {
            delete child.usernameError
        } else if (type === 'password') {
            delete child.passwordError
            delete child.passwordWarning
        }

        arrayHelpers.replace(i, { ...child })
        handleChange(e)

    }

    const keyPress = (e) => {
        if (focus && (
            e.keyCode === focus.KEYS.ENTER || (
                e.target.type === 'number' &&
                !focus.isNumeric(e.keyCode) &&
                !focus.isCursorMove(e.keyCode)
            ))) {
            e.preventDefault()
        }
    }

    const keyUp = (e) => {
        if (
            focus &&
            focus.isCompleted(e.target) &&
            focus.isNumeric(e.keyCode)
        ) {
            if (e.target.name.includes("year")) {
                if (CHILD_MIN_YEAR <= e.target.value && e.target.value <= CHILD_MAX_YEAR) {
                    focus.focusNext(e.target)
                }
            } else {
                focus.focusNext(e.target)
            }
            e.preventDefault()
        }
    }

    const dobChange = (e, i) => {
        handleBlur(e)
        const child = values.children[i]

        // prevent API call if the date doesn't satisfy the Pre-Conditions
        if (
            !child.day || child.day > 31 ||
            !child.month || child.month > 12 ||
            !child.year || child.year < CHILD_MIN_YEAR || child.year > CHILD_MAX_YEAR
        ) return

        validateField(`${child.day}-${child.month}-${child.year}`, 'age', i, values,false)
    }

    const hasName = (child) => {
        let res = child.first_name ? child.first_name.replace(/\s+/g, '') : "" &&
            child.last_name ? child.last_name.replace(/\s+/g, '') : "";
        return res;
    }

    let focus
    useEffect(
        () => {
            focus = FocusUtils()
        }
    )

    return (
        <Card className={classes.root}>
            {/* {children && children.map(c => c.first_name + ", ")} */}
            <div className='padDiv'>
                <FieldArray
                    name="children"
                    render={(arrayHelpers) => (
                        <div >
                            {
                                children &&
                                children.map((child, index) => (
                                    <div key={index}>
                                        <FormRowWithLabel>
                                            <FormLabel>{`${index + 1}. ${t("child-first-name")}`}</FormLabel>
                                            <TextField
                                                id={`children.${index}.first_name`}
                                                name={`children.${index}.first_name`}
                                                placeholder={`${t("child's-placeholder")} ${t('first-name-placeholder')}`}
                                                helperText={touched.first_name ? errors.first_name : ""}
                                                error={touched.first_name && Boolean(errors.first_name)}
                                                value={child.first_name}
                                                onKeyDown={keyPress}
                                                onChange={handleChange}
                                                variant="filled"
                                                onBlur={(e) => handleFieldChange(e, 'name', index)}
                                                autoComplete={`child${index}.first_name`}
                                            />
                                            <ErrorMessage name={`children[${index}].first_name`} />
                                        </FormRowWithLabel>
                                        <FormRowWithLabel>
                                            <FormLabel>{`${t("child-last-name")}`}</FormLabel>
                                            <TextField id={`children.${index}.last_name`}
                                                name={`children.${index}.last_name`}
                                                placeholder={`${t("child's-placeholder")} ${t('last-name-placeholder')}`}
                                                error={getIn(errors, `children[${index}].last_name`) && getIn(touched, `children[${index}].last_name`)}
                                                value={child.last_name}
                                                onKeyDown={keyPress}
                                                onChange={handleChange}
                                                variant="filled"
                                                autoComplete={`child${index}.last_name`}
                                                onBlur={(e) => { handleBlur(e); updateUsername(index, values) }}
                                            />
                                            <ErrorMessage name={`children[${index}].last_name`} />
                                        </FormRowWithLabel>
                                        <FormRowWithLabel>
                                            <FormLabel>{t('gender')}</FormLabel>
                                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                                <RadioGroup aria-label="gender"
                                                    id={`children.${index}.gender`}
                                                    name={`children.${index}.gender`}
                                                    error={getIn(errors, `children[${index}].gender`) && getIn(touched, `children[${index}].gender`)}
                                                    onKeyDown={keyPress}
                                                    onChange={handleChange}
                                                    value={child.gender}
                                                    autoComplete={`child${index}.gender`}
                                                    row
                                                >
                                                    <FormControlLabel value="FEMALE" control={<Radio color="default" />} label={t('girl')} />
                                                    <FormControlLabel value="MALE" control={<Radio color="default" />} label={t('boy')} />
                                                    <FormControlLabel value="DONTSAY" control={<Radio color="default" />} label={t('prefer-not-to-say')} />
                                                </RadioGroup>
                                            </FormControl>
                                            <ErrorMessage name={`children[${index}].gender`} />
                                        </FormRowWithLabel>
                                        <FormRowWithLabel>
                                            <FormLabel>{t('date-of-birth')}</FormLabel>
                                            <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12}>
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <TextField id={`children.${index}.day`}
                                                        name={`children.${index}.day`}
                                                        placeholder='dd'
                                                        error={getIn(errors, `children[${index}].day`) && getIn(touched, `children[${index}].day`)}
                                                        value={child.day || child.day === 0 ? child.day : ''}
                                                        onKeyDown={keyPress}
                                                        onKeyUp={keyUp}
                                                        onChange={(e) => { if (e.target.value.length <= 2) { handleChange(e); } }}
                                                        type="number"
                                                        variant="filled"
                                                        onBlur={e => dobChange(e, index)}
                                                        maxLength={2}
                                                        autoComplete={`child${index}.dob.day`}
                                                    />
                                                    <ErrorMessage name={`children[${index}].day`} />
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <TextField id={`children.${index}.month`}
                                                        name={`children.${index}.month`}
                                                        placeholder='mm'
                                                        error={getIn(errors, `children[${index}].month`) && getIn(touched, `children[${index}].month`)}
                                                        value={child.month || child.month === 0 ? child.month : ''}
                                                        onKeyDown={keyPress}
                                                        onKeyUp={keyUp}
                                                        onChange={(e) => { if (e.target.value.length <= 2) { handleChange(e); } }}
                                                        type="number"
                                                        variant="filled"
                                                        onBlur={e => dobChange(e, index)}
                                                        maxLength={2}
                                                        autoComplete={`child${index}.dob.month`}
                                                    />
                                                    <ErrorMessage name={`children[${index}].month`} />
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <TextField id={`children.${index}.year`}
                                                        name={`children.${index}.year`}
                                                        placeholder='yyyy'
                                                        error={getIn(errors, `children[${index}].year`) && getIn(touched, `children[${index}].year`)}
                                                        value={child.year || child.year === 0 ? child.year : ''}
                                                        onKeyDown={keyPress}
                                                        onKeyUp={keyUp}
                                                        type="number"
                                                        onChange={(e) => handleOnChange(e, 'age', index, child, child.ageError, arrayHelpers)}
                                                        variant="filled"
                                                        onBlur={e => dobChange(e, index)}
                                                        maxLength={4}
                                                        autoComplete={`child${index}.dob.year`}
                                                    />
                                                    <ErrorMessage name={`children[${index}].year`} />
                                                </Grid>
                                            </Grid>
                                            {child.ageError && <ErrorMessage
                                                name={`children.${index}.first_name`}
                                                error={child.ageError} />}
                                        </FormRowWithLabel>
                                        <div className={hasName(child) ? "expandable expand" : "expandable collapse"}>
                                            <FormRowWithLabel >
                                                <FormLabel />
                                                <span className='help'>{t('why-child-credentials')}</span>
                                            </FormRowWithLabel>
                                            <FormRowWithLabel>
                                                <FormLabel>{t('user-name')}</FormLabel>
                                                <TextField id={`children.${index}.username`}
                                                    name={`children.${index}.username`}
                                                    placeholder={t('user-name-placeholder')}
                                                    error={getIn(errors, `children[${index}].username`) && getIn(touched, `children[${index}].username`)}
                                                    value={child.username}
                                                    onKeyDown={keyPress}
                                                    onChange={(e) => handleOnChange(e, 'username', index, child, child.usernameError, arrayHelpers)}
                                                    variant="filled"
                                                    onBlur={(e) => handleFieldChange(e, 'username', index)}
                                                    autoComplete={`child${index}.username`}
                                                />
                                                <ErrorMessage name={`children[${index}].username`} error={child.usernameError} />
                                            </FormRowWithLabel>
                                            <FormRowWithLabel>
                                                <FormLabel>{t('password')}</FormLabel>
                                                {/* <PasswordWithSuggest
                                                    id={`children.${index}.password`}
                                                    name={`children.${index}.password`}
                                                    placeholder={t('password-placeholder')}
                                                    pattern={['all', 'all', 'all']}
                                                    suggestOnInit={true}
                                                    error={getIn(errors, `children[${index}].password`) && getIn(touched, `children[${index}].password`)}
                                                    value={child.password}
                                                    onKeyDown={keyPress}
                                                    onChange={(e) => handleOnChange(e, 'password', index, child, child.passwordError, arrayHelpers)}
                                                    onDataChange={(value) => { child.passwordWarning = ''; setFieldValue(`children[${index}].password`, value) }}
                                                    onBlur={(e) => handleFieldChange(e, 'password', index)}
                                                    buttonLabel={t('suggest-password')}
                                                /> */}
                                                <TextField
                                                    id={`children.${index}.password`}
                                                    name={`children.${index}.password`}
                                                    type="password"
                                                    placeholder={t('password-placeholder')}
                                                    error={getIn(errors, `children[${index}].password`) && getIn(touched, `children[${index}].password`)}
                                                    value={child.password}
                                                    onKeyDown={keyPress}
                                                    onChange={(e) => handleOnChange(e, 'password', index, child, child.passwordError, arrayHelpers)}
                                                    onBlur={(e) => handleFieldChange(e, 'password', index)}
                                                // autoComplete={`child${index}.username`}
                                                />
                                                <ErrorMessage name={`children[${index}].password`} error={child.passwordError} />
                                            </FormRowWithLabel>
                                            {child.passwordWarning &&
                                                <FormRowWithLabel>
                                                    <FormLabel />
                                                    <span className='help'>{child.passwordWarning}</span>
                                                </FormRowWithLabel>}
                                        </div>


                                        <FormRowWithLabel expandOnClick={`Does ${child.first_name || 'the child'} use an email?`}
                                            collapse={child.email ? false : true}>
                                            <FormLabel optional='yes'>{`${t("child-email")}`}</FormLabel>
                                            <TextField id={`children.${index}.email`}
                                                name={`children.${index}.email`}
                                                placeholder={t('email-placeholder')}
                                                error={getIn(errors, `children[${index}].email`) && getIn(touched, `children[${index}].email`)}
                                                value={child.email}
                                                onKeyDown={keyPress}
                                                onChange={(e) => handleOnChange(e, 'email', index, child, child.emailError, arrayHelpers)}
                                                variant="filled"
                                                onBlur={(e) => handleFieldChange(e, 'email', index)}
                                                autoComplete={`child${index}.email`}
                                            />
                                            <ErrorMessage name={`children[${index}].email`} error={child.emailError} />
                                        </FormRowWithLabel>
                                        <FormRowWithLabel expandOnClick={`Does ${child.first_name || 'the child'} have a mobile phone?`}
                                            collapse={child.mobile ? false : true}>
                                            <FormLabel optional='yes'>{`${t("child-mobile-number")}`}</FormLabel>
                                            <TextField id={`children.${index}.mobile`}
                                                name={`children.${index}.mobile`}
                                                placeholder={t('mobile-number-placeholder')}
                                                error={getIn(errors, `children[${index}].mobile`) && getIn(touched, `children[${index}].mobile`)}
                                                value={child.mobile}
                                                onKeyDown={keyPress}
                                                onChange={(e) => handleOnChange(e, 'number', index, child, child.numberError, arrayHelpers)}
                                                maxLength={11}
                                                variant="filled"
                                                onBlur={(e) => handleFieldChange(e, 'number', index)}
                                                autoComplete={`child${index}.tel`}
                                            />
                                            {/* {child.numberError && <ErrorMessageApi name={`children.${index}.mobile`} error={child.numberError} />} */}
                                            <ErrorMessage name={`children[${index}].mobile`} error={child.numberError} />
                                            {/* <FormControlLabel
                                                control={<Checkbox checked={!child.mobile ? false : child.notify} onChange={handleChange} name={`children.${index}.notify`}
                                                    disabled={!child.mobile} />}
                                                label="Send Notification"
                                            /> */}
                                        </FormRowWithLabel>
                                        {index < children.length - 1 && <hr />
                                        }
                                    </div>
                                ))
                            }
                            {error && <FormRowWithLabel>
                                <FormLabel></FormLabel>
                                <p className='errorMessage'>{error}</p>
                            </FormRowWithLabel>}
                            <FormSpacer />
                            <FormRowWithLabel className='next-button-container'>
                                <FormLabel />
                                <DefaultButton id="create-order-btn"
                                    onClick={handleSubmit}
                                    name={<div>{t('next')}</div>}>
                                </DefaultButton>
                                <FormSpacer />
                                <FormSpacer />
                            </FormRowWithLabel>
                        </div>
                    )
                    }
                />
            </div >
        </Card >
    );
}
const mapStateToProps = state => ({
    Kyc: state.kyc,
    kids: state.children
})

const mapDispatchToProps = dispatch => {
    return {
        // saveChildren: (value) => dispatch({ type: "SET_CHILDREN", value }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AboutYourChildForm));
