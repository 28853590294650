import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import './formRows.css'
import { Field, getIn } from 'formik';

const FormRowWithLabel = (props) => {
  const [expanded, setExpanded] = useState(!props.collapse)
  const expand = () => setExpanded(true)

  return <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12}>
    <Grid item xs={12} sm={3} md={3} lg={3} className="labelCell" >
      {expanded ? props.children[0] : null}
    </Grid>
    <Grid item xs={12} sm={9} md={9} lg={9} className="contentCell">
      {expanded ? props.children.slice(1) : <ExpandBtn msg={props.expandOnClick} onClick={expand} />}
    </Grid>
  </Grid >
}

const FormRowFullWidth = (props) => {
  return <Grid container spacing={1} className={props.className} >
    <Grid item xs={12} sm={12} md={12} lg={12} >
      {props.children}
    </Grid>
  </Grid>
}

const FormLabel = (props) => {
  return <p className={`formLabel ${props.noMargin ? 'nomargin' : ''}`} style={props.mail ? { position: 'absolute', top: 0 } : {}}>
    {props.children}
    {props.optional === 'yes' && <small className='xs-12'>(optional)</small>}
  </p>
}

const ConfirmLabel = (props) => {
  return <p className='formLabel label'>
    {props.children}
  </p>
}

const FormSpacer = () => {
  return <div className='formSpacer'></div>
}

const ConfirmValue = (props) => {
  return <p className='formLabel value'>{props.children}</p>
}

const ExpandBtn = (props) => {
  return <p className={"expandBtn"} onClick={props.onClick}>{props.msg}</p>
}


const ErrorMessage = ({ name, error }) => (
  error ?
    <Field
      name={name}>
      {() => {
        return <p className="error" id={`${name}-helper-text`}>{error}</p>;
      }}
    </Field>
    :
    <Field
      name={name}>
      {({ form }) => {
        const error = getIn(form.errors, name);
        const touched = getIn(form.touched, name);
        return touched && error ? <p className="error" id={`${name}-helper-text`}>{error}</p> : null;
      }}
    </Field>
);

export { FormRowFullWidth, FormRowWithLabel, FormLabel, FormSpacer, ConfirmValue, ErrorMessage, ConfirmLabel }
