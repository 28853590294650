import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultButton } from '../../ui-components/button/index';
import DialogTitle from './DialogTitile';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  replaceDialog: {
    padding: '1em',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
  replaceDialogItem: {
    padding: '1em',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      marginLeft: '0px !important'
    }
  },
  replaceDialogInfo: {
    display: 'block',
    padding: '1em',
    width: 'auto'
  }

}))

export default function ReplaceDialog(props) {

  const { t } = useTranslation();

  const classes = useStyles();

  const { close, select } = props

  return <Dialog onDismiss={close} id="dialog"
    onClear={close}
    open={true}
    aria-labelledby="alert-dialog-title">
    <DialogTitle id="alert-dialog-title" onClose={close}>
      {t('dashboard-dialog.header')}</DialogTitle>
    <DialogActions className={classes.replaceDialog}>
      <span className={classes.replaceDialogItem}>
        <DefaultButton onClick={() => select(true)} name={t('dashboard-dialog.lost-card')} />
        <small className={classes.replaceDialogInfo}>{t('dashboard-dialog.lost-card-desc')}</small>
      </span>
      <span className={classes.replaceDialogItem}>
        <DefaultButton onClick={() => select(false)} name={t('dashboard-dialog.new-design')} />
        <small className={classes.replaceDialogInfo}>{t('dashboard-dialog.new-design-desc')}</small>
      </span>
    </DialogActions>
  </Dialog>
}


