import React from "react";
import { connect } from "react-redux";
import withWidth from "@material-ui/core/withWidth";
import { useTranslation, withTranslation } from "react-i18next";

const TermsChecklist = (props) => {
  const { t } = useTranslation();
  const { scope } = props;
  let scopes = scope ? scope.split(',') : ''

  return <ul className={'tickList'}>
    {scopes.includes('balance:read') && <li><a  href="">{t('consent.terms1')}</a></li>}
    {scopes.includes('transactions:read') && <li><a href="">{t('consent.terms2')}</a></li>}
  </ul>
}

export default connect()(withWidth()(withTranslation()(TermsChecklist)));
