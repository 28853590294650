import React from "react";
import "./layout.css";
import ProcessProgress from "../../ui-components/processProgress/index";
import SuccessHeader from "../../ui-components/successHeader/index";
import logo from "./../../assets/images/osper-logo-white.png";
import { connect } from "react-redux";
import { postAPI } from "../../common/api";
import { useTranslation } from "react-i18next";
import BannerInfo from "../../ui-components/bannerInfo/bannerInfo";

const Logo = () => {
  return (
    <a href="/" className="logo">
      <img src={logo} alt="logo" />
    </a>
  );
};

const LogoutButton = ({ onClick }) => {
  return (
    <div className="logoutBtn" onClick={onClick}>
      Log Out
    </div>
  );
};

function AffiliateHeadings() {
  let referralCampaign = window.referralCampaign || "";
  let affiliates = {
    MSE: "Money Saving Expert",
    finder: "Finder.com",
  };
  var heading = "";
  if (affiliates[referralCampaign]) {
    heading = (
      <div className="affiliate heading">
        <h3>
          Get 22% off Osper PLUS a 1-month free trial, when you sign up today!
        </h3>
        <p>
          Claim your exclusive {affiliates[referralCampaign]} discount and get
          22% off Osper for life (Only £1.95 per card per month). <br />
        </p>
      </div>
    );
  } else {
    heading = <div className="affiliate heading"></div>;
  }
  return heading;
}

const AppHeader_ = ({ selected, checkout, success, history, ...props }) => {
  const onLogoutClick = () => {
    postAPI("logout");
  };

  return (
    <div className="app-header">
      <BannerInfo />
      <div className={`contents ${success ? "success" : ""}`}>
        <Logo />
        {success && <SuccessHeader />}
        {props.sessionAuthenticated && <LogoutButton onClick={onLogoutClick} />}
      </div>
      <AffiliateHeadings />
      {(selected || selected === 0) && (
        <ProcessProgress selected={selected} checkout={checkout} />
      )}
    </div>
  );
};

const AppFooter_ = () => {
  const { t } = useTranslation();
  return (
    <div className="app-footer">
      <div className={"contents"}>
        <small className="footer_content">
          &copy; {t("footer.message1")} | &copy; {t("footer.message2")}
        </small>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessionAuthenticated: state.sessionAuthenticated,
});
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "LOGOUT_OK" }),
  };
};
const AppHeader = connect(mapStateToProps, mapDispatchToProps)(AppHeader_);
const AppFooter = connect(mapStateToProps, mapDispatchToProps)(AppFooter_);
export { AppHeader, AppFooter };
