import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '../../ui-components/textField/textField';
import { getAPI } from '../../common/api'
import Card from '@material-ui/core/Card';
import { withTranslation, useTranslation } from 'react-i18next';
import withWidth from '@material-ui/core/withWidth';
import Loader from '../../ui-components/loader/loader'
import { DefaultButton } from '../../ui-components/button/index';
import { Gallery, CardPreview } from "./gallery";
import ChildrenUtils from '../../common/childrenUtils'
import './cardDesign.css'
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormRowWithLabel, FormLabel, FormSpacer } from '../../ui-components/formRows/formRows';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TermsChecklist from '../account/termsChecklist'
import { AppHeader } from '../layout/index'
import { IconButton, InputAdornment } from "@material-ui/core";
import PersonAdd from "@material-ui/icons/PersonAdd";
import { getDecryptedData, getUsername } from "../../common/constants";
import { trackConversion } from "../../common/trackers";


const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    color: '#fff',
  },
  titleBar: {
    background: 'rgba(0, 0, 0, 0.5)'
  },
  termsChanged: {
    color: 'var(--brand-main-color)',
    color: '#ff8300'
  }
}));

const CardDesign = (props) => {
  const { childLimit } = props.history.location || {}
  const additionalCard = sessionStorage.getItem('mode') === 'additional'
  const tos_accepted = sessionStorage.getItem('tos_accepted') === 'true'

  const MAX_CHILDREN = childLimit || (additionalCard && sessionStorage.getItem("child_limit")) || 3;
  const { Tc, addSteps, saveChildrenCards, loadDesigns, designs, setTc, Kyc, saveStep, history, Steps, OrderForm } = props

  const { t } = useTranslation();
  const classes = useStyles();
  const UnknownCard = {
    card_design_id: 0,
    description: "",
    label: "Unknown Card",
    price: "included",
    groupname: "osper",
    url: ""
  }

  const [designGroups, setDesignGroups] = useState([])
  const [error, setError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [name,] = useState("")
  const cardDesignsList = designGroups.reduce((groupsAcc, group) => {
    const list = groupsAcc.concat(group.designs.map(design => ({ ...design, groupname: group.groupname })))
    if (list) {
      /* temp solution until API returns correct URLs */
      list.forEach(d => d.url = d?.url?.replace('.s3.eu-west-1.amazonaws.com', ''))
      /* add extensions if missing for the DB stores raw filennames */
      list.forEach(d => d.url = d?.url?.replace(/(\.jpg)?$/, '.jpg'))
    }
    return list;
  }, [])
  const [galleryPosition, setGalleryPosition] = useState(-1)
  const [children, setChildren] = useState(props.Children)
  const [index, setIndex] = useState('')
  const [preselectedDesignId, setPreselectedDesignId] = useState(0)




  useEffect(
    () => {
      const screens = [
        { id: "card-design" },
        { id: "about-your-child" },
        { id: "checkout" }
      ]
      if (additionalCard)
        addSteps(screens.map(s => s.id))
    }, [addSteps]
  )

  useEffect(() => {
    //previously added child details will be stored in redux, to clear that data
    if (additionalCard)
      saveChildrenCards([])
  }, [])

  useEffect(()=>{
    
    const element = document.getElementById('gallery');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  },[children])

  useEffect(() => {
    // if the page is redirected from SQUID with encrypted data in the url prefill form using those data
    const decryptedData = getDecryptedData() || {}
    if (props.Children.length <= 0 && decryptedData && decryptedData.children) {

      // trackConversion('OsperforSquid', 0)
      // changing the format of incoming data to our format
      const decryptedChildrenData = decryptedData.children.slice(0, MAX_CHILDREN).map(child => ({
        ...child,
        first_name: child.first_name || "",
        last_name: child.last_name || "",
        ...(child.birth_date ? child.birth_date : { day: null, month: null, year: null }),
        gender: child.gender || 'DONTSAY',
        above_minimum_age: true, tier_id: 14,
        username: child.email || getUsername([], child, 0),
        password: "",
        email: child.email || "",
        mobile: child.mobile || ""
      }))
      setChildren([...decryptedChildrenData])
    }
    else {
      setChildren(props.Children)
    }

  }, [props.Children])

  // if (cardDesignsList && cardDesignsList.length && !preselectedDesignId) {
  //   setPreselectedDesignId(cardDesignsList[0].card_design_id)
  // }

  const ChildrenCardModules = ({ children }) => {
    return <>
      {error && <div className='help-block margin-top' id='error'>{error.error}</div>}
      {children.filter(child => child).map((child, i, all) => {
        return <ChildCardModule child={child} index={i} key={i} />
      })}
    </>
  }

  const ChildCardModule = ({ child, index }) => {
    const onSelect = (design_id) => selectDesign(index, design_id)
    const onRemove = () => removeChild(index)
    const toggleGallery = () => setGalleryPosition(index === galleryPosition ? -1 : index)
    return <>
      <Grid container className='childCardModule'>
        <Grid item xs={12} sm={3} md={3} lg={3} className='description'>
          <h3>{child.first_name}'s new card:</h3>
          <span className="cardButton removeChild" onClick={onRemove}>{t('card-design.remove-child')}</span>
          {child.card_design_id === UnknownCard.card_design_id ? null :
            <span className="cardButton changeDesign" onClick={toggleGallery}>{
              child.card_design_id ?
                t('card-design.change-design') : t('card-design.select-design')
            }</span>
          }
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9}>
          <CardPreview
            design={getDesign(child.card_design_id)}
            disabled={cardDesignsList.length < 1}
            index={index}
            onClick={toggleGallery}
          />
        </Grid>
      </Grid>
      {cardDesignsList.length < 1 ?
        <div>Loading...</div> :
        <Gallery onSelect={onSelect}
        id="gallery"
          isVisible={galleryPosition === index}
          initItems={cardDesignsList} />
      }
    </>
  }

  const PreselectionGallery = () => {
    const showPreview = !!(preselectedDesignId && !children.length);
    const showGallery = !preselectedDesignId && children.length === 0
    // const [showGallery, setShowGallery] = useState(true)
    return <>
      {showGallery && <div className="preselectionTopSpacer" />}
      <Gallery onSelect={(design_id) => { setPreselectedDesignId(design_id) }}
        isVisible={showGallery}
        initItems={cardDesignsList} />
      {!!showPreview && <FormRowWithLabel>
        <div />
        <CardPreview design={getDesign(preselectedDesignId)}
          disabled={cardDesignsList.length < 1}
          index={0}
          // onClick={() => setShowGallery(!showGallery)}
          onClick={() => setPreselectedDesignId(0)}

        />
      </FormRowWithLabel>
      }
      {/* <Gallery onSelect={(design_id) => { setPreselectedDesignId(design_id) }}
        isVisible={showGallery}
        initItems={cardDesignsList} /> */}

    </>
  }


  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      e.target.blur()
    }
  }

  const TermsAndConditions = () => {
    return (<>{!tos_accepted && additionalCard && <>
      <FormSpacer />
      <FormRowWithLabel>
        <div />
        <span className={classes.termsChanged}>{t('terms-changed')}</span>
      </FormRowWithLabel>
      <FormRowWithLabel className='checkbox'>
        <FormLabel />
        < FormControlLabel className={classes.checkbox}
          id="gdprLabel"
          control={< Checkbox checked={Tc}
            onChange={(e) => setTc(e.target.checked)}
            name="gdpr"
            id="gdpr"
          />
          }
          label={t('account.dataProcessingConsent')}
        />
        <TermsChecklist />
      </FormRowWithLabel>
    </>}</>)
  }

  const AddAnotherChild = ({handleSubmit}) => {
    // const hasSelectedCardsForExistingChildren = children.every((child) => child.card_design_id > 0)
    const hasReachedMaxChildren = (children.length >= MAX_CHILDREN)
    const message =
      hasReachedMaxChildren ?
        "Want to add more children? Please contact customer support" :
        ""
    return (
      <div className="addAnotherChildModule">
        {message ? <>
          <FormRowWithLabel>
            <div />
            <span className="message">{message}</span>
          </FormRowWithLabel>
          <TermsAndConditions />
        </>
          :
          <Formik
            enableReinitialize
            validationSchema={Yup.object({
              first_name: Yup.string()
                .required(`${t('field-rqd')} ${t("child-first-name")}`)
                .min(2, t('2-character-rqrd'))
                .max(12, t('first-name-max'))
                .matches(/^[a-zA-Z .'-]{2,30}$/, `${t('latin-letter-exclusion-error')} ${t("child's-placeholder")} ${t('first-name-placeholder')}`)
            })}
            initialValues={{ first_name: name }}
            onSubmit={handleSubmit}
          >{(props) => <>
  {children.length >= 1 && !OrderForm &&
              <>
                <FormSpacer />
                <FormRowWithLabel className='next-button-container'>
                  <FormLabel />
                  <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                </FormRowWithLabel>
                <FormSpacer />
                <FormSpacer />
              </>
            }
            <FormRowWithLabel>
              <FormLabel>{!children.length ? "Who is the card for?" : <b>Add another?</b>}</FormLabel>
              <TextField name="first_name"
                id="first_name"
                placeholder="first name of your child"
                onBlur={(e) => { props.handleBlur(e); handleClickAddChild(e) }}
                onChange={props.handleChange}
                value={props.values.name}
                helperText={props.touched.first_name ? props.errors.first_name : ""}
                error={props.touched.first_name && Boolean(props.errors.first_name)}
                InputProps={{
                  maxLength: 12,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="add child"
                        onClick={handleClickAddChild}><PersonAdd />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onKeyDown={keyPress}
                autoComplete="off" />
            </FormRowWithLabel>
            <TermsAndConditions />
            {children.length < 1 && !OrderForm &&
              <>
                <FormSpacer />
                <FormRowWithLabel className='next-button-container'>
                  <FormLabel />
                  <DefaultButton onClick={props.handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                </FormRowWithLabel>
                <FormSpacer />
                <FormSpacer />
              </>
            }
          </>}
          </Formik>
        }
      </div >
    )
  }

  useEffect(() => {
    setDesignGroups(designs)
  }, [designs])

  useEffect(() => {
    if (!designs || designs.length <= 0)
      loadDesigns()
  }, [loadDesigns])

  useEffect(() => {
    // if steps is not initialised redirect to the first page
    if (Steps.length > 0)
      setIndex(Steps.findIndex(p => p.id === "card-design"));
    else if (additionalCard) {
      history.push('/v2/card-design')
    }
    else
      history.push('/v2')

    if (OrderForm)
      setIndex(0)

  }, [Steps])


  const handleClickAddChild = (e) => {

    if ((children.filter(child => (child.first_name && !child.card_design_id))).length >= 1) {
      setErrorMessage("Please choose a design for the child")
      return
    }

    let value = e.target.value
    if(!value) return
    if (value.length < 2 || !(/^[a-zA-Z .'-]{2,30}$/).test(value) || value.length > 12) {
      return
    }
    addChild(value || "Child " + (children.length + 1), preselectedDesignId)
    //need parent name in the detail api , implement later
    if (additionalCard) {
      // addChild(value || "Child " + (children.length + 1))
      return
    }


    getAPI(`name/${Kyc.first_name ? Kyc.first_name : 'null'}/${value}`).then((data) => {
      let error = ''
      switch (data.error_id) {
        case 'INVALID_PARAM_VALUE':
          error = '' //used to be an error for the parent and child names identical
          break
        case 'ERROR':
          error = t('please-try-again')
          break
        case 'NO_CONTENT':
          error = ''
          break
        default:
          break
      }
      if (error !== '') {
        setError({ error: error, name: value })
        // e.target.value = ''
      }

    })

  }
  const addChild = (first_name, preselectedDesignId) => {
    const child = ChildrenUtils.create(first_name, preselectedDesignId)
    const newChildren = children.concat([child])
    if (!preselectedDesignId) {
      // console.log("no preselected")
      setGalleryPosition(newChildren.length - 1)
    } else {
      // console.log("deleting preselected")
      setGalleryPosition(-1)
    }
    setChildren(newChildren);
    saveChildrenCards(newChildren)
    setPreselectedDesignId(0)
    //----------------------------------------------update for new change
    if (OrderForm)
      saveStep({ completed: true, index: index })
  }

  const removeChild = (index) => {
    const newChildren = children.concat()
    newChildren.splice(index, 1)
    if ((newChildren.filter(child => child.first_name ? child.first_name === error.name : false)).length === 0)
      setError('')
    setChildren(newChildren)
    saveChildrenCards(newChildren)
  }

  const selectDesign = (childIndex, card_design_id) => {
    setGalleryPosition(-1)
    updateChild(childIndex, { card_design_id: card_design_id, open: true });
  }

  const updateChild = (i, props) => {
    const newChildren = [...children]
    if (!newChildren[i])
      newChildren[i] = ChildrenUtils.create("Your Child " + i)
    ChildrenUtils.update(newChildren[i], props)
    if (newChildren.filter(child => child.first_name && !child.card_design_id).length === 0) {
      setErrorMessage("")
    }
    setChildren(newChildren);
    saveChildrenCards(newChildren)
  }

  const getDesign = (id) => {
    const idNum = parseInt(id)
    if (!designGroups || !designGroups.length) return UnknownCard;
    const d = cardDesignsList.find(d => (d.card_design_id === idNum))
    return d || UnknownCard
  }

  const handleSubmit = () => {

    if (children.length < 1)
      return

    if ((children.filter(child => (child.first_name && !child.card_design_id))).length >= 1) {
      setErrorMessage("Please choose a design for the child")
      return
    }
    if (!Tc && !tos_accepted && additionalCard) {
      setErrorMessage(t('terms-and-conditions'))
      return
    }
    saveChildrenCards(children)
    saveStep({ completed: true, index: index })
    if (additionalCard) {
      history.push({ pathname: 'about-your-child', state: { additionalCard: true } })
    } else {
      history.push('/v2/about-your-child')
    }
  }

  return (
    <>
      <AppHeader selected={index} />
      <div id={'cardDesignScreen'} className="gallery2">
        <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
          <h4 className="screen-title">{t('card-design.title')}</h4>
          <h5 className="screen-subtitle">{t('card-design.subtitle')}</h5>

          <Card className={classes.cardcustom}>
            <div className='padDiv'>
              <ChildrenCardModules children={children} />
              {cardDesignsList.length < 1 ?
                <div>Loading...</div> : <PreselectionGallery />
              }
              <AddAnotherChild handleSubmit={handleSubmit}/>
              <FormRowWithLabel><div />
                <p className='errorMessage' id='error'>{errorMessage}</p>
              </FormRowWithLabel>
              <FormSpacer />
              {children.length >= MAX_CHILDREN && <>
                <FormRowWithLabel>
                  <FormLabel />
                  <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                </FormRowWithLabel>
                <FormSpacer />
                <FormSpacer />
              </>}
            </div>
          </Card>
          <Loader />
        </Grid>
      </div>
    </>
  )
}


const mapStateToProps = state => {
  return {
    Children: state.children,
    Kyc: state.kyc,
    designs: state.designs,
    Tc: state.tc,
    Steps: state.steps,
    screenOrder: state.order
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveChildrenCards: (value) => dispatch({ type: "SET_CHILDREN", value }),
    setTc: (value) => dispatch({ type: "SET_TC", value }),
    saveStep: (value) => dispatch({ type: "SAVE_STEP", value }),
    loadDesigns: () => dispatch({ type: "GET_DESIGNS" }),
    addSteps: (steps) => dispatch({ type: "ADD_STEPS", value: steps })

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withTranslation()(CardDesign)));
