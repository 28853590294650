import { reducer } from '../reducer/reducer';
import createSagaMidddleware from 'redux-saga';
import { getServices } from '../sagas/saga';
import { createStore, applyMiddleware, compose } from "redux";

const sagaMiddleware = createSagaMidddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));


sagaMiddleware.run(getServices);
export default store