import React from 'react'
import OrderFormIndex from './orderFormIndex';
import './index.css'

import CardDesign2 from "../cardDesign2";

const OrderForm = (props) => {

    const { history } = props

    return (
        <>
            <CardDesign2 history={history} OrderForm={true} />
            <OrderFormIndex history={history} />
        </>)
}


export default OrderForm;
