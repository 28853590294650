import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import SignupForm from './signupForm';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { postAPI, getAPI } from "../../common/api";
// import { fixBackendTypo } from '../../common/constants'
import { trackConversion } from "../../common/trackers";

const Login = (props) => {
    const { t } = useTranslation();
    const { history, loginOk, code, onSuccess, childName } = props
    const giftDetails = JSON.parse(sessionStorage.getItem('gifter_details'))
    const [initialValues, setInitalValues] = useState({
        first_name: "", last_name: "", email: giftDetails.username,
        // confirmEmail: "",
        password: "", postcode: "",
        address1: "",
        address2: "",
        city: "",
        // mobile: "",
        day: "",
        month: "", year: "",
        gender: "",
        // display_name: "",
        //  source: window.referralCode || "",
        marketing: false, gdpr: false
    })
    const [errorMessage, setErrorMessage] = useState('')
    const [error, setError] = useState('')
    const [passwordSuggestion, setPasswordSuggestion] = useState('')
    const [address, setAddress] = useState([])
    const [errorCode, setErrorCode] = useState(0)
    const [ageError, setAgeError] = useState('')
    const [postCodeError, setPostCodeError] = useState('')
    const currentYear = parseInt(new Date().getFullYear())


    const validationSchema = Yup.object({
        first_name: Yup.string()
            .required(`${t('field-rqd')} ${t('first-name-placeholder')}`)
            .min(2, t('2-character-rqrd'))
            .matches(/^[a-zA-Z .'-]{2,30}$/, `${t('latin-letter-exclusion-error')} ${t('first-name-placeholder')}`),
        last_name: Yup.string()
            .required(t(`${t('field-rqd')} ${t('last-name-placeholder')}`))
            .min(2, t('2-character-rqrd'))
            .matches(/^[a-zA-Z .'-]{2,30}$/, `${t('latin-letter-exclusion-error')} ${t('last-name-placeholder')}`),
        email: Yup.string()
            .required(`${t('field-rqd')} ${t('email-placeholder')}`)
            .email(t('email-validation')),
        // confirmEmail: Yup.string()
        //     .required(t('confirm-email-error'))
        //     .test('email-match', t('confirm-email-match'), function (value) {
        //         return this.parent.email === value;
        //     }),
        password: Yup.string()
            .required(`${t('field-rqd')} ${t('password-placeholder')}`)
            // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^(~_+){.,/`+-}])[A-Za-z\d@$!%*?&#^(~_+){.,/`+-}]{8,}$/,
            //     t('password-error')
            .min(8, t('8-character-rqrd')),
        postcode: Yup.string()
            .min(5, t('post-code-short'))
            .max(8, t('post-code-long'))
            .required(`${t('field-rqd')} ${t('post-code-text')}`),
        address1: Yup.string()
            .min(3, t('address-min'))
            .max(120, t('address-max'))
            .required(t('address1-error')),
        address2: Yup.string()
            .min(3, t('address-min'))
            .max(120, t('address-max')),
        city: Yup.string()
            .min(3, t('city-min'))
            .max(120, t('city-max'))
            .matches(/^[a-zA-Z0-9 .'-,]+$/, `${t('latin-letter-exclusion-error')} ${t('city')}`)
            .required(`${t('field-rqd')} ${t('city-placeholder')}`),
        // mobile: Yup.string()
        //     .required(`${t('field-rqd')} ${t('mobile-number-placeholder')}`)
        //     .matches(/^07[0-9]{9}$/, t('mobile-number-validation')),
        // display_name: Yup.string()
        //     .min(2, t('2-character-rqrd'))
        //     .max(12, t('display-name-validation'))
        //     .matches(/^[a-zA-Z .'-]{2,12}$/, `${t('letter-exclusion-error')} ${t('display-name')}`)
        //     .required(`${t('field-rqd')} ${t('display-name')}`),
        day: Yup.number().nullable()
            .required(`${t('field-rqd')} ${t('day')}`)
            .max(31, t('day-validation'))
            .min(1, t('day-validation')),
        month: Yup.number().nullable()
            .required(`${t('field-rqd')} ${t('month')}`)
            .max(12, t('month-validation'))
            .min(1, t('month-validation')),
        year: Yup.number().nullable()
            .required(`${t('field-rqd')} ${t('year')}`)
            .min(currentYear - 100, t('parent-age-validation'))
            .max(currentYear - 18, t('parent-age-validation')),
        // gender: Yup.string().required(t('gender-validation')),
        gdpr: Yup.bool().oneOf([true], t('terms-and-conditions')),
    });


    const submitValues = async (values) => {
        setError('')
        if (errorCode !== 0 || errorMessage || passwordSuggestion)
            return

        let response = await Promise.all([
            validatePostCode(values.postcode),
            // validateField(values.mobile, 'number'),
            validateField(`${values.day}-${values.month}-${values.year}`, 'age'),
            validateEmail(values.email),
            checkPassword({
                password: values.password,
                user_inputs: [values.first_name, values.last_name, values.email]
            })
        ]);

        if (response.filter(data => !data).length > 0)
            return

        let body = { ...values }
        // delete body.confirmEmail
        body.kyc = {
            "address1": body.address1,
            "address2": body.address2,
            "city": body.city,
            "date_of_birth": `${body.year}-${body.month}-${body.day}`,
            "first_name": body.first_name,
            ...body.gender && { "gender": body.gender },
            "last_name": body.last_name,
            "postcode": body.postcode
        }
        body.display_name = giftDetails.display_name;

        body.preferences = {
            "gdpr": body.gdpr,
            "marketing": body.marketing
        }
        // body.source = body.source ? `ref-${body.source}` : 'website'
        body.source = 'website'
        // body.mobile = '07444444443'
        delete body.gdpr;
        delete body.marketing;
        delete body.address1;
        delete body.address2;
        delete body.city;
        delete body.year;
        delete body.month;
        delete body.day;
        delete body.first_name;
        delete body.gender;
        delete body.last_name;
        delete body.postcode;


        
        postAPI(`v2/account/signup?code=${code}`, body).then(data => {
            if (Object.values(data).includes("ALREADY_USED")) {
                setError('Data entered already used')
                return
            }
            if (data.error_id) {
                setError(data.user_message)
                return
            }
            trackConversion('Signup', 0)
            switch (data.next) {
                case "checkout":
                    localStorage.setItem('orderSuccess', true)
                    loginOk()
                    onSuccess()
                    break
                case "fail_soft":
                    history.push({
                        pathname: '/v2/fail-soft',
                        email: body.email
                    })
                    break
                case "fail_hard":
                    history.push({
                        pathname: '/v2/fail-hard',
                        email: body.email
                    })
                    break
                default:
                    setError('Entered email/number is already used')
                    break
            }
        })
    }

    const checkPassword = async (body) => {
        if (/\s/g.test(body.password)) {
            setPasswordSuggestion(t('whitespace-error'))
            return false
        }
        let response = await postAPI(`password_strength`, body).then(data => {
            if (data.error_id) {
                // setError('error')
                setPasswordSuggestion(data.user_message ? data.user_message : t('please-try-again'))
                return false
            }
            // if (data.feedback) {
            //     let suggestion = data.feedback.suggestions.toString();
            //     suggestion = suggestion
            //         .concat(data.feedback.warning)
            //     suggestion = fixBackendTypo(suggestion)

            if (data.score < 3) {
                setPasswordSuggestion(data.feedback)
                // setError('error')
                return false

            }
            //}
            setPasswordSuggestion('')

            // setError('')
            return true
        })
        return response
    }

    const validateEmail = async (email) => {
        let response;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
        if (regexp.test(email))
            response = await getAPI(`email/${email}/?orders=true&accounts=true`).then((data) => {
                switch (data.error_id) {
                    case 'NOT_FOUND':
                        // setFieldError('email','')
                        setErrorMessage('')
                        return true
                    case 400:
                        // setFieldError('email',t('email-api-failed'))
                        setErrorMessage(t('email-api-failed'))
                        return false
                    case 'INVALID_PARAM_VALUE':
                        // setFieldError('email',t('email-api-failed'))
                        setErrorMessage(data.user_message)
                        return false
                    case 'NO_CONTENT':
                        setErrorMessage([t('email-api-exist'),
                        <a href="/contact" target="_blank" rel='noreferrer' style={{ color: '#f44336' }} key={"1"}>
                            {t('contact-us')}
                        </a>,
                            '.'])
                        return false
                    default: return false


                }
                /* 404: true # Email not currently in use
                                    400: false # Request malformed
                                    204: false # Email already in use */
            }
            )
        return response
    }


    const getAddressesForPostCode = (postCode) => {
        getAPI(`postcodes/${postCode}`).then((data) => {
            handleGetAddressesResponse(data);
            setAddress(data.addresses)
        })
    }

    const validatePostCode = async (value) => {
        let response = await getAPI(`postcode/${value}`).then(handlePostCodeValidation)
        return response
    }

    const validateField = async (value, type) => {
        let response;
        // if (type === 'number') {
        //     const regexp = /^07[0-9]{9}$/;
        //     if (regexp.test(value))
        //         response = await getAPI(`mobile/${value}/?orders=true&accounts=true`).then(handleMobileNumberValidation)
        // } else
        if (type === 'age') {
            if (value !== '') {
                response = await getAPI(`age/${value}`).then(handleDateOfBirthValidation)
            }
        }
        return response
    }

    const handleGetAddressesResponse = (data) => {
        switch (data.error_id) {
            case 'InvalidPostCode':
                setErrorCode(400)
                setPostCodeError(data.user_message)
                break
            default:
                break
        }
    }

    const handlePostCodeValidation = (data) => {
        switch (data.error_id) {
            case 'INVALID_PARAM_VALUE':
                setErrorCode(404)
                setPostCodeError(t('post-code-invalid'))
                return false
            case 'ERROR':
                setErrorCode(400)
                setPostCodeError(t('post-code-api-failed'))
                return false
            case 'NO_CONTENT':
                setErrorCode(0)
                setPostCodeError('')
                return true
            default: return false
        }
    }

    // const handleMobileNumberValidation = (data) => {
    //     switch (data.error_id) {
    //         case 'NOT_FOUND':
    //             setErrorCode(0)
    //             setNumberError('')
    //             return true
    //         case 400:
    //             setErrorCode(400)
    //             setNumberError(t('mobile-number-api-failed'))
    //             return false
    //         case 'NO_CONTENT':
    //             setErrorCode(204)
    //             setNumberError([t('mobile-number-api-exist'),
    //             <a href="/contact" target="_blank" rel='noreferrer' style={{ color: '#fff' }}>{t('contact-us')}</a>, '.'])
    //             return false
    //         default:
    //             return false
    //     }
    // }

    const handleDateOfBirthValidation = (data) => {
        switch (data.error_id) {
            case 'InvalidAgeError':
                setErrorCode(404)
                setAgeError(t('parent-age-validation'))
                return false
            case 'ERROR':
                setErrorCode(400)
                setAgeError(t('please-try-again'))
                return false
            case 'NO_CONTENT':
                setErrorCode(0)
                setAgeError('')
                return true
            default: return false

        }
    }
    const selectAddress = (address, postCode, values) => {
        setAddress([])
        let newAddress = {
            "postcode": postCode,
            "address1": address.address1,
            "address2": address.address2,
            "city": address.city
        }
        setInitalValues({ ...values, ...newAddress })
    }

    return (
        // <>
        //     <AppHeader />
        //     <div id={'signupScreen'}>
        //         <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
        //             <h4 className="screen-title">{t('signup.title')}</h4>
        //             <h5 className="screen-subtitle">{t('signup.subtitle')}</h5>
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={submitValues}>{
                (props) =>
                    <SignupForm {...props} validateEmail={validateEmail}
                        emailChange={() => { setErrorMessage('') }}
                        passwordSuggestion={passwordSuggestion}
                        checkPassword={checkPassword}
                        change={e => console.log(error)}
                        // setError={(error) => setError(error)}
                        setPasswordSuggestion={() => setPasswordSuggestion('')}
                        history={history}
                        emailError={errorMessage}
                        error={error}
                        getAddressesForPostCode={getAddressesForPostCode}
                        addresses={address}
                        selectAddress={selectAddress}
                        validateField={validateField}
                        fieldChange={(type) => {
                            setErrorCode(0);
                            // type === 'number' ? setNumberError('') : 
                            type === 'age' ? setAgeError('') : setPostCodeError('')
                        }}
                        // numberError={numberError}
                        postCodeError={postCodeError}
                        childName={childName}
                        ageError={ageError} />
            }
        </Formik>
        //             {/* <Loader />
        //         </Grid>
        //     </div>
        // </> */}
    );
}

const mapStateToProps = state => ({
    Kyc: state.kyc
})
const mapDispatchToProps = dispatch => {
    return {
        loginOk: (user) => dispatch({ type: "LOGIN_OK", user: user }),
        loginFail: () => dispatch({ type: "LOGIN_FAILED" }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
