import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import { DefaultButton } from '../../ui-components/button/index';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import { FormRowWithLabel, FormLabel, FormSpacer } from '../../ui-components/formRows/formRows';

const useStyles = makeStyles(() => ({
    root: {
        '& > *': {
        },
    },
}));

const LoginForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        values: {
            amount,
            display_name,
            message,
            username
        },
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        linkStatus,
        error,
        isSignedIn,
        initialValues
    } = props;

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }


    return (
        <Card className={classes.cardcustom}>
            <div className='padDiv'>
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}>
                    <FormRowWithLabel>
                        <FormLabel>{t('gifter.amount')}</FormLabel>
                        <TextField id="amount"
                            name="amount"
                            placeholder={t('gifter.amount-placeholder')}
                            helperText={touched.amount ? errors.amount : ""}
                            error={touched.amount && Boolean(errors.amount)}
                            value={amount}
                            onKeyDown={keyPress}
                            disabled={initialValues.amount}
                            onChange={handleChange}
                            variant="filled"
                            onBlur={handleBlur}
                            type='number'
                        />
                    </FormRowWithLabel>
                    <FormRowWithLabel>
                        <FormLabel>{t('gifter.from')}</FormLabel>
                        <TextField id="display_name"
                            name="display_name"
                            placeholder={t('gifter.from-placeholder')}
                            helperText={touched.display_name ? errors.display_name : ""}
                            error={touched.display_name && Boolean(errors.display_name)}
                            value={display_name}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            disabled={initialValues.display_name}
                            variant="filled"
                            onBlur={handleBlur}
                        />
                    </FormRowWithLabel>
                    <FormRowWithLabel>
                        <FormLabel>{t('gifter.message')}</FormLabel>
                        <TextField id="message"
                            name="message"
                            placeholder={t('gifter.message-placeholder')}
                            helperText={touched.message ? errors.message : ""}
                            error={touched.message && Boolean(errors.message)}
                            value={message}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            disabled={initialValues.message}
                            variant="filled"
                            onBlur={handleBlur}
                        />
                    </FormRowWithLabel>
                    {!isSignedIn && <FormRowWithLabel>
                        <FormLabel>{t('email')}</FormLabel>
                        <TextField id="username"
                            name="username"
                            placeholder={t('username')}
                            helperText={touched.username ? errors.username : ""}
                            error={(touched.username && Boolean(errors.username)) || Boolean(error)}
                            value={username}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            variant="filled"
                            onBlur={handleBlur}
                            autoComplete="email"
                        />
                    </FormRowWithLabel>}
                    {error && <FormRowWithLabel>
                        <FormLabel></FormLabel>
                        <p>{error}</p>
                    </FormRowWithLabel>}
                    <FormSpacer />
                    {linkStatus !== 'Payment' && <FormRowWithLabel className='next-button-container'>
                        <FormLabel />
                        <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                    </FormRowWithLabel>}

                    

                </form>
            </div>
        </Card>
    );
}

export default LoginForm;
