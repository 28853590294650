import React, { useEffect, useState } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import Account from "../account/index";
// import { useTranslation } from 'react-i18next';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { parse } from "query-string";
import CardDesign from "../cardDesign2";
import { trackConversion, trackEvent } from "../../common/trackers";
import OrderForm from "../allCombined";
import { getDecryptedData } from "../../common/constants";

const Order_V2 = (props) => {
  const { addSteps, loadDesigns, history, setOrder } = props;

  let query = parse(props.location.search);
  const sequences = ["B"]; // ['A','B']
  const randomIndex = Math.floor(Math.random() * sequences.length);
  const sequence =
    sequences.indexOf(query.sequence) >= 0
      ? query.sequence
      : sequences[randomIndex];
  const [decryptedData] = useState(() => getDecryptedData());
  const screens =
    decryptedData && decryptedData.adult
      ? [{ id: "your-order" }, { id: "checkout" }]
      : sequence === "B"
      ? [
          { id: "card-design" },
          { id: "about-your-child" },
          { id: "account" },
          { id: "about-you" },
          { id: "checkout" },
        ]
      : [
          { id: "account" },
          { id: "about-you" },
          { id: "card-design" },
          { id: "about-your-child" },
          { id: "checkout" },
        ];

  useEffect(() => {
    setOrder(sequence);
    trackConversion("CardOrderPageLoaded", 0);
    trackEvent("sequence " + sequence);
  }, [setOrder, sequence]);

  useEffect(() => {
    addSteps(screens.map((s) => s.id));
  }, [screens, addSteps]);

  useEffect(() => {
    loadDesigns();
    sessionStorage.setItem("mode", "create");
  }, [loadDesigns]);

  return (
    <>
      {/* <AppHeader selected={0} /> */}
      {decryptedData && decryptedData.adult ? (
        <OrderForm history={history} />
      ) : sequence === "B" ? (
        <CardDesign history={history} Order={sequence} />
      ) : (
        <Account history={history} />
      )}
      {/* {window.ENVIRONMENT === 'local' && <StepsNavigator selected={props.currentStep} className='test' />} */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSteps: (steps) => dispatch({ type: "ADD_STEPS", value: steps }),
    loadDesigns: () => dispatch({ type: "GET_DESIGNS" }),
    setOrder: (order) => dispatch({ type: "SET_ORDER", value: order }),
  };
};

const mapStateToProps = (state) => {
  return {
    designs: state.designs,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Order_V2));
