import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
// const { t } = this.props;
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(() => ({
    center: {
        margin: 'auto'
    },

}));


const KycConfirm = (props) => {

    const { t } = useTranslation();
    const classes = useStyles()



    return (

        <div>
            <div className="module-content heading">
                <div className="container">

                    <h4><span className="icon-tick" style={{ fontSize: '20px', marginRight: '5px' }}></span> Thank You!</h4>
                    {/* </div> */}
                </div>
            </div>
            <Grid item xs={12} sm={10} md={8} lg={6} className={classes.center}>
                <Card className='customCard'>

                    <div className='padDiv'>


                        <div className="module module-white module-quite-tight">

                            <div className="module-content align-center">
                                <div className="row">
                                    <Grid item xs={12} sm={10} md={10} lg={10} className={classes.center}>

                                        <h4 className="visible-xs" style={{ lineHeight: "1.4" }}>{t('kycConfirm.message')}</h4>
                                        <h3 className="hidden-xs">{t('kycConfirm.message')}</h3>

                                        {/* <p>We've sent your confirmation email to <b>{email}</b>.</p> */}

                                        {/* <p className="text-gray">(Please make sure you check your spam folder too.)</p> */}

                                        <div className="small-print">
                                            <p><b>{t('kycConfirm.error')}</b></p>
                                            <p>{t('kycConfirm.email_error1')}<b>{t('kycConfirm.email_error2')}</b>{t('kycConfirm.email_error3')} <i><b>{t('kycConfirm.email_error4')}</b> {t('kycConfirm.email_error5')} <a href="/contact">{t('kycConfirm.email_error6')}</a> {t('kycConfirm.email_error7')}</i></p>
                                        </div>
                                    </Grid>
                                </div>
                            </div>

                        </div>

                        {/* <Grid container className='next-button-container'>
                            <Grid item xs={12} sm={4} md={3} lg={3} className={classes.center}>
                                <DefaultButton onClick={handleOk} name='Ok' btnType="button" id='next-btn'></DefaultButton>
                            </Grid>
                        </Grid> */}

                    </div>
                </Card>
            </Grid>
        </div>)

}


export default (withTranslation()(KycConfirm));

