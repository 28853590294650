import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import { DefaultButton } from '../../ui-components/button/index';
import { FormRowWithLabel, FormLabel, FormSpacer, ErrorMessage } from '../../ui-components/formRows/formRows';
import TermsChecklist from './termsChecklist'
import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from "react-i18next";
import { getAPI } from '../../common/api'

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {},
  },
}));



const AccountForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [invitecodeValue, setInviteCode] = useState('')
  const [invitecodeMessage, setInviteCodeMessage] = useState('')
  const [invitecodeDetails, setInviteCodeDetails] = useState({})


  const {
    values: {
      first_name,
      last_name,
      email,
      confirmEmail,
      password,
      source,
      marketing,
      gdpr
    },

    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    validateEmail,
    emailError,
    emailChange,
    passwordSuggestion,
    setPasswordSuggestion,
    error,
  } = props;


  useEffect(() => {
    if (source)
      refCodeApi(source)
  }, [source])


  const handleEmailChange = (e) => {
    handleBlur(e)
    validateEmail(e.target.value,false)
  }
  const handleEmailOnChange = (e) => {
    handleChange(e)
    emailChange(e.target.value)
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }

  const checkReferralCode = (e) => {
    handleBlur(e)
    setInviteCodeMessage('')
    if (e.target.value === '') {
      setInviteCode('')
      return
    }
    refCodeApi(e.target.value)
  }

  const refCodeApi = (value) => {
    getAPI(`referrer/ref-${value}?channel_code=`,'',false,false).then(data => {
      switch (data.error_id) {
        case 'NOT_FOUND':
          setInviteCode('fail')
          break
        case 400:
          setInviteCode('fail')
          break
        default:
          if (data.referral_link) {
            setInviteCode('success')
          }
          if(data.campaign_details)
          setInviteCodeDetails(data.campaign_details)
          if (data.campaign_details && data.campaign_details.campaign_type === 'marketing')
            setInviteCodeMessage(data.campaign_details.campaign_text)
          break
      }
    })
  }

  const checkPassword = (e) => {
    handleBlur(e)
    if (e.target.value.length < 8)
      return
    if (e.target.value === '') {
      setPasswordSuggestion('')
      return
    }
    let body = {
      password: e.target.value,
      user_inputs: [first_name, last_name, email]
    }
    props.checkPassword(body,false)
  }



  return (
    <Card className={classes.cardcustom}>
      <div className='padDiv'>
        <FormRowWithLabel>
          <FormLabel>{t('first-name')}</FormLabel>
          <TextField id="first_name"
            name="first_name"
            placeholder={t('first-name')}
            helperText={touched.first_name ? errors.first_name : ""}
            error={touched.first_name && Boolean(errors.first_name)}
            value={first_name}
            onKeyDown={keyPress}
            onChange={handleChange}
            variant="filled"
            onBlur={handleBlur}
            autoComplete="given-name"
          />
        </FormRowWithLabel>
        <FormRowWithLabel>
          <FormLabel>{t('last-name')}</FormLabel>
          <TextField id="last_name"
            name="last_name"
            placeholder={t('last-name')}
            helperText={touched.last_name ? errors.last_name : ""}
            error={touched.last_name && Boolean(errors.last_name)}
            value={last_name}
            onKeyDown={keyPress}
            onChange={handleChange}
            variant="filled"
            onBlur={handleBlur}
            autoComplete="family-name"
          />
        </FormRowWithLabel>
        <FormSpacer />
        <FormRowWithLabel>
          <FormLabel>{t('email')}</FormLabel>
          <TextField id="email"
            name="email"
            placeholder={t('email')}
            // helperText={touched.email ? errors.email : ""}
            error={(touched.email && Boolean(errors.email)) || emailError}
            value={email}
            onKeyDown={keyPress}
            onChange={handleEmailOnChange}
            variant="filled"
            onBlur={handleEmailChange}
            autoComplete="email"
          />
          <ErrorMessage name="email" error={emailError} />
        </FormRowWithLabel>
        <FormRowWithLabel>
          <FormLabel></FormLabel>
          <TextField id="confirmEmail"
            name="confirmEmail"
            placeholder={t('confirm-email')}
            helperText={touched.confirmEmail ? errors.confirmEmail : ""}
            error={touched.confirmEmail && Boolean(errors.confirmEmail)}
            value={confirmEmail}
            onKeyDown={keyPress}
            onChange={handleChange}
            variant="filled"
            onBlur={handleBlur}
          // autoComplete="email"
          />
        </FormRowWithLabel>
        <FormSpacer />
        <FormRowWithLabel>
          <FormLabel>{t('password')}</FormLabel>
          {/* <PasswordWithSuggest
            id="password"
            name="password"
            placeholder={t('password')}
            pattern={['all', 'all', 'all', 'all']}
            suggestOnInit={true}
            error={(touched.password && Boolean(errors.password)) || passwordSuggestion}
            value={password}
            onKeyDown={keyPress}
            onChange={(e) => { setPasswordSuggestion(''); handleChange(e) }}
            onDataChange={(value) => { setPasswordSuggestion(''); setFieldValue('password', value) }}
            onBlur={checkPassword}
            buttonLabel={t('suggest-password')}
          /> */}
          <TextField
            id="password"
            name="password"
            type="password"
            placeholder={t('password')}
            error={(touched.password && Boolean(errors.password)) || passwordSuggestion}
            value={password}
            onKeyDown={keyPress}
            onChange={(e) => { setPasswordSuggestion(''); handleChange(e) }}
            onBlur={checkPassword}
          // autoComplete="password"
          />
          <ErrorMessage name="password" error={passwordSuggestion} />
        </FormRowWithLabel>

        <FormRowWithLabel>
          <FormLabel optional='yes'>{t('invite-code')}</FormLabel>
          <TextField
            name="source"
            placeholder={t('invite-code')}
            id='referral-code'
            className={invitecodeValue}
            helperText={touched.source ? errors.source : ""}
            error={touched.source && Boolean(errors.source)}
            value={source}
            onKeyDown={keyPress}
            onChange={handleChange}
            variant="filled"
            onBlur={checkReferralCode}

          />

        </FormRowWithLabel>
        <FormRowWithLabel>
          <></>
          {invitecodeValue !== 'success' && <span className='help'>{t('invite-code-note')}</span>
          }
          {invitecodeValue === 'success'
            && <>{invitecodeMessage ? <p className="referral-input-success">
              <b>{invitecodeMessage}</b>
            </p>
              : invitecodeDetails.campaign_type === "user" ? <p className="referral-input-success">
                <b>{t('invite-code-success-note1')}</b>
                {t('invite-code-success-note2')}
              </p>: null}</>}
        </FormRowWithLabel>

        <FormSpacer />
        <FormRowWithLabel className='checkbox'>
          <FormLabel />
          < FormControlLabel
            id="gdprLabel"
            control={< Checkbox checked={gdpr}
              onChange={handleChange}
              name="gdpr"
              id="gdpr"
              error={touched.gdpr && Boolean(errors.gdpr)} />}
            label={t('account.dataProcessingConsent')}
            error={touched.gdpr && Boolean(errors.gdpr)}
          />
          <TermsChecklist />
          <ErrorMessage name='marketing' />
          <ErrorMessage name='gdpr' />
        </FormRowWithLabel>
        <FormRowWithLabel className='checkbox'>
          <FormLabel />
          < FormControlLabel id="marketingLabel"
            control={< Checkbox checked={marketing} onChange={handleChange} name="marketing"
              id="marketing"
              error={touched.marketing && Boolean(errors.marketing)} />}
            label={t('account.marketingConsent')}
            error={touched.marketing && Boolean(errors.marketing)}
          />
        </FormRowWithLabel>
        {error && <FormRowWithLabel>
          <FormLabel></FormLabel>
          <p className='errorMessage'>{error}</p>
        </FormRowWithLabel>}
        <FormSpacer />
        <FormRowWithLabel className='next-button-container'>
          <FormLabel />
          <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
        </FormRowWithLabel>
        <FormSpacer />
        <FormSpacer />
      </div>
    </Card >
  );
}


export default AccountForm;
