import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  labelDiv: {
    color: '#454d5c',
    fontSize: '16px'
  },
  inputTextField: {
    width: '100%',
    maxWidth: '585px',
    border: 'none',
    margin: '4px 0',
    color: '#000',
    '& input': {
      padding: '9px 12px',
      fontSize: '14px'
    }
  },
}));

const StyledInput = withStyles({
  root: {
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "red"
    }
  }
})(TextField);

export default function TextFields(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(props.visibleByDefault ? true : false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    props.type === 'password' ?
      <StyledInput {...props}
        variant="filled"
        className={`${classes.inputTextField} ${props.className}`}
        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
        InputProps={{ // <-- This is where the toggle button is added.
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }} />
      :
      <StyledInput {...props}
        variant="filled"
        className={`${classes.inputTextField} ${props.className}`}
        inputProps={{ maxLength: props.maxLength, min: 0 }} />
  );
}
