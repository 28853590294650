import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';
import { useTranslation, withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
// const { t } = this.props;
import Card from '@material-ui/core/Card';
import { AppHeader } from '../layout/index'
import { FormRowWithLabel, FormLabel, ConfirmValue } from "../../ui-components/formRows/formRows";
import EmailIcon from '@material-ui/icons/Email';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DraftsIcon from '@material-ui/icons/Drafts';

const useStyles = makeStyles(() => ({
    center: {
        margin: 'auto'
    },
    change: {
        marginTop: '15px',
        marginRight: '13px',
        fontWeight: 500,
        color: 'rgb(255, 131, 0)',
        float: 'right'
    },
    mailIcon: {
        width: '3em',
        height: '3em',
        color: '#ff8300'
    }
}));


const AnimatedMailIcon = (props) => {
    const animate = () => {
        setFrame((frame + 1) % frames.length)
    }
    const frames = [
        <MailOutlineIcon {...props} />,
        <EmailIcon {...props} />,
        <DraftsIcon {...props} />
    ]
    const [frame, setFrame] = useState(0)
    useEffect(() => {
        const timer = setInterval(animate, 333)
        return () => {
            clearInterval(timer)
        }
    })
    return frames[frame]
}

const FailSoft = (props) => {
    const { t } = useTranslation();

    let email = props.history.location ? props.history.location.email : ''
    // const { t } = useTranslation();
    const classes = useStyles()


    return (
        <>
            {/* <Grid item xs={12} sm={10} md={8} lg={6} className={classes.center}>

                <Card className='customCard'>

                    <div className='padDiv'>
                        <div className="header">
                            <div className="header__inner">
                                <h3 style={{ fontSize: '23px' }}> You're nearly there</h3>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-sm-offset-3" align="center">
                            <p>We were unable to match your name to your address, and need more information from you to proceed.</p>
                            <span className="icon-email orange" style={{ fontSize: "30px" }}></span>
                            <p>We've <b>sent an email</b> to <a color="orange" href="/#">{email}</a> explaining what to do next.</p>
                            <p>If you don't receive the email in the next few minutes, check your spam folder first, then contact us via <a href="https://help.osper.com/using-your-osper-app/live-chat-in-app" color="orange">the in-app chat</a>.</p>
                        </div>
                        <br />
                    </div></Card></Grid> */}

            <AppHeader />
            <div id={'failSoftScreen'}>
                <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
                    <h4 className="screen-title">{t('fail-soft.title')}</h4>
                    <h5 className="screen-subtitle">{t('fail-soft.subtitle')}</h5>
                    <Card className={classes.cardcustom}>
                        <div className='padDiv'>
                            <FormRowWithLabel>
                                <FormLabel><AnimatedMailIcon className={classes.mailIcon} /></FormLabel>
                                <ConfirmValue>
                                    <p>{t('fail_soft.message')}</p>
                                </ConfirmValue>
                            </FormRowWithLabel>
                            {/* <FormSpacer /> */}
                            <FormRowWithLabel>
                                <FormLabel />
                                <div className="small-print">
                                    <p>{t('fail_soft.email_confirm1')} <b>{t('fail_soft.email_confirm2')}</b> 
                                    {t('fail_soft.email_confirm3')} <a color="orange" href="/#">{email}</a>{t('fail_soft.email_confirm4')}</p>
                                    <p>{t('fail_soft.message_error')}<a href="https://help.osper.com/using-your-osper-app/live-chat-in-app" color="orange">{t('fail_soft.message_error1')}</a>.</p>
                                </div>
                            </FormRowWithLabel>
                        </div>
                    </Card>
                </Grid>
            </div>
        </>

    )

}


export default (withTranslation()(FailSoft));

