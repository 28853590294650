import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next';
import CardDesign2 from '../cardDesign2/index'
import { connect } from 'react-redux'



const AdditionalOrder = (props) => {


  const { addSteps, initialiseStep, saveChildrenCards } = props
  const { childLimit } = props.history.location || {}

  const screens = [
    { id: "card-design" },
    { id: "about-your-child" },
    { id: "checkout" }
  ]

  useEffect(
    () => {
      addSteps(screens.map(s => s.id))
    }, [screens, addSteps]
  )

  useEffect(() => {
    // initialiseStep(0)
    saveChildrenCards([])

  }, [initialiseStep, saveChildrenCards])

  return (

    <CardDesign2 childLimit={childLimit} additionalCard={true}  history={props.history} />
  )
}

const mapDispatchToProps = dispatch => {
  return {
    saveChildrenCards: (value) => dispatch({ type: "SET_CHILDREN", value }),
    addSteps: (steps) => dispatch({ type: "ADD_STEPS", value: steps })
  }
};



export default connect(null, mapDispatchToProps)(withTranslation()(AdditionalOrder));
