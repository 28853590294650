import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import { DefaultButton } from '../../ui-components/button/index';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import { FormRowWithLabel, FormSpacer, FormLabel, ErrorMessage } from '../../ui-components/formRows/formRows';
import DOB from '../../ui-components/dob';
import FocusUtils from "../../common/focusUtils";
import { keyPressCommon } from '../../common/constants';

const useStyles = makeStyles(() => ({
    root: {
        '& > *': {
        },
    },
}));

const ResetPasswordForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        values: {
            password
        },
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        error,
        // passwordSuggestion,
        // setPasswordSuggestion,
        passwordError
    } = props;


    const keyPress = (e) => {
        keyPressCommon(e, focus)
    }

    let focus
    useEffect(() => {
        focus = FocusUtils()
    })

    const checkPassword = (e) => {
        handleBlur(e)
        if (e.target.value.length < 8)
            return
        if (e.target.value === '') {
            // setPasswordSuggestion('')
            return
        }
        let body = {
            password: e.target.value,
            user_inputs: ['first_name', 'last_name', 'email']
        }
        props.checkPassword(body)
    }

    const fieldChange = (e) => {
        // setPasswordSuggestion('');
        props.fieldChange()
        handleChange(e)
    }

    return (
        <Card className={classes.cardcustom}>

            <div className='padDiv'>
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                    autocomplete="do-not-show-ac">
                    <DOB {...props} dobChange={handleBlur} />
                    <FormRowWithLabel>
                        <FormLabel>{t('password')}</FormLabel>
                        <TextField id="password"
                            name="password"
                            placeholder={t('password')}
                            // helperText={touched.password ? errors.password : ""}
                            error={(touched.password && Boolean(errors.password))}
                            value={password}
                            onKeyDown={keyPress}
                            visibleByDefault={true}
                            onChange={fieldChange}
                            variant="filled"
                            onBlur={checkPassword}
                            type='password'
                            autocomplete="do-not-show-ac"
                        />
                        <ErrorMessage name="password" error={passwordError} />

                    </FormRowWithLabel>
                    {/* {passwordSuggestion &&
                        <FormRowWithLabel>
                            <FormLabel />
                            <span className='help'>{passwordSuggestion}</span>
                        </FormRowWithLabel>} */}
                    {error && <FormRowWithLabel>
                        <FormLabel></FormLabel>
                        <p className='errorMessage'>{error}</p>
                    </FormRowWithLabel>}
                    <FormSpacer />
                    <FormRowWithLabel className='next-button-container'>
                        <FormLabel />
                        <DefaultButton onClick={handleSubmit} name={t('submit')} id='submit-btn'></DefaultButton>
                    </FormRowWithLabel>

                </form>
            </div>
        </Card>
    );
}

export default ResetPasswordForm;
