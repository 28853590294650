import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultButton } from '../../ui-components/button/index';
import DialogTitle from './DialogTitile';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    confirmDialog: {
        padding: '1em',
        alignItems: 'flex-start',
    },
    confirmDialogItem: {
        padding: '1em 10px',
    },


}))

export default function ReplaceDialog(props) {
    const { t } = useTranslation();


    const classes = useStyles();

    const { close, select, name } = props

    return <Dialog onDismiss={close} id="dialog"
        onClear={close}
        open={true}
        aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title" onClose={close}>
            {t('cancel-dialog.header')}</DialogTitle>
        <DialogContent>
            <span>{t('cancel-dialog.text')} {name}. {t('cancel-dialog.confirm')}</span>
        </DialogContent>
        <DialogActions className={classes.confirmDialog}>
            <span className={classes.confirmDialogItem}>
                <DefaultButton onClick={() => select(true)} name={t('yes')} />
            </span>
            <span className={classes.confirmDialogItem}>
                <DefaultButton onClick={close} name={t("No")} />
            </span>
        </DialogActions>
    </Dialog>
}


