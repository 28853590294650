const ChildrenUtils = () => {
  const create = (first_name = "", card_design_id = 0) => ({
    first_name: first_name,
    last_name: "",
    gender: "",
    email: "",
    mobile: "",
    // date_of_birth: null,
    card_design_id: card_design_id,//12,
    password: "",
    tier_id: 14,
    username: "",
    above_minimum_age: true,
    day: null,
    month: null,
    year: null
    // notify: false,
    // invite: false
  })
  const update = (child, updates) => {
    Object.keys(updates).forEach(p => child[p] = updates[p])
  }
  return {
    create: create,
    update: update
  }
}

export default ChildrenUtils()
