

import { takeLatest, put, call } from 'redux-saga/effects';
const BASE_URL = window.API || 'https://testing.osper.com/api/'

function* callAPI() {
    const response = yield call(fetch, BASE_URL + this.type);

    const responseBody = yield response.json();
    yield put({ type: this.action, value: this.response ? responseBody[this.response] : responseBody });
}

export function* getServices() {
    yield takeLatest('GET_DESIGNS', callAPI.bind({ 'type': 'designs', 'action': 'SET_DESIGNS', 'response': 'groups' }));
}
