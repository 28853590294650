import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import './index.css'
import { getAPI } from "../../common/api";
import { AppHeader } from "../layout";
import { connect } from "react-redux";
import EmailIcon from '@material-ui/icons/Email';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DraftsIcon from '@material-ui/icons/Drafts';
import { ConfirmValue, FormLabel, FormRowWithLabel } from "../../ui-components/formRows/formRows";

const useStyles = makeStyles(() => ({
  center: {
    margin: 'auto'
  },
  change: {
    marginTop: '15px',
    marginRight: '13px',
    fontWeight: 500,
    color: 'rgb(255, 131, 0)',
    float: 'right'
  },
  mailIcon: {
    width: '3em',
    height: '3em',
    color: '#ff8300',
  },
  heading: {
    fontWeight: 700,
    fontSize: '1.125rem'
  }
}));

const AnimatedMailIcon = (props) => {
  const animate = () => {
    setFrame((frame + 1) % frames.length)
  }
  const frames = [
    <MailOutlineIcon {...props} />,
    <EmailIcon {...props} />,
    <DraftsIcon {...props} />
  ]
  const [frame, setFrame] = useState(0)
  useEffect(() => {
    const timer = setInterval(animate, 333)
    return () => clearInterval(timer)
  })
  return frames[frame]
}

const VerifyEmail = (props) => {
  const email = props.history.location ? props.history.location.email || props.Kyc.email : props.Kyc.email || ''
  const { neworder } = props.history.location || ''
  const { t } = useTranslation();
  const classes = useStyles()
  const POLL_INTERVAL_MSEC = 1000
  let timer = false;

  const poll = (email) => {
    getAPI(`verification/email/${email}`).then(data => {
      if (data && data.error_id === 'NO_CONTENT') {
        props.loginOk()
        stopPolling()
        props.history.push({ pathname: '/v2/dashboard' })
      }
    })
  }

  const startPolling = () => {

    timer = setInterval(() => poll(email), POLL_INTERVAL_MSEC)
  }

  const stopPolling = () => {
    clearInterval(timer)
  }

  const visChange = (e) => {
    console.log(document.documentURI.includes('verify-email') && !document.hidden, document.documentURI.includes('verify-email'), !document.hidden, "vischange")
    if (document.documentURI.includes('verify-email') && !document.hidden) startPolling()
    else
      stopPolling()
  }

  useEffect(() => {
    startPolling()
    document.addEventListener('visibilitychange', visChange)
    return () => {
      stopPolling()
    }
  }, [])


  return (
    <div>
      <AppHeader />
      <div id={'confirmEmailScreen'}>
        <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
          <h4 className="screen-title">{t('email-confirm.title')}</h4>
          <h5 className="screen-subtitle">{t('email-confirm.subtitle')}</h5>
          <Card className={classes.cardcustom}>
            <div className='padDiv'>
              <FormRowWithLabel>
                <FormLabel mail={true}>
                  <AnimatedMailIcon className={classes.mailIcon} /></FormLabel>
                <ConfirmValue>
                  {t('email-confirm.confirm-email')}
                </ConfirmValue>
                {neworder && <p className={classes.heading}>
                {t('email-confirm.text')}
                </p>}
                <p>{t('email-confirm.sent-email')} <b>{email}</b>. <br />
                {t('email-confirm.spam')}<br /><br />

                {t('email-confirm.page-redirect')}</p>

                {(/@hotmail.com\s*$/.test(email)) && <div className="alert alert-warning spam-warning" role="alert">
                  <p><b>{t('email-confirm.hotmail-warning1')}</b></p>
                  <p>{t('email-confirm.hotmail-warning1')}</p>
                </div>}
              </FormRowWithLabel>
              {/* <FormSpacer /> */}
              <FormRowWithLabel>
                <FormLabel />
                <div className="small-print">
                  <p>
                    {/* <b>Email address incorrect or didn't get your confirmation email?</b></p>
                  <p>
                    Log into the <a href="https://osper.com/app">Osper App</a> on your mobile,
                    using <b>{email}</b> as your username and the password
                    you just created to resend your link or edit your email address.<br /><br /> */}
                    <i>
                    {t('email-confirm.problem')} <a href="/contact">{t('email-confirm.contact-us')}</a> {t('email-confirm.help')}</i>
                  </p>
                </div>
              </FormRowWithLabel>
            </div>
          </Card>
        </Grid>
      </div>
    </div>)

}

const mapStateToProps = state => ({
  Kyc: state.kyc
})

const mapDispatchToProps = dispatch => {
  return {
    loginOk: (user) => dispatch({ type: "LOGIN_OK", user: user }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VerifyEmail));

