import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import AccountForm from "./accountForm";
import Grid from "@material-ui/core/Grid";
import { withTranslation, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAPI, postAPI } from "../../common/api";
import Loader from "../../ui-components/loader/loader";
import { AppHeader } from "../layout/index";
import { getDecryptedData } from "../../common/constants";
import { trackConversion } from "../../common/trackers";
import GoToTop from "../../common/goToTop";

const Account = (props) => {
  const { t } = useTranslation();
  const [initialValues, setInitalValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirmEmail: "",
    password: "",
    source: window.referralCode || "",
    marketing: false,
    gdpr: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [passwordSuggestion, setPasswordSuggestion] = useState("");
  const [index, setIndex] = useState("");

  const { Kyc, Steps, saveKyc, saveStep, history, Children } = props;

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required(`${t("field-rqd")} ${t("first-name-placeholder")}`)
      .min(2, t("2-character-rqrd"))
      .max(12, t("first-name-max"))
      .matches(
        /^[a-zA-Z .'-]{2,12}$/,
        `${t("latin-letter-exclusion-error")} ${t("first-name-placeholder")}`
      ),
    last_name: Yup.string()
      .required(t(`${t("field-rqd")} ${t("last-name-placeholder")}`))
      .min(2, t("2-character-rqrd"))
      .max(30, t("last-name-max"))
      .matches(
        /^[a-zA-Z .'-]{2,30}$/,
        `${t("latin-letter-exclusion-error")} ${t("last-name-placeholder")}`
      ),
    email: Yup.string()
      .required(`${t("field-rqd")} ${t("email-placeholder")}`)
      .email(t("email-validation")),
    confirmEmail: Yup.string()
      .required(t("confirm-email-error"))
      .test("email-match", t("confirm-email-match"), function (value) {
        return this.parent.email === value;
      }),
    password: Yup.string()
      .required(`${t("field-rqd")} ${t("password-placeholder")}`)
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^(~_+){.,/`+-}])[A-Za-z\d@$!%*?&#^(~_+){.,/`+-}]{8,}$/,
      //     t('password-error')
      .min(8, t("8-character-rqrd")),
    gdpr: Yup.bool().oneOf([true], t("terms-and-conditions")),
  });

  useEffect(() => {
    const decryptedData = getDecryptedData() || {};

    if (
      Object.values(Kyc).every((x) => x === null || x === "" || x === false) &&
      decryptedData &&
      decryptedData.adult
    ) {
      // trackConversion('OsperforSquidAccount', 0)

      setInitalValues({
        ...decryptedData.adult,
        ...decryptedData.adult.birth_date,
        confirmEmail: decryptedData.adult.email,
        password: "",
        gdpr: false,
      });
    } else
      setInitalValues({
        ...Kyc,
        source: window.referralCode || Kyc.source,
        // password: Kyc.password ? Kyc.password : putils.suggestPassword('.', ['all', 'all', 'all', 'all'])
      });
  }, [Kyc]);

  useEffect(() => {
    // if steps is not initialised redirect to the first page
    if (Steps.length > 0) setIndex(Steps.findIndex((p) => p.id === "account"));
    else history.push("/v2");

    if (Children.length <= 0) history.push("/v2");
    else {
      let childFilled = Children.map((child) => {
        const { email, mobile, ...rest } = child;
        return Object.keys(rest).every(
          (x) => child[x] !== null && child[x] !== "" && child[x] !== false
        );
      });
      if (childFilled.includes(false)) history.push("/v2/about-your-child");
    }
  }, [Steps]);

  const submitValues = async (values) => {
    if (errorMessage || passwordSuggestion) return;
    setError("");

    let response = await Promise.all([
      validateEmail(values.email),
      checkPassword({
        password: values.password,
        user_inputs: [values.first_name, values.last_name, values.email],
      }),
    ]);

    if (response.filter((data) => !data).length > 0) {
      setError("There was an error with the request you sent");
      return;
    }
    values.isAccount = true;
    saveKyc(values);
    saveStep({ completed: true, index: index });
    history.push("/v2/about-you");
  };

  const checkPassword = async (body, LOADER_DISABLED) => {
    if (/\s/g.test(body.password)) {
      setPasswordSuggestion(t("whitespace-error"));
      return false;
    }
    let response = await postAPI(
      `password_strength`,
      body,
      false,
      LOADER_DISABLED
    ).then((data) => {
      if (data.error_id) {
        // setError('error')
        setPasswordSuggestion(
          data.user_message ? data.user_message : t("please-try-again")
        );
        return false;
      }
      // if (data.feedback) {
      //     let suggestion = data.feedback.suggestions.toString();
      //     suggestion = suggestion
      //         .concat(data.feedback.warning)
      //     suggestion = fixBackendTypo(suggestion)

      if (data.score < 3) {
        // setPasswordSuggestion(suggestion)
        setPasswordSuggestion(data.feedback);
        // setError('error')
        return false;
      }
      //  }
      setPasswordSuggestion("");

      // setError('')
      return true;
    });
    return response;
  };

  const validateEmail = async (email, LOADER_DISABLED) => {
    let response;
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    if (regexp.test(email))
      response = await getAPI(
        `email/${email}/?orders=true&accounts=true`,
        "",
        "",
        LOADER_DISABLED
      ).then((data) => {
        switch (data.error_id) {
          case "NOT_FOUND":
            // setFieldError('email','')
            setErrorMessage("");
            return true;
          case 400:
            // setFieldError('email',t('email-api-failed'))
            setErrorMessage(t("email-api-failed"));
            return false;
          case "INVALID_PARAM_VALUE":
            // setFieldError('email',t('email-api-failed'))
            setErrorMessage(data.user_message);
            return false;
          case "NO_CONTENT":
            setErrorMessage([
              t("email-api-exist"),
              <a
                href="/contact"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#f44336" }}
                key={"1"}
              >
                {t("contact-us")}
              </a>,
              ".",
            ]);
            return false;
          default:
            return false;
        }
        /* 404: true # Email not currently in use
                                    400: false # Request malformed
                                    204: false # Email already in use */
      });
    return response;
  };

  return (
    <>
      <AppHeader selected={index} />

      <div id={"accountScreen"}>
        <Grid item xs={12} sm={10} md={8} lg={6} className="contentWrapper">
          <h4 className="screen-title">{t("account.title")}</h4>
          <h5 className="screen-subtitle">{t("account.subtitle")}</h5>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitValues}
          >
            {(props) => (
              <AccountForm
                {...props}
                validateEmail={validateEmail}
                emailChange={() => {
                  setErrorMessage("");
                }}
                passwordSuggestion={passwordSuggestion}
                checkPassword={checkPassword}
                change={(e) => console.log(error)}
                // setError={(error) => setError(error)}
                setPasswordSuggestion={() => setPasswordSuggestion("")}
                history={history}
                emailError={errorMessage}
                error={error}
              />
            )}
          </Formik>
          <Loader />
        </Grid>
      </div>
      <GoToTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  Kyc: state.kyc,
  Steps: state.steps,
  Children: state.children,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveKyc: (value) => dispatch({ type: "SET_KYC", value }),
    saveStep: (value) => dispatch({ type: "SAVE_STEP", value }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Account));
