import React from 'react'
import './processProgress.css'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

const Step = (props) => {


  const { active, index, completed, checkout, stepsCount, onClick, label } = props;
  let css = active ?
    'active' :
    completed || checkout ?
      'completed' :
      ''
  let indexLabel = 'step ' + index + ' of ' + stepsCount;

  return <li className={css} data-index={index} style={{ cursor: checkout ? 'auto' : '' }}
    onClick={onClick} >
    <span className="index">{indexLabel}</span>
    <span className="label">{label}</span>
  </li>
}


const ProcessProgress = (props) => {


  const { t, Steps, selected, checkout, history ,Order} = props;
  // console.log(Steps)
  return <ul className={'processProgress'}>
    {Steps.map((stepItem, i) =>
      <Step
        key={i}
        index={i + 1}
        onClick={() => (!checkout && (stepItem.completed || i <= selected)) ? history.push(Order === 'B' ? stepItem.url :
          stepItem.url === '/v2/account' ? '/v2' :
            stepItem.url === '/v2/card-design' && i === 0 ? '/v2/replace-card' :
              stepItem.url) : null}
        stepsCount={Steps.length}
        label={t(stepItem.label)}
        checkout={checkout}
        completed={stepItem.completed}
        active={selected === i}
      />

    )}
    <li className={'stepTransition'}></li>
    <li></li>
  </ul>

}

const mapStateToProps = state => ({
  Steps: state.steps,
  Order: state.order
})



export default withRouter(connect(mapStateToProps, null)(withTranslation()(ProcessProgress)));
