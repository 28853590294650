import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import GiftForm from './giftForm'
// import { connect } from 'react-redux';

// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { postAPI, getAPI, scaLogin } from "../../common/api";
import Loader from '../../ui-components/loader/loader'
import { AppHeader } from '../layout/index'
import Payment from './payment'
import cardIcon from '../../assets/images/loadlink.svg'
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Signup from "../signup/index";
import LoginForm from "../login/loginForm";
import { setAccountId } from "../../common/constants";
import { DefaultButton } from "../../ui-components/button";
// import './main.css'

const useStyles = makeStyles(() => ({
  root: {
    background: '#ff8300',
    height: '95vh'

  },
  center: {
    margin: 'auto'
  },
  change: {
    marginTop: '15px',
    marginRight: '13px',
    fontWeight: 500,
    color: 'rgb(255, 131, 0)',
    float: 'right'
  },
  flexImage: {
    height: 'auto'
  },
  headerContainer: {
    maxWidth: '1000px',
    margin: 'auto'
  },
  listItems: {
    marginLeft: "45px"
  },
  formContainer: {
    paddingTop: '50px'
  },
  btn: {
    maxWidth: "250px",
    margin: 'auto'
  }
}));

const Gifter = (props) => {
  const { t } = useTranslation();
  const classes = useStyles()
  const [initialValues, setInitialValues] = useState({ username: '', password: '' })
  const [initialValuesGifter, setInitialValuesGifter] = useState(sessionStorage.getItem('gifter_details') ?
    JSON.parse(sessionStorage.getItem('gifter_details')) : { amount: '', display_name: '', message: '', username: "" })
  const [error, setError] = useState('')
  const [linkStatus, setLinkStatus] = useState('Loading')
  const [giftStatus, setGiftStatus] = useState('Gifter')


  /* linkStatus
  Loading --- on intial loading
  FORBIDDEN --- If the gift link does not exists
  Gift --- Gifting page
  Payment --- To show the braintree
  Success --- Gifting Completed
   */

  /* giftStatus
Login --- To show Login page
Gifter --- To show the gifting Page
Signup --- To show Signup page
 */

  const [emailValidation, setEmailValidation] = useState('')
  const [data, setData] = useState({})
  const [isSca, setIsSca] = useState(false)
  const [scaData, setScaData] = useState({})
  const [values, setValues] = useState({})


  const { GiftStatus, changeGifter, history, loginFail, loginOk, sessionAuthenticated } = props;

  const validationSchema = Yup.object({
    username: Yup.string()
      .required(`${t('field-rqd')} ${t('email-placeholder')}`)
      .email(t('email-validation')),
    password: Yup.string()
      .required(`${t('field-rqd')} ${t('password-placeholder')}`),
  });



  let validationGifter = {
    amount: Yup.number().nullable()
      .required(`${t('field-rqd')} ${t('gifter.amount-error')}`)
      .max(100, t('gifter.amount-validation'))
      .min(5, t('gifter.amount-validation')),
    display_name: Yup.string()
      .when([], {
        is: () => !data.display_name,
        then: Yup.string().required(`${t('field-rqd')} ${t('gifter.from-error')}`)
          .min(2, t('2-character-rqrd'))
          .max(12, t('display-name-validation'))
          .matches(/^[a-zA-Z .'-]{2,12}$/, `${t('latin-letter-exclusion-error')} ${t('display-name-placeholder')}`),
        otherwise: Yup.string(),
      }),
    message: Yup.string()
      .required(`${t('field-rqd')} ${t('gifter.message-error')}`)
  };

  if (!sessionAuthenticated) {
    validationGifter.username = Yup.string()
      .required(`${t('field-rqd')} ${t('email-placeholder')}`)
      .email(t('email-validation'))
  }

  const validationSchemaGifter = Yup.object(validationGifter);

  const { code } = props.match.params || '';

  useEffect(() => {
    getAPI('v2/login?next=gifter').then((resp) => {
      if (resp.error_id === 'SCA_DISABLED') {
        setIsSca(true)
      }
      else { setScaData(resp) }
    })

    checkLinkValidity()


  }, [])

  useEffect(() => {
    setGiftStatus(GiftStatus)
  }, [GiftStatus])

  useEffect(() => {
    if (giftStatus === 'Gifter')
      checkLinkValidity()
  }, [giftStatus])




  useEffect(() => {
    if (sessionAuthenticated)
      getDetails()
    else
      sessionStorage.removeItem('gifter_details')

  }, [sessionAuthenticated])

  const submitValuesGifter = (values) => {
    setError('')

    setValues(values)

    if (sessionAuthenticated) {
      setLinkStatus('Payment')
      setInitialValuesGifter(values)

    } else {
      sessionStorage.setItem('gifter_details', JSON.stringify(values))
      validateEmail(values.username)
    }

  }


  const checkLinkValidity = () => {
    getAPI(`v2/gift/${code}`, "", true).then(response => {
      if (response.error_id) {
        // if (response.error_id === "UNAUTHENTICATED") {
        //   setGiftStatus("Login")
        //   setLinkStatus("Gift")
        //   loginFail()
        // }
        // else
        setLinkStatus('FORBIDDEN')
      } else {
        setData(response)
        setInitialValuesGifter(sessionStorage.getItem('gifter_details') ? response.display_name ?
          { ...JSON.parse(sessionStorage.getItem('gifter_details')), display_name: response.display_name } :
          { ...JSON.parse(sessionStorage.getItem('gifter_details')) } :
          { ...initialValuesGifter, display_name: response.display_name })
        if (sessionAuthenticated && sessionStorage.getItem('gifter_details')) {
          setLinkStatus("Payment")
        } else
          setLinkStatus('Gift')
        setGiftStatus("Gifter")
        // loginOk()
      }
    })
  }


  const submitValues = (values) => {
    setError('')
    if (isSca) {
      login(values)
      return
    }
    scaLogin('v2/login?next=gifter', values, scaData.nonce).then((resp) => {

      if (!resp) {
        setError("server failed")
        return
      }
      if (resp.error_id) {
        if (resp.error_id === 'SCA_DISABLED') {

          login(values)
          return
        }
        setError(resp.user_message)
        return
      }
      setAccountId(resp.account_id)
      if (resp.next.includes('challenge')) {
        changeGifter('Gifter')
        history.push({
          pathname: '/v2/challenge',
          state: {
            account_id: resp.account_id,
            nonce: resp.nonce,
            email: resp.email,
          }
        })
      }
      checkLinkValidity()
      // setLinkStatus('Gift')
      // setGiftStatus("Gifter")
      // setIsSignedIn(true)
      loginOk()
    })

  }

  const login = (values) => {
    postAPI('login', values).then((resp) => {
      if (resp.error_id) {
        setError(resp.user_message)
        loginFail()
        return
      }
      // changeGifter('Gifter')
      checkLinkValidity()
      // setLinkStatus('Gift')
      // setGiftStatus("Gifter")
      // setIsSignedIn(true)
      loginOk()

    })
  }

  const validateEmail = async (email) => {
    setError('')
    setEmailValidation('')
    let response;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    if (regexp.test(email))
      response = await getAPI(`email/${email}/?orders=true&accounts=true`).then((resp) => {
        switch (resp.error_id) {
          case 'NOT_FOUND':
            // setFieldError('email','')
            // setError([`${t('gifter.new-loader1')} ${data.first_name} ${t('gifter.new-loader2')} `,
            // <a href="/v2/signup" target="_blank" rel='noreferrer' style={{ color: '#f44336' }} key={"1"}>
            //   {t('signup.title')}
            // </a>,
            //   '.'])
            // setError(`${t('gifter.new-loader1')} ${data.first_name} ${t('gifter.new-loader2')}`)
            setGiftStatus("signup")
            return false
          case 400:
            // setFieldError('email',t('email-api-failed'))
            setError(t('email-api-failed'))
            return false
          case 'INVALID_PARAM_VALUE':
            // setFieldError('email',t('email-api-failed'))
            setError(resp.user_message)
            return false
          case 'NO_CONTENT':
            setError('')
            setEmailValidation('NO_CONTENT')
            setInitialValues({ ...initialValues, username: email })
            setGiftStatus("Login")
            return true
          default: return false

        }
        /* 404: false # Email not currently in use
                            400: false # Request malformed
                            204: true # Email already in use */
      }
      )
    return response
  }


  const emailChange = () => { setError(''); setEmailValidation(''); setGiftStatus("Login") }

  // const signup = (values) => {
  //   history.push({
  //     pathname: '/v2/signup',
  //     state: {
  //       email: values.username,
  //       code

  //     }
  //   })
  // }

  // const amountChange = (amount) => {
  //   if (amount < 100 && amount > 5)
  //     setLoadAmount(amount)
  // }

  const getDetails = () => {
    setError('')
    getAPI(`v2/account/details`, '', true).then(data => {
      if (data.error_id) {
        if (data.error_id === "UNAUTHENTICATED") {
          setGiftStatus("Login")
          setLinkStatus("Gift")
          loginFail()
        } else
          setError(data.user_message)
      } else {
        setAccountId(data.account_id)
      }
    })

  }

  return (
    <div className={(linkStatus === 'FORBIDDEN' || linkStatus === 'Loading' || linkStatus === 'Success') ? classes.root : ''}>
      <AppHeader />
      {(linkStatus === 'FORBIDDEN' || linkStatus === 'Loading' || linkStatus === 'Success') ?
        <Grid item xs={12} sm={10} md={8} lg={6} className={classes.center}>

          <Card className='customCard'>

            <div className='padDiv module-orange'>


              <div className="module module-orange module-quite-tight">
                {linkStatus === 'Success' ?

                  <div className="row">

                    <Grid container>
                      <Grid item xs={12} sm={10} md={6} lg={6} className={classes.center}>
                        <div className="module-content align-left">
                          <h3>{t('gifter.title')}</h3>
                          <p>{`${t('gifter.sub1')} ${data.first_name} ${t('gifter.sub2')}`}</p>
                          <div className={classes.btn} >
                            <DefaultButton onClick={() => history.push('/v2/dashboard')} name={t('next')} />
                          </div>
                          {/* <a href='/v2/dashboard' className="gifterLink">{t('success.back-to-dashboard')}</a> */}
                        </div>

                      </Grid>
                      {/* <Grid item xs={12} sm={10} md={6} lg={6} className={classes.center}>
                        <div className="module-content">
                          <img className={classes.flexImage} src={cardIcon} alt="" />
                        </div>
                      </Grid> */}
                    </Grid>
                  </div>
                  :
                  <div className="module-content align-center">
                    <div className="row">
                      <Grid item xs={12} sm={10} md={10} lg={10} className={classes.center}>


                        {/* <img className={classes.flexImage} src={cardIcon} alt="" /> */}
                        {linkStatus === 'Loading' ? <h3>{t('gifter.loading')}</h3>
                          : <>
                            <h3>{t('gifter.error')}</h3>
                            <br />
                            <p>{t('gifter.check-again')}
                              <a href="/contact"> {t('contact-us')}</a>.</p></>}
                      </Grid>
                    </div>
                  </div>}

              </div>

            </div></Card></Grid >

        :
        <>
          <div id="giftingIntro" className="module module-orange" style={{ paddingBottom: '50px' }}>
            <Grid container spacing={2} className={classes.headerContainer}>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <div className="module-content order-content-header">
                  <img className={classes.flexImage} src={cardIcon} alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={9} md={8} lg={8} className="align-left">
                <div className="module-content order-content-header">
                  <h5 >{`${data.first_name}${t('gifter.title3')}`}</h5>
                  <h3 >{`${t('gifter.title1')} ${data.first_name} ${t('gifter.title2')}`} </h3>
                  <div className={classes.listItems}>
                    <div><span className="icon-tick-open iconTick"></span>{t('gifter.subtitle1')}</div>
                    <div><span className="icon-tick-open iconTick"></span>{t('gifter.subtitle2')}</div>
                    <div><span className="icon-tick-open iconTick"></span>{`${t('gifter.subtitle3')} ${data.first_name} ${t('gifter.subtitle4')}`}</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>


          <div id={'loginScreen'} className={classes.formContainer}>
            {giftStatus === 'Gifter' ? <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
              {/* <h4 className="screen-title">{`${t('gifter.title1')} ${data.first_name} ${t('gifter.title2')}`}</h4>
              <h5>{t('gifter.subtitle1')}</h5>
              <h5>{t('gifter.subtitle2')}</h5>
              <h5>{`${t('gifter.subtitle3')} ${data.first_name} ${t('gifter.subtitle4')}`}</h5> */}
              <Formik
                enableReinitialize
                validationSchema={validationSchemaGifter}
                initialValues={initialValuesGifter}
                onSubmit={submitValuesGifter}>{
                  (props) =>
                    <GiftForm {...props} error={error} emailValidation={emailValidation}
                      emailChange={emailChange} linkStatus={linkStatus}
                      validateEmail={validateEmail}
                      initialValues={initialValuesGifter}
                      isSignedIn={sessionAuthenticated}
                    />
                }
              </Formik>
              {linkStatus === 'Payment' && <Payment code={code} data={data} values={values} history={history} initialValuesGifter={initialValuesGifter}
                sessionExpired={() => changeGifter('Login')} success={() => setLinkStatus('Success')} />
              }
            </Grid> :
              <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
                {/* <h4 className="screen-title">{t('login.title')}</h4>
                <h5>{t('login.subtitle')}</h5> */}
                {giftStatus === "signup" ?
                  //  <Formik
                  //   enableReinitialize
                  //   initialValues={initialValues}
                  //   onSubmit={signup}>{
                  //     (props) =>
                  //       <LoginForm {...props} error={error}
                  //         emailValidation={emailValidation}
                  //         emailChange={emailChange}
                  //         validateEmail={validateEmail}
                  //         giftStatus={giftStatus}
                  //       />
                  //   }
                  // </Formik> 
                  <Signup history={history}
                    childName={data.first_name}
                    code={code}
                    onSuccess={() => setGiftStatus("Gifter")} />
                  : <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={submitValues}>{
                      (props) =>
                        <LoginForm {...props} error={error}
                          gifter={true}
                        // emailValidation={emailValidation}
                        // emailChange={emailChange}
                        // validateEmail={validateEmail}
                        />
                    }
                  </Formik>}

              </Grid>}
          </div>
        </>
      }
      <Loader />

    </div>
  );
}

const mapStateToProps = state => ({
  GiftStatus: state.gifterStatus,
  sessionAuthenticated: state.sessionAuthenticated
})

const mapDispatchToProps = dispatch => {
  return {
    loginOk: (user) => dispatch({ type: "LOGIN_OK", user: user }),
    loginFail: () => dispatch({ type: "LOGIN_FAILED" }),
    changeGifter: (value) => dispatch({ type: "CHANGE_GIFTER", value }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Gifter);
