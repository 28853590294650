import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import { postAPI, getAPI } from "../../common/api";
import BrainTree from "../BrainTree";
// const { t } = this.props;
import Loader from "../../ui-components/loader/loader";
import { connect } from "react-redux";
import { getAccountId } from "../../common/constants";

const useStyles = makeStyles(() => ({
  center: {
    margin: "auto",
  },
  change: {
    marginTop: "15px",
    marginRight: "13px",
    fontWeight: 500,
    color: "rgb(255, 131, 0)",
    float: "right",
  },
  container: {
    borderBottom: "1px solid #eeeeee",
  },
  sub: {
    fontSize: "12px",
  },
  failMessage: {
    margin: "20px 0px 0px 0px",
  },
}));

const CheckOut = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { code, data, values, sessionExpired, success, initialValuesGifter } =
    props;
  // } = props.history.location || "";

  const [error, setError] = useState("");
  const [trasactionDetails, setTrasactionDetails] = useState({});
  const amount = (values.amount || initialValuesGifter.amount)?.toFixed(2);
  const totalAmount = (
    parseFloat(values.amount || initialValuesGifter.amount) +
    data.fee / 100
  )?.toFixed(2);
  const account_id = getAccountId();

  const getTransactionDeatils = (account_id) => {
    getAPI(`v2/account/${account_id}/gift/${code}/payment`).then((d) => {
      setTrasactionDetails(d);
    });
  };

  useEffect(() => {
    getTransactionDeatils(account_id);
  }, [account_id]);

  const submit = (nonce) => {
    if (!values && !initialValuesGifter) {
      props.history.goBack();
    }
    // if (!values)
    //     values = initialValuesGifter
    let body = {
      gift: {
        message: values.message || initialValuesGifter.message,
        display_name: values.display_name || initialValuesGifter.display_name,
        reason: "LOAD",
        amount: (values.amount || initialValuesGifter.amount) * 100,
        nonce: nonce,
      },
    };
    postAPI(`v2/account/${account_id}/gift/${code}/payment`, body, true).then(
      (data) => {
        if (data.error_id) {
          if (data.error_id === "401") {
            sessionExpired();
            return;
          }
          setError(data.user_message);
          if (
            data.error_id === "PaymentFailureError" ||
            data.error_id === "InvalidCardDetailsError"
          ) {
            setTrasactionDetails("");
            getTransactionDeatils(account_id);
          }
          return;
        }
        if (data.next === "congrats") {
          sessionStorage.removeItem("gifter_details");
          success();
          return;
        }

        // props.history.push(`/v2/success?ref_code=${data.code}&bonus_amount=${data.bonus_amount}&super_bonus_amount=${data.super_bonus_amount}`)
      }
    );
  };
  return (
    <div className="padDiv">
      <h2>Checkout</h2>
      {data && (
        <div className="form-group confirm-kyc-group text-left">
          <div className={classes.container}>
            <div className="checkout-detail">
              <span className="confirm-value">
                {`${t("gifter.checkout1")} ${data.first_name}`}:
              </span>
              <span className="confirm-value">£{amount}</span>
              {/* <br /> */}
            </div>

            <div className="checkout-detail">
              <span className="confirm-value">{t("gifter.checkout2")}:</span>
              <span className="confirm-value">
                £{(data.fee / 100)?.toFixed(2)}
              </span>
              {/* <br /> */}
            </div>
          </div>
          <div className={classes.container}>
            <div className="checkout-detail">
              <span className="confirm-value">Total fee:</span>
              <span className="confirm-value">£{totalAmount}</span>
            </div>
          </div>
        </div>
      )}
      <BrainTree
        submit={submit}
        error={error}
        trasactionDetails={trasactionDetails}
        account_id={account_id}
        amount={
          parseFloat(values.amount || initialValuesGifter.amount) +
          data.fee / 100
        }
        btn={t("pay")}
      />
      {/* <p className='errorMessage'>{error}</p> */}

      <Loader />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSteps: (steps) => dispatch({ type: "ADD_STEPS", value: steps }),
  };
};

export default connect(null, mapDispatchToProps)(withTranslation()(CheckOut));
