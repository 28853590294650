import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import AboutYouForm from './aboutYouForm';
// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import { getAPI } from '../../common/api'
import { useTranslation } from 'react-i18next';
import Loader from '../../ui-components/loader/loader'
import { AppHeader } from '../layout/index'

const AboutYou = (props) => {
  const { t } = useTranslation();
  const [initialValues, setInitalValues] = useState({
    postcode: "",
    address1: "",
    address2: "",
    city: "",
    mobile: "",
    day: "",
    month: "", year: "",
    gender: "",
    display_name: "",
  })

  const [address, setAddress] = useState([])
  const [errorCode, setErrorCode] = useState(0)
  const [ageError, setAgeError] = useState('')
  const [numberError, setNumberError] = useState('')
  const [postCodeError, setPostCodeError] = useState('')
  const [index, setIndex] = useState('')
  const currentYear = parseInt(new Date().getFullYear())
  const validationSchema = Yup.object({
    postcode: Yup.string()
      .min(5, t('post-code-short'))
      .max(8, t('post-code-long'))
      .required(`${t('field-rqd')} ${t('post-code-text')}`),
    address1: Yup.string()
      .min(3, t('address-min'))
      .max(120, t('address-max'))
      .required(t('address1-error')),
    address2: Yup.string()
      .min(3, t('address-min'))
      .max(120, t('address-max')),
    city: Yup.string()
      .min(3, t('city-min'))
      .max(120, t('city-max'))
      .matches(/^[a-zA-Z0-9 .'-]+$/, `${t('latin-letter-exclusion-error')} ${t('city')}`)
      .required(`${t('field-rqd')} ${t('city-placeholder')}`),
    mobile: Yup.string()
      .required(`${t('field-rqd')} ${t('mobile-number-placeholder')}`)
      .matches(/^07[0-9]{9}$/, t('mobile-number-validation')),
    display_name: Yup.string()
      .min(2, t('2-character-rqrd'))
      .max(12, t('display-name-validation'))
      .matches(/^[a-zA-Z .'-]{2,12}$/, `${t('letter-exclusion-error')} ${t('display-name')}`)
      .required(`${t('field-rqd')} ${t('display-name')}`),
    day: Yup.number().nullable()
      .required(`${t('field-rqd')} ${t('day')}`)
      .max(31, t('day-validation'))
      .min(1, t('day-validation')),
    month: Yup.number().nullable()
      .required(`${t('field-rqd')} ${t('month')}`)
      .max(12, t('month-validation'))
      .min(1, t('month-validation')),
    year: Yup.number().nullable()
      .required(`${t('field-rqd')} ${t('year')}`)
      .min(currentYear - 100, t('parent-age-validation'))
      .max(currentYear - 18, t('parent-age-validation')),
    // gender: Yup.string().required(t('gender-validation')),

  });

  const { Order, Kyc, Steps, saveKyc, saveStep, history } = props;

  useEffect(() => {
    setInitalValues({ ...initialValues, ...Kyc })
    // if steps is not initialised redirect to the first page
    if (Steps.length > 0 && !Object.values(Kyc).every(x => x === null || x === '' || x === false))
      setIndex(Steps.findIndex(p => p.id === "about-you"));
    else
      history.push('/v2')
  }, [Kyc, Steps, history])


  const submitValues = async (values) => {
    if (errorCode !== 0)
      return

    let response = await Promise.all([
      validatePostCode(values.postcode),
      validateField(values.mobile, 'number'),
      validateField(`${values.day}-${values.month}-${values.year}`, 'age')
    ]);

    if (response.filter(data => !data).length > 0)
      return

    values.isAboutYou = true;
    saveKyc(values)


    saveStep({ completed: true, index: index })

    if (Order === 'B') {
      if (Kyc.isAccount) {
        history.push('/v2/order-preview')
      } else {
        history.push('/v2/account')
      }
    } else {

      history.push('card-design')
    }
  };

  const getAddressesForPostCode = (postCode) => {
    getAPI(`postcodes/${postCode}`).then((data) => {
      handleGetAddressesResponse(data);
      setAddress(data.addresses)
    })
  }

  const validatePostCode = async (value) => {
    let response = await getAPI(`postcode/${value}`).then(handlePostCodeValidation)
    return response
  }

  const validateField = async (value, type,LOADER_DISABLED) => {
    let response;
    if (type === 'number') {
      const regexp = /^07[0-9]{9}$/;
      if (regexp.test(value))
        response = await getAPI(`mobile/${value}/?orders=true&accounts=true`,'',false,LOADER_DISABLED).then(handleMobileNumberValidation)
    } else if (type === 'age') {
      if (value !== '') {
        response = await getAPI(`age/${value}`,'',false,LOADER_DISABLED).then(handleDateOfBirthValidation)
      }
    }
    return response
  }

  const handleGetAddressesResponse = (data) => {
    switch (data.error_id) {
      case 'InvalidPostCode':
        setErrorCode(400)
        setPostCodeError(data.user_message)
        break
      default:
        break
    }
  }

  const handlePostCodeValidation = (data) => {
    switch (data.error_id) {
      case 'INVALID_PARAM_VALUE':
        setErrorCode(404)
        setPostCodeError(t('post-code-invalid'))
        return false
      case 'ERROR':
        setErrorCode(400)
        setPostCodeError(t('post-code-api-failed'))
        return false
      case 'NO_CONTENT':
        setErrorCode(0)
        setPostCodeError('')
        return true
      default: return false
    }
  }

  const handleMobileNumberValidation = (data) => {
    switch (data.error_id) {
      case 'NOT_FOUND':
        setErrorCode(0)
        setNumberError('')
        return true
      case 400:
        setErrorCode(400)
        setNumberError(t('mobile-number-api-failed'))
        return false
      case 'NO_CONTENT':
        setErrorCode(204)
        setNumberError([t('mobile-number-api-exist'),
        <a href="/contact" target="_blank" rel='noreferrer' style={{ color: '#fff' }}>{t('contact-us')}</a>, '.'])
        return false
      default:
        return false
    }
  }

  const handleDateOfBirthValidation = (data) => {
    switch (data.error_id) {
      case 'InvalidAgeError':
        setErrorCode(404)
        setAgeError(t('parent-age-validation'))
        return false
      case 'ERROR':
        setErrorCode(400)
        setAgeError(t('please-try-again'))
        return false
      case 'NO_CONTENT':
        setErrorCode(0)
        setAgeError('')
        return true
      default: return false

    }
  }
  const selectAddress = (address, postCode, values) => {
    setAddress([])
    let newAddress = {
      "postcode": postCode,
      "address1": address.address1,
      "address2": address.address2,
      "city": address.city
    }
    setInitalValues({ ...values, ...newAddress })
  }


  return (
    <>
      <AppHeader selected={index} />

      <div id={'aboutYouScreen'}>
        <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
          <h4 className="screen-title">{t('about-you.title')}</h4>
          <h5 className="screen-subtitle">{t('about-you.subtitle')}</h5>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitValues}>{
              (props) =>
                <AboutYouForm {...props}
                  getAddressesForPostCode={getAddressesForPostCode}
                  addresses={address}
                  selectAddress={selectAddress}
                  validateField={validateField}
                  fieldChange={(type) => {
                    setErrorCode(0);
                    type === 'number' ? setNumberError('') : type === 'age' ? setAgeError('') : setPostCodeError('')
                  }}
                  numberError={numberError}
                  postCodeError={postCodeError}
                  ageError={ageError} />
            }
          </Formik>
          <Loader />
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  Kyc: state.kyc,
  Steps: state.steps,
  Order: state.order

})

const mapDispatchToProps = dispatch => {
  return {
    saveKyc: (value) => dispatch({ type: "SET_KYC", value }),
    saveStep: (value) => dispatch({ type: "SAVE_STEP", value }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutYou);
