import React, { useState, useEffect } from "react";
// import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Snackbar, Paper } from "@material-ui/core";
// import {orderSteps} from '../../common/constants'
import { useTranslation } from "react-i18next";
import Loader from "../../ui-components/loader/loader";
import { AppHeader } from "../layout/index";
import { DefaultButton } from "../../ui-components/button/index";
import { getAPI, deleteAPI, getDownloadFile, postAPI } from "../../common/api";
import {
  getAccountId,
  localDateConversionMethod,
  setAccountId,
} from "../../common/constants";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import { isMobile } from "../../common/constants";
import ReplaceDialog from "../../ui-components/Dialog/dashboardDialog";
import ConfirmDialog from "../../ui-components/Dialog/cancelConfirmation";
import { saveAs } from "file-saver";
import {
  FaFileCsv,
  FaFilePdf,
  FaHtml5,
  FaUser /*FaCopy*/,
} from "react-icons/fa";
// import { IconContext } from "react-icons";
import BrainTree from "../BrainTree/index";
// import TextField from "../../ui-components/textField/textField";
// import { IconButton, InputAdornment } from "@material-ui/core";
// import Search from "@material-ui/icons/Search";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  btnIndicatorAdd: {
    fontSize: "20px",
    marginLeft: "-20px",
    fontWeight: "bold",
    position: "absolute",
    top: "-1px",
  },
  processingCards: {
    fontSize: "18px",
    fontWeight: "100",
    lineHeight: "30px",
    textTransform: "title-case",
  },
  processingCardsHeader: {
    marginBottom: "10px",
    fontWeight: "100",
    fontSize: "22px",
  },
  subHeading: {
    marginBottom: "10px",
    fontWeight: "100",
    fontSize: "18px",
    marginTop: "2px",
  },
  // cardOrderStatus: {
  //     display: 'block',
  //     padding: '0.5em',
  //     backgroundColor: 'var(--reverse-background-color)',
  //     backgroundColor: '#666666', // for IE
  //     color: 'var(--reverse-text-color)',
  //     color: '#bbbbbb', // for IE
  //     // textTransform: 'lowercase'
  // },
  cardContainer: {
    background: "#f0f0f0",
    marginBottom: "10px",
    alignItems: "center",
    padding: "0px 10px",
    borderRadius: "10px",
    minHeight: "60px",
    position: "relative",
  },
  verificationContainer: {
    marginBottom: "15px",
  },
  paddingBottom: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "10px",
    },
  },
  card: {
    width: "100px",
  },
  orderStatusPending: {
    color: "#66B114",
    // textTransform: 'lowercase',
    marginLeft: "10px",
  },
  activeCard: {
    color: " var(--brand-main-color)",
    color: "#ff8300" /* for IE*/,
    // textTransform: 'lowercase',
    marginLeft: "10px",
  },
  replace: {
    textAlign: "lefy",
    color: " var(--brand-main-color)",
    color: "#ff8300" /* for IE*/,
    cursor: "pointer",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: " var(--brand-main-color)",
      backgroundColor: "#ff8300" /* for IE*/,
      color: "white",
      borderRadius: "8px",
      maxWidth: "100%",
      display: "block",
      padding: "5px 0px",
      margin: "5px 0px",
      textAlign: "center",
    },
  },
  delete: {
    textAlign: "center",
    color: " var(--brand-main-color)",
    color: "#ff8300" /* for IE*/,
    cursor: "pointer",
  },
  profileIcon: {
    marginRight: "10px",
    marginLeft: "20px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  subContainer: {
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  },
  statementSubContainer: {
    margin: "0px",
  },
  paper: {
    padding: "10px",
    textAlign: "center",
    backgroundColor: "var(--reverse-background-color)",
    backgroundColor: "#666666",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
  },
  consentContainer: {
    margin: "10px 0px !important",
    "& a": {
      color: " var(--brand-main-color)",
      color: "#ff8300" /* for IE*/,
    },
    "& a:hover": {
      color: " var(--reverse-background-color) !important",
      color: "#666666 !important" /* for IE*/,
    },
  },
  consentHead: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  giftcode: {
    padding: "10px",
    background: "#f0f0f0",
    border: "solid 1px white",
    borderRadius: "10px",
    cursor: "pointer",
    color: "grey",
    position: "relative",
    "& span": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "&:hover": {
      background: " var(--brand-main-color)",
      color: "white",
      "& span": {
        color: "white",
      },
    },
  },
  giftSubScript: {
    top: "-15px",
    color: "var(--brand-main-color)",
    right: "10px",
    display: "none",
    position: "absolute",
    fontSize: "12px",
  },
  copyIcon: {
    color: "grey",
    cursor: "pointer",
    marginLeft: "2px",
    "&:hover": {
      color: "var(--brand-main-color)",
    },
  },

  braintreeContainer: {
    position: "relative",
    marginTop: "15px",
  },
  cancel: {
    position: "absolute",
    right: "0px",
    zIndex: "1",
  },
  linkError: {
    marginTop: "0px",
  },
  unverified: {
    color: "red",
    textAlign: "center",
  },
}));

const DashBoard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [details, setDetails] = useState([]);
  const [childLimit, setChildLimit] = useState("");
  const [devices, setDevices] = useState([]);
  const [consents, setConsents] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  // const [linkError, setLinkError] = useState('')
  const [paymentError, setPaymentError] = useState("");
  const [replaceOrder, setReplaceOrder] = useState(false);
  const [cancelCard, setCancelCard] = useState(false);
  const [prevMonths, setPrevMonths] = useState([]);
  const [selected, setSelected] = useState("pdf");
  // const [copyText, setCopyText] = useState('')
  const [paymentDetails, setPaymentDetails] = useState({});
  const [verificationDetails, setVerificationDetails] = useState({});
  const [childVerificationDetails, setChildVerificationDetails] = useState({});
  const [changePaymentDetail, setChangePaymentDetails] = useState(false);
  const [childAccountId, setChildAccountId] = useState(false);
  // const [showVerification, setShowVerification] = useState(false)
  const [paymentVerify, setPaymentVerify] = useState(false);
  const [state] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = state;
  // const [gifterUrl, setGifterUrl] = useState(false)
  const { getDesigns } = props;

  const deleteChild = () => {
    setCancelCard(false);
    // let url = uris.delete_account.url.slice(5,uris.delete_account.url.length)
    let url = cancelCard?.delete_account?.url?.replace("/api/", "");
    deleteAPI(url).then((resp) => {
      if (resp.error_id === "NO_CONTENT") {
        setMessage(t("order_delete_success"));
        setOpen(true);
      }
    });
  };

  const getDetails = () => {
    setError("");
    getAPI(`v2/account/details`, true).then((resp) => {
      if (resp.error_id) {
        setError(resp.user_message);
      } else {
        setAccountId(resp.account_id);
        getPaymentDetails(resp.account_id);
        let paymentVerify = !!resp.accounts.filter(
          (account) => account.relationship_type === "self"
        )[0].uris.payment_method_verify;
        setPaymentVerify(paymentVerify);
        if (paymentVerify) getVerificationDetails(resp.account_id);
        setDetails(resp);
      }
    });
  };

  const getChildLimit = () => {
    setError("");
    getAPI("account/get_children_limit").then((resp) => {
      if (resp.error_id && resp.error_id !== "ForbiddenError") {
        setError(resp.user_message);
      } else {
        setChildLimit(resp.addchild_left ? resp.addchild_left : "");
        sessionStorage.setItem(
          "child_limit",
          resp.addchild_left ? resp.addchild_left : ""
        );
      }
    });
  };

  const getDevices = () => {
    getAPI("v2/devices").then((resp) => {
      // if (resp.error_id) {
      //     setError(resp.user_message)
      // } else
      setDevices(resp.results);
    });
  };

  const getPaymentDetails = (account_id) => {
    setPaymentError("");
    getAPI(`v2/account/${account_id}/payment_method`).then((resp) => {
      if (resp.error_id) {
        setChangePaymentDetails("NA");
        return;
      }
      setChangePaymentDetails(resp.last_4 ? false : "nocard");
      setPaymentDetails(resp);
    });
  };

  const getVerificationDetails = (account_id) => {
    getAPI(`v2/account/${account_id}/payment_method/verify`).then((resp) => {
      if (resp.error_id) {
        setChangePaymentDetails("NA");
        return;
      }
      setChangePaymentDetails(resp.last_4 ? false : "verify");
      setVerificationDetails(resp);
    });
  };

  const getConsents = () => {
    getAPI("v2/openbanking/consents").then((resp) => {
      if (resp.error_id) {
        // setError(resp.user_message)
      } else setConsents(resp.consents);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    getDetails();
    getDevices();
    getConsents();
    getChildLimit();
  };

  const findDate = (date, count) => {
    let currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + count);
    return localDateConversionMethod(currentDate);
  };

  const findDateDiff = (date) => {
    let createDate = new Date(date);
    let today = new Date();
    let diff = Math.abs(today.getTime() - createDate.getTime());
    let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  };

  const openReplaceDialog = (order) => {
    setReplaceOrder(order);
  };

  const openCancelConfirm = (uri, name) => {
    uri.name = name;
    setCancelCard(uri);
  };

  const downloadStatement = (card_id, month, name) => {
    getDownloadFile(
      `card/${card_id}/${month.year}-${month.monthNo}?format=${selected}`
    ).then((blob) =>
      saveAs(blob, `${name}_${card_id.slice(0, 3)}.${selected}`)
    );
  };

  const getScope = (scope) => {
    switch (scope) {
      case "balance:read":
        return t("consent.terms1");
      case "transactions:read":
        return t("consent.terms2");
      default:
        return "";
    }
  };

  const ProcessingCardOrder = ({
    order,
    replace,
    pending,
    replaceAvailable,
    cancel,
    processing,
    multiple,
    nextCard,
  }) => {
    const nextCardPending = nextCard
      ? nextCard.status === "PENDING_ACTIVATION"
      : false;
    return (
      <div className="parentCard">
        <Grid
          container
          className={`${classes.cardContainer} ${classes.paddingBottom}`}
          style={
            multiple
              ? { marginBottom: "0px", borderRadius: "10px 10px 0px 0px" }
              : {}
          }
        >
          <Grid item xs={3} sm={4} md={3} lg={3}>
            {replace
              ? order.user && order.user.first_name
                ? order.user.first_name
                : order.first_name_on_card
              : cancel
              ? order.first_name
              : order.name}
          </Grid>
          <Grid item xs={5} sm={4} md={2} lg={2}>
            <img
              className={classes.card}
              src={order.card_design_url}
              alt="new"
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <div
              className={
                replace &&
                (!pending || findDateDiff(order.created) > 15) &&
                order.status !== "ACTIVE"
                  ? classes.activeCard
                  : classes.orderStatusPending
              }
            >
              {pending
                ? findDateDiff(order.created) < 5
                  ? t("printing")
                  : findDateDiff(order.created) > 15
                  ? t("having_problems")
                  : t("on_its_way")
                : cancel
                ? t("waiting_at_checkout")
                : processing
                ? t("submitted")
                : order.status === "DORMANT"
                ? t("contact_support")
                : order.status === "AVAILABLE"
                ? t("ready_to_activate")
                : order.status.toLowerCase()}
            </div>
            {(!replace || (replace && pending)) &&
              !cancel &&
              !processing &&
              findDateDiff(order.created) <= 15 && (
                <div style={{ marginLeft: "10px" }}>
                  {t("expected")} {findDate(order.created, 10)}
                </div>
              )}
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            {
              replace &&
                !pending &&
                replaceAvailable &&
                !order.has_replacement && (
                  // <StyledTooltip title="Lost the card or just want a different design?">
                  <span
                    onClick={() => openReplaceDialog(order)}
                    className={classes.replace}
                  >
                    {t("replace")}{" "}
                  </span>
                )
              // {/* </StyledTooltip> */}
            }
            {replace && pending && findDateDiff(order.created) > 6 && (
              <a className="activate" href="osper://activate.card/">
                {t("activate")}
              </a>
            )}
            {processing && <span>{t("processing")}</span>}
            {cancel && (
              <span
                // onClick={() => deleteChild(order.uris)}
                onClick={() => openCancelConfirm(order.uris, order.first_name)}
                className={classes.replace}
              >
                {t("cancel")}
              </span>
            )}
          </Grid>
        </Grid>
        {multiple && (
          <Grid
            container
            className={`${classes.cardContainer} ${classes.paddingBottom}`}
            style={multiple ? { borderRadius: "0px 0px 10px 10px" } : {}}
          >
            <Grid item xs={3} sm={4} md={3} lg={3} />
            <Grid item xs={5} sm={4} md={2} lg={2}>
              <img
                className={classes.card}
                src={nextCard.card_design_url}
                alt="new"
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <div
                className={
                  (!nextCardPending || findDateDiff(nextCard.created) > 15) &&
                  nextCard.status !== "ACTIVE"
                    ? classes.activeCard
                    : classes.orderStatusPending
                }
              >
                {nextCardPending
                  ? findDateDiff(nextCard.created) < 5
                    ? "printing"
                    : findDateDiff(nextCard.created) > 15
                    ? t("having_problems")
                    : t("on_its_way")
                  : cancel
                  ? t("waiting_at_checkout")
                  : processing
                  ? t("submitted")
                  : nextCard.status === "DORMANT"
                  ? t("contact_support")
                  : nextCard.status === "AVAILABLE"
                  ? t("ready_to_activate")
                  : nextCard.status.toLowerCase()}
              </div>
              {nextCardPending &&
                //  !cancel && !processing &&
                findDateDiff(nextCard.created) <= 15 && (
                  <div style={{ marginLeft: "10px" }}>
                    {t("expected")} {findDate(nextCard.created, 10)}
                  </div>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              {!nextCardPending &&
                nextCard.uris.replace.methods &&
                !nextCard.has_replacement && (
                  <span
                    onClick={() => openReplaceDialog(nextCard)}
                    className={classes.replace}
                  >
                    {t("replace")}
                  </span>
                )}
              {nextCardPending && findDateDiff(nextCard.created) > 6 && (
                <a className="activate" href="osper://activate.card/">
                  {t("activate")}
                </a>
              )}
              {/* {processing && <span>
                        We are processing your card.
            </span>}
                    {cancel && <span
                        onClick={() => deleteChild(order.uris)} className={classes.replace}>
                        Cancel ?
            </span>} */}
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  const Devices = () => {
    return (
      <div className="parentCard">
        {devices &&
          devices.map((device) => {
            return (
              <Grid
                container
                className={classes.cardContainer}
                key={device.address_ip}
              >
                <Grid item xs={8} sm={8} md={9} lg={9}>
                  <div>{`${device.label ? device.label : ""} ${
                    device.browser ? device.browser : ""
                  }`}</div>
                  <div>{device.address_ip}</div>
                </Grid>

                <Grid item xs={4} sm={4} md={3} lg={3}>
                  <span
                    onClick={() => deleteDevice(device)}
                    className={classes.delete}
                  >
                    {t("delete")}
                  </span>
                </Grid>
              </Grid>
            );
          })}{" "}
      </div>
    );
  };

  const Consents = () => {
    return (
      <div className="parentCard">
        {consents &&
          consents
            .filter((consent) => consent.state === "granted")
            .map((consent) => {
              return (
                <Grid
                  container
                  className={classes.cardContainer}
                  key={consent.address_ip}
                >
                  <Grid item xs={8} sm={8} md={9} lg={9}>
                    <div className={classes.consentContainer}>
                      <div className={classes.consentHead}>{consent.tpp} </div>
                      <div>{consent.description}</div>
                      {consent.scope.split(",").map((scope) => (
                        <div>{getScope(scope)}</div>
                      ))}
                      <a href={consent.website}>{consent.website}</a>
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    <span
                      onClick={() => deleteConsent(consent)}
                      className={classes.delete}
                    >
                      {t("delete")}
                    </span>
                  </Grid>
                </Grid>
              );
            })}{" "}
      </div>
    );
  };

  const GiftCodes = ({ data }) => {
    return (
      <div className="parentCard">
        {data &&
          data.map((child) => {
            return (
              <Grid
                container
                className={classes.cardContainer}
                style={{ paddingBottom: "0px" }}
                key={child.giftcode}
              >
                <Grid item xs={8} sm={8} md={9} lg={9}>
                  <div className={classes.consentContainer}>
                    <div className={classes.consentHead}>
                      {child.firstname} {child.lastname}{" "}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={3}>
                  <span
                    className={classes.delete}
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/v2/c/${child.giftcode}`,
                        "_blank"
                      )
                    }
                  >
                    {t("dashboard-headings.send-gift")}{" "}
                  </span>
                </Grid>
                {/* <Grid item xs={2} sm={3} md={4} lg={5} >
                        <div className={classes.consentContainer}>
                            <div className={classes.consentHead}>{child.firstname} </div>
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={8} md={7} lg={6} >
                        <span className={classes.giftcode} onClick={() => window.open(`${window.location.origin}/v2/c/${child.giftcode}`, "_blank")}>
                            <Hidden only={['xs']}>
                                {`${window.location.origin}/v2/c/`}</Hidden>
                            <Hidden only={['sm', 'lg', 'md', 'xl']}>
                                {`${window.location.origin.slice(8)}/v2/c/`}</Hidden>
                            <span>{child.giftcode}</span>
                        </span>
                        <div className={classes.giftSubScript} id="text">{`${child.firstname}'s gift link'`}</div>
                    </Grid>
                    <Tooltip title={copyText === child.giftcode ? t('dashboard-headings.copied') : t('dashboard-headings.copy')}>
                        <Grid item xs={1} sm={1} md={1} lg={1} >
                            <IconContext.Provider value={{ className: classes.copyIcon }}>
                                <FaCopy size={20}
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${window.location.origin}/v2/c/${child.giftcode}`);
                                        setCopyText(child.giftcode)
                                    }} />
                            </IconContext.Provider>
                        </Grid>
                    </Tooltip> */}
              </Grid>
            );
          })}{" "}
      </div>
    );
  };

  const getChildVerification = (childAccountId) => {
    setChildAccountId(childAccountId);
    getAPI(`v2/account/${childAccountId}/subscription/verify`).then((resp) => {
      if (resp.error_id) {
        return;
      }
      setChildVerificationDetails(resp);
    });
  };

  const Subscriptions = ({ data }) => {
    return (
      <div className="parentCard">
        {data &&
          data
            .filter(
              (account) =>
                account.account_type === "child" &&
                account.uris.subscription_verify
            )
            .map((child) => {
              return (
                <>
                  <Grid
                    container
                    className={classes.cardContainer}
                    style={{ paddingBottom: "0px" }}
                    key={child.account_id}
                  >
                    <Grid item xs={4} sm={4} md={5} lg={5}>
                      <div className={classes.consentContainer}>
                        <div className={classes.consentHead}>
                          {child.first_name} {child.last_name}{" "}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <span className={classes.unverified}>
                        {t("dashboard-headings.unverified")}
                      </span>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={3}>
                      <span
                        className={classes.delete}
                        onClick={() => getChildVerification(child.account_id)}
                      >
                        {t("dashboard-headings.verify")}
                      </span>
                    </Grid>
                  </Grid>
                  {child.account_id === childAccountId && (
                    <div className={classes.verificationContainer}>
                      <BrainTree
                        submit={(nonce) => verifyChild(nonce, child.account_id)}
                        account_id={child.account_id}
                        trasactionDetails={childVerificationDetails}
                        btn={t("dashboard-headings.verify-child")}
                      />
                    </div>
                  )}
                </>
              );
            })}{" "}
      </div>
    );
  };

  const Statements = () => {
    const FormRow = (props) => {
      const { name, card_id, months } = props;

      return (
        <>
          {months &&
            months.map((month) => (
              <Grid item xs={4} sm={2} md={2} lg={2} key={month.month}>
                <Paper
                  className={classes.paper}
                  onClick={() => downloadStatement(card_id, month, name)}
                >
                  {" "}
                  <div>{month.year}</div>
                  <div>{month.month}</div>
                </Paper>
              </Grid>
            ))}
        </>
      );
    };

    return (
      <div className="parentCard">
        {details &&
          details.cards &&
          details.cards
            .filter((card) => card.status === "ACTIVE")
            .map((card) => {
              return (
                <Grid
                  container
                  className={classes.cardContainer}
                  key={card.card_id}
                >
                  <Grid container style={{ marginTop: "10px" }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={7}
                      className={classes.nameContainer}
                    >
                      <FaUser
                        size={40}
                        color={"#ff8300"}
                        style={{ marginRight: "10px" }}
                      />
                      <span>{card.first_name_on_card}</span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={5}
                      className={classes.iconContainer}
                    >
                      <div
                        className={classes.subContainer}
                        onClick={() => setSelected("pdf")}
                      >
                        <FaFilePdf
                          size={30}
                          color={selected === "pdf" ? "#ff8300" : "grey"}
                          className={classes.profileIcon}
                        />
                        <span>{t("pdf")}</span>
                      </div>
                      <div
                        className={classes.subContainer}
                        onClick={() => setSelected("csv")}
                      >
                        <FaFileCsv
                          size={30}
                          color={selected === "csv" ? "#ff8300" : "grey"}
                          className={classes.profileIcon}
                        />

                        <span>{t("csv")}</span>
                      </div>
                      <div
                        className={classes.subContainer}
                        onClick={() => setSelected("html")}
                      >
                        <FaHtml5
                          size={30}
                          color={selected === "html" ? "#ff8300" : "grey"}
                          className={classes.profileIcon}
                        />

                        <span>{t("html")}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container style={{ margin: "10px 0px" }}>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={1}
                      className={classes.statementSubContainer}
                    >
                      <FormRow
                        months={prevMonths.filter((month, i) => i < 6)}
                        card_id={card.card_id}
                        name={card.first_name_on_card}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      spacing={1}
                      className={classes.statementSubContainer}
                    >
                      <FormRow
                        months={prevMonths.filter((month, i) => i >= 6)}
                        card_id={card.card_id}
                        name={card.first_name_on_card}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}{" "}
      </div>
    );
  };

  const ChangeCard = () => {
    return (
      <div className="parentCard">
        <Grid container className={classes.cardContainer}>
          <Grid item xs={8} sm={8} md={9} lg={9}>
            <div>
              <span style={{ textTransform: "capitalize" }}>
                {paymentDetails.card_type || ""}
              </span>{" "}
              {`${t("ending")} ${paymentDetails.last_4 || "0000"}`}
            </div>
            <div>
              {t("expires")}: {paymentDetails.expiration_month || "MM"}/
              {paymentDetails.expiration_year || "YYYY"}
            </div>
          </Grid>

          <Grid item xs={4} sm={4} md={3} lg={3}>
            <span
              onClick={() => setChangePaymentDetails("change")}
              className={classes.delete}
            >
              {t("change")}
            </span>
            <br />
            {paymentVerify && (
              <span
                onClick={() => setChangePaymentDetails("verify")}
                className={classes.delete}
              >
                {t("verify")}
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const deleteDevice = (device) => {
    deleteAPI(`v2/devices/${device.persistent_cookie}`).then((resp) => {
      if (resp.status === "Success") {
        setMessage(t("dashboard-dialog.device-delete"));
        setOpen(true);
      }
    });
  };

  const deleteConsent = (consent) => {
    deleteAPI(`v2/openbanking/consents/${consent.consent_id}`).then((resp) => {
      if (resp.error_id === "NO_CONTENT") {
        setMessage(t("dashboard-dialog.consent-delete"));
        setOpen(true);
      }
    });
  };

  const navigateToCheckout = () =>
    props.history.push({
      pathname: "/v2/checkout",
      state: { additionalCard: hasPendingOrders || hasOwnCards, pending: true },
    });

  const select = (selection) => {
    navigateToReplaceCard(replaceOrder, selection);
  };

  const close = () => {
    setReplaceOrder(false);
  };

  const navigateToReplaceCard = (card, cancel_old) => {
    sessionStorage.setItem("mode", "replace");
    sessionStorage.setItem("tos_accepted", details.tos_accepted);
    props.history.push({
      state: {
        card_id: card.card_id,
        childName: card.first_name_on_card,
        cancel_old: cancel_old,
      },
      pathname: "/v2/replace-card",
    });
  };

  const navigateToAddChild = () => {
    props.addChild(false);
    sessionStorage.setItem("mode", "additional");
    sessionStorage.setItem("tos_accepted", details.tos_accepted);
    props.history.push({
      pathname: "/v2/card-design",
      childLimit: childLimit,
      additionalCard: true,
    });
  };

  const getPreviousMonths = () => {
    let monthName = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let d = new Date();
    d.setDate(1);
    let prevMonths = [];
    for (let i = 0; i <= 11; i++) {
      let prevMonth = {};
      prevMonth.month = monthName[d.getMonth()];
      prevMonth.monthNo = d.getMonth() + 1;
      prevMonth.year = d.getFullYear();
      prevMonths.push(prevMonth);
      d.setMonth(d.getMonth() - 1);
    }
    setPrevMonths([...prevMonths]);
  };

  const hasPendingOrders =
    details &&
    details.accounts &&
    details.accounts.filter((account) => account.pending_order).length > 0;
  // const hasProcessingOrders = details && details.processing_cardorders && details.processing_cardorders.length > 0
  const hasOwnCards = details && details.cards && details.cards.length > 0;

  useEffect(() => {
    getDesigns();
    getDetails();
    getChildLimit();
    getPreviousMonths();
    getDevices();
    // getConsent()
    getConsents();
  }, [getDesigns]);

  const submit = (nonce) => {
    const account_id = getAccountId();
    setPaymentError("");

    let body = {
      nonce: nonce,
    };

    postAPI(
      `v2/account/${account_id}/payment_method${
        changePaymentDetail === "verify" ? "/verify" : ""
      }`,
      body
    ).then((resp) => {
      if (resp.data === "Success") {
        if (changePaymentDetail === "verify") {
          setMessage(t("dashboard-headings.verify-card-success"));
          setOpen(true);
          // getDetails()
        } else getPaymentDetails(account_id);
        return;
      }

      // Fix to show error alert message after loader ends
      setTimeout(() => {
        setAlertOpen(true);
        setAlert(resp.user_message);
      }, 3000);

      if (
        resp.error_id === "PaymentFailureError" ||
        resp.error_id === "InvalidCardDetailsError"
      ) {
        setPaymentDetails("");
        if (changePaymentDetail === "verify")
          getVerificationDetails(account_id);
        else getPaymentDetails(account_id);
      }
    });
  };

  const verifyChild = (nonce, childAccountId) => {
    let body = {
      nonce: nonce,
    };
    postAPI(`v2/account/${childAccountId}/subscription/verify`, body).then(
      (resp) => {
        if (resp.data === "Success") {
          setMessage(t("dashboard-headings.verify-child-success"));
          setOpen(true);
          setChildAccountId(false);
          // getDetails()
          return;
        }

        if (
          resp.error_id === "PaymentFailureError" ||
          resp.error_id === "InvalidCardDetailsError"
        ) {
          setChildVerificationDetails("");
          getChildVerification(childAccountId);
        } else {
          setAlertOpen(true);
          setChildAccountId(false);
          setAlert(resp.user_message);
        }
      }
    );
  };

  // const keyPress = (e) => {
  //     if (e.keyCode == 13) {
  //         gifterRedirection(e)
  //     }
  // }

  // const gifterRedirection = (e) => {
  //     let url = e.target.value || gifterUrl;
  //     if (url.indexOf('/c/') < 0) {
  //         setLinkError(t('invalid_format'))
  //         return
  //     }

  //     if (url.indexOf('//') < 0) {
  //         url = 'https://' + url;
  //     }
  //     window.open(url, "_blank")
  // }

  const mobile = isMobile();

  let filteredArray =
    details && details.cards && details.cards.filter((a) => a.user !== null);

  return (
    <>
      <AppHeader />
      <div id={"dashboardScreen"}>
        <Grid item xs={12} sm={9} md={8} lg={6} className="contentWrapper">
          <h4 className="screen-title">{t("dashboard.title")}</h4>
          <h5 className="screen-subtitle">{t("dashboard.subtitle")}</h5>
          {details ? (
            <Card className={classes.cardcustom}>
              <div className="padDiv">
                {error && <p className="errorMessage">{error}</p>}
                {details.verified === false && (
                  <p className={classes.subHeading}>
                    <Trans i18nKey="verify-your-mobile-number">
                      {t("mobile_verification1")}
                      <a
                        href="osper://verify.mobile.number"
                        title={
                          !mobile
                            ? "It seems you are not on a mobile device..."
                            : ""
                        }
                      >
                        {t("osper_app")}
                      </a>{" "}
                      {t("mobile_verification2")}
                      {t("mobile_verification_warning")}
                    </Trans>
                  </p>
                )}
                {details &&
                  details.accounts &&
                  details.accounts.length > 0 &&
                  details.accounts.filter(
                    (account) =>
                      account.account_type === "child" &&
                      account.uris.subscription_verify
                  ).length > 0 && (
                    <>
                      <p className={classes.processingCardsHeader}>
                        {t("dashboard-headings.subscriptions")}
                      </p>
                      <p className={classes.subHeading}>
                        {t("dashboard-headings.subscriptions-sub-heading")}
                      </p>
                      <Subscriptions data={details.accounts} />
                    </>
                  )}

                {details &&
                  ((details.processing_cardorders &&
                    details.processing_cardorders.length > 0) ||
                    (details.cards && details.cards.length > 0)) && (
                    <>
                      <p className={classes.processingCardsHeader}>
                        {t("dashboard-headings.cards")}
                      </p>
                      <p className={classes.subHeading}>
                        {t("dashboard-headings.card-sub-heading")}
                      </p>
                    </>
                  )}

                {details.processing_cardorders &&
                  details.processing_cardorders.map((processing_cardorder) => {
                    return (
                      <ProcessingCardOrder
                        order={processing_cardorder}
                        processing={true}
                      />
                    );
                  })}

                {filteredArray &&
                  filteredArray
                    .sort((a, b) => a.user.account_id - b.user.account_id)
                    .map((card, index) => {
                      // Multiple cards of same child is merged together
                      const prevEl = filteredArray[index - 1]
                        ? filteredArray[index - 1].user.account_id
                        : 0;
                      const nextEl = filteredArray[index + 1]
                        ? filteredArray[index + 1].user.account_id
                        : 0;
                      return (
                        card.user.account_id !== prevEl && (
                          <Grid container key={card.card_id}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <ProcessingCardOrder
                                order={card}
                                replace={true}
                                pending={card.status === "PENDING_ACTIVATION"}
                                replaceAvailable={card.uris.replace.methods}
                                className="parentCard"
                                multiple={card.user.account_id === nextEl}
                                nextCard={
                                  card.user.account_id === nextEl
                                    ? filteredArray[index + 1]
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        )
                      );
                    })}

                {details &&
                  details.cards &&
                  details.cards
                    .filter((a) => a.user === null)
                    .map((card) => {
                      // Multiple cards of same child is merged together

                      return (
                        <Grid container key={card.card_id}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ProcessingCardOrder
                              order={card}
                              replace={true}
                              pending={card.status === "PENDING_ACTIVATION"}
                              replaceAvailable={card.uris.replace.methods}
                              className="parentCard"
                            />
                          </Grid>
                        </Grid>
                      );
                    })}

                {hasPendingOrders &&
                  details.accounts
                    .filter((account) => account.pending_order)
                    .map((account) => {
                      return (
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ProcessingCardOrder
                              order={account}
                              cancel={true}
                              className="parentCard"
                            />
                          </Grid>
                        </Grid>
                      );
                    })}

                {hasPendingOrders && (
                  <Grid container className="next-button-container">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <DefaultButton
                        name={t("dashboard-headings.go-to-checkout")}
                        onClick={navigateToCheckout}
                      />
                    </Grid>
                  </Grid>
                )}

                {childLimit > 0 && (
                  <Grid container className="next-button-container">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <DefaultButton
                        // onClick={() => props.history.push('/v2/add-child')}
                        onClick={navigateToAddChild}
                        disabled={!details.email_verified}
                        name={
                          <div>
                            <span className={classes.btnIndicatorAdd}>
                              {"+"}
                            </span>
                            {t("dashboard-headings.add-child")}
                          </div>
                        }
                      />
                    </Grid>
                    {!details.email_verified && (
                      <p>{t("dashboard-headings.add-child-restriction")}</p>
                    )}
                  </Grid>
                )}

                {details &&
                  details.contributees &&
                  details.contributees.length > 0 && (
                    <>
                      <p className={classes.processingCardsHeader}>
                        {t("dashboard-headings.gifting")}
                      </p>
                      <p className={classes.subHeading}>
                        {t("dashboard-headings.gift-sub-heading")}
                      </p>
                      <GiftCodes data={details.contributees} />
                    </>
                  )}
                {/* <TextField id="giftCode"
                                name="giftCode"
                                className="gifterArea"
                                placeholder={t('gift-code-placeholder')}
                                onKeyDown={keyPress}
                                onChange={() => linkError ? setLinkError('') : null}
                                onBlur={(e) => setGifterUrl(e.target.value)}
                                variant="filled"
                                InputProps={{
                                    maxLength: 12,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="search"
                                                onClick={gifterRedirection}><Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            /> */}

                {changePaymentDetail !== "NA" &&
                  (changePaymentDetail !== "nocard" ||
                    (changePaymentDetail === "nocard" && hasPendingOrders)) && (
                    <p className={classes.processingCardsHeader}>
                      {t("dashboard-headings.payment-details")}
                    </p>
                  )}

                {changePaymentDetail ? (
                  changePaymentDetail !== "NA" ? (
                    changePaymentDetail === "nocard" && hasPendingOrders ? (
                      <div>{t("go-to-checkout")}</div>
                    ) : (
                      <div className={classes.braintreeContainer}>
                        <span
                          onClick={() => {
                            setChangePaymentDetails(false);
                            setPaymentError("");
                          }}
                          className={`${classes.delete} ${classes.cancel}`}
                        >
                          {t("back")}
                        </span>
                        <BrainTree
                          submit={submit}
                          error={paymentError}
                          trasactionDetails={
                            changePaymentDetail === "verify"
                              ? verificationDetails
                              : paymentDetails
                          }
                          btn={
                            changePaymentDetail === "verify"
                              ? t("dashboard-headings.verify-payment")
                              : ""
                          }
                          account_id={getAccountId()}
                        />
                      </div>
                    )
                  ) : null
                ) : (
                  <ChangeCard />
                )}

                {details &&
                  details.cards &&
                  details.cards.filter((card) => card.status === "ACTIVE")
                    .length > 0 && (
                    <>
                      <p className={classes.processingCardsHeader}>
                        {t("dashboard-headings.statements")}
                      </p>
                      <Statements />
                    </>
                  )}
                {devices && devices.length > 0 && (
                  <>
                    <p className={classes.processingCardsHeader}>
                      {t("dashboard-headings.your-devices")}
                    </p>
                    <Devices />
                  </>
                )}
                {consents &&
                  consents.filter((consent) => consent.state === "granted")
                    .length > 0 && (
                    <>
                      <p className={classes.processingCardsHeader}>
                        {t("dashboard-headings.your-consents")}
                      </p>
                      <Consents />
                    </>
                  )}
                {replaceOrder && (
                  <ReplaceDialog close={close} select={select} />
                )}
                {cancelCard && (
                  <ConfirmDialog
                    close={() => setCancelCard(false)}
                    select={deleteChild}
                    name={cancelCard.name}
                  />
                )}
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical, horizontal }}
                >
                  <Alert onClose={handleClose} severity="success">
                    {message}
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={alertOpen}
                  autoHideDuration={10000}
                  onClose={() => setAlertOpen(false)}
                >
                  <Alert severity="error">{alert}</Alert>
                </Snackbar>
                <Loader />
              </div>
            </Card>
          ) : (
            <h3>Loading...</h3>
          )}
        </Grid>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDesigns: () => dispatch({ type: "GET_DESIGNS" }),
    addChild: (value) => dispatch({ type: "ADD_CHILD_SUCCESS", value }),
  };
};

export default connect(null, mapDispatchToProps)(withTranslation()(DashBoard));
