import React, { useEffect, useState } from "react";
import dropin from "braintree-web-drop-in";
import Grid from "@material-ui/core/Grid";
import { DefaultButton } from "../../ui-components/button/index";
import { useTranslation } from "react-i18next";
import { getBrowsername } from "../../common/constants";
import { postAPI } from "../../common/api";

export default function BraintreeDropIn(props) {
  const { t } = useTranslation();

  const { error, trasactionDetails, btn, submit, amount, account_id } = props;

  const [gotClientToken, setGotClientToken] = useState(false);

  const [braintreeInstance, setBraintreeInstance] = useState(undefined);

  useEffect(() => {
    const initializeBraintree = () => {
      // const box = document.getElementById('braintree-drop-in-div');
      // box.replaceChildren();
      if (trasactionDetails && trasactionDetails.braintree_token && account_id)
        dropin.create(
          {
            authorization: trasactionDetails.braintree_token,
            container: `#braintree-drop-in-div-${account_id}`,
            threeDSecure: true,
          },
          function (error, instance) {
            if (error) {
              log(error);
            } else {
              setBraintreeInstance(instance);
            }
          }
        );
    };

    if (braintreeInstance) {
      braintreeInstance.teardown().then(() => {
        initializeBraintree();
      });
    } else {
      initializeBraintree();
    }

    setTimeout(() => {
      setGotClientToken(true);
    }, 5000);
  }, [trasactionDetails]);

  const log = (err) => {
    let body = {
      action: "api.log.failure",
      properties: {
        key: "3ds_error",
        account_id: account_id,
        error: err,
        view: "A way to identify the web screen",
        braintree_lib_name: "braintree-web-drop-in-react",
        source: getBrowsername(),
        url: window.location.href,
      },
    };
    postAPI("log", body);
  };

  const buy = () => {
    if (braintreeInstance) {
      if (trasactionDetails.info_3ds.is_3ds) {
        const billingAddress = trasactionDetails
          ? trasactionDetails.info_3ds.billingAddress
          : "";
        braintreeInstance.requestPaymentMethod(
          {
            threeDSecure: {
              amount: amount || trasactionDetails.info_3ds.amount,
              billingAddress: billingAddress,
              //forced challenge
              challengeRequested: true,
              // additional 3ds params
            },
          },
          onSubmit
        );
      } else {
        braintreeInstance.requestPaymentMethod(onSubmit);
      }
    }
  };

  const onSubmit = (error, payload) => {
    console.log('brain tree log :', payload)
    if (error) {
      log(error);
    } else {
      const paymentMethodNonce = payload.nonce;
      submit(paymentMethodNonce);
    }
  };

  return (
    <div>
      {trasactionDetails && trasactionDetails.braintree_token && account_id ? (
        <>
          <div id={`braintree-drop-in-div-${account_id}`} />
          <p className="errorMessage">{error}</p>
          <Grid container className="next-button-container">
            <Grid item xs={12} sm={4} md={3} lg={3} className="payButton">
              <DefaultButton
                onClick={buy}
                name={btn || t("enter-card-details")}
                id="next-btn"
              ></DefaultButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <div>
          {gotClientToken ? (
            <p>
              Something went wrong, <span>try again</span>
            </p>
          ) : (
            <h4>Loading...</h4>
          )}
        </div>
      )}
    </div>
  );
}
