import React from 'react'
// import { withTranslation } from 'react-i18next';
import './index.css'



export default function SuccessHeader(props) {



    // const { t } = props;
    return (<div className='successHead'>
        
    </div>)

}




