import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { getAPI, postAPI, putAPI } from "../../common/api";
import BrainTree from '../BrainTree/index'
// const { t } = this.props;
import Card from '@material-ui/core/Card';
import Loader from '../../ui-components/loader/loader'
import { AppHeader } from '../layout/index'
import { connect } from 'react-redux'
import { getAccountId, setAccountId } from '../../common/constants';

const useStyles = makeStyles(() => ({
    center: {
        margin: 'auto'
    },
    change: {
        marginTop: '15px',
        marginRight: '13px',
        fontWeight: 500,
        color: 'rgb(255, 131, 0)',
        float: 'right'
    },
    container: {
        borderBottom: '1px solid #eeeeee'
    },
    sub: {
        fontSize: '12px'
    },
    failMessage: {
        margin: '20px 0px 0px 0px'
    }
}));


const CheckOut = (props) => {

    const [checkouInfo, setCheckoutInfo] = useState([]);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [trasactionDetails, setTrasactionDetails] = useState({});

    const { t } = useTranslation();
    const classes = useStyles()
    const { card_id, card_design_id, additionalCard, pending, cancel_old
    } = props.history.location.state || "";

    const { addSteps, history } = props
    const account_id = getAccountId()

    useEffect(() => {

        setMessage('')

        if (card_id) {
            getAPI(`card/${card_id}/${card_design_id}/replace_fee`).then(data => {
                if (data.error_id) {
                    setMessage(data.user_message)
                } else {
                    setCheckoutInfo(data)
                }
            })

            getAPI(`card/${card_id}/replace/${card_design_id}`).then(d => {
                setTrasactionDetails(d)
            })

        } else {
            if (account_id !== null) {
                getAPI(`v2/account/${account_id}/payment`).then(d => {
                    setTrasactionDetails(d)
                });
            }
            getAPI('account/checkout_info').then(data => {
                if (data.error_id) {
                    setMessage(data.user_message)
                } else {
                    setCheckoutInfo(data)
                }
            })
        }



    }, [card_id, card_design_id, account_id])

    const screensAdditional = [
        "card-design", "about-your-child", "checkout"
    ]
    const screens = [
        "account", "about-you", "card-design", "about-your-child", "checkout"
    ]
    const screensReplace = [
        "card-design", "checkout"
    ]


    useEffect(
        () => {
            if (pending) {
                if (additionalCard)
                    addSteps(screensAdditional)
                else if (card_id)
                    addSteps(screensReplace)
                else
                    addSteps(screens)

            }
        }, [screens, addSteps, screensAdditional, pending, additionalCard, card_id, screensReplace]
    )


    const toTitleCase = (str) => {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    const submit = (nonce) => {
        if (card_id) {
            let body = { card_design_id: card_design_id, nonce: nonce, cancel_old: cancel_old }
            postAPI(`card/${card_id}/replace/${card_design_id}`, body).then((data) => {
                if (data.error_id) {
                    setError(data.user_message)
                    return
                }
                history.push({
                    pathname: '/v2/success',
                    state: { replacement: true }
                })
            })
        } else {
            let body = {
                payment_method_nonce: nonce,
            }
            putAPI('account/payment', body).then((data) => {
                if (data.error_id) {
                    if (data.error_id === 'NO_CONTENT') {
                        history.push({
                            pathname: '/v2/success',
                            state: { additional: true }
                        })
                        return
                    }
                    setError(data.user_message)
                    if (data.error_id === 'PaymentFailureError' || data.error_id === 'InvalidCardDetailsError') {
                        setTrasactionDetails('')
                        if (card_id) {
                            getAPI(`card/${card_id}/replace/${card_design_id}`).then(d => {
                                setTrasactionDetails(d)
                            })
                        } else {
                            if (account_id !== null) {
                                getAPI(`v2/account/${account_id}/payment`).then(d => {
                                    setTrasactionDetails(d)
                                });
                            }
                        }
                        // geClientToken().then(d => {
                        //     setTrasactionDetails(d)
                        // })
                    }

                    return
                }
                setAccountId(data.account_id)
                history.push(`/v2/success?ref_code=${data.code}&bonus_amount=${data.bonus_amount}&super_bonus_amount=${data.super_bonus_amount}`)
                // history.push({
                //     pathname: `/v2/success?ref_code=${data.code}&bonus_amount=${data.bonus_amount}&super_bonus_amount=${data.super_bonus_amount}`,
                //     state: { account_id: data.account_id }
                // })
            })
        }

    }

    return (
        <>
            <AppHeader selected={additionalCard ? 2 : card_id ? 1 : 3} checkout={true} />

            <Grid item xs={12} sm={10} md={8} lg={6} className={classes.center}>

                <Card className='customCard'>

                    <div className='padDiv'>
                        {message && <h2 className={classes.failMessage}>{message}<a href='/v2/login'> Back to Login</a></h2>}
                        {!message && <>
                            <h2>{t('checkout.title')}</h2>
                            <div className="form-group confirm-kyc-group text-left">
                                <div className={classes.container}>
                                    <div className="checkout-detail">
                                        <span className="confirm-value">{t('delivery-postcode')}:</span>
                                        <span className="confirm-value">{checkouInfo.details ? checkouInfo.details.postcode : ''}</span>
                                        {/* <br /> */}
                                    </div>
                                    <div className="checkout-detail">
                                        <span className="confirm-value">{t('delivery-fee')}:</span>
                                        <span className="confirm-value">{checkouInfo.details ? checkouInfo.details.delivery_fee : ''}</span>
                                        {/* <br /> */}
                                    </div>
                                    {checkouInfo.details && checkouInfo.details.design && checkouInfo.details.design.map((design, i) => {
                                        return (
                                            <div className="checkout-detail" key={i}>
                                                <span className="confirm-value">{design.count} {design.group.toUpperCase() === 'OSPER' ? design.name : `premium ${toTitleCase(design.group)}`} {design.count > 1 ? "cards" : "card"}:</span>
                                                <span className="confirm-value">{design.price}</span>
                                                {/* <br/> */}
                                            </div>

                                        )
                                    })}
                                    {/* {card_id && <div className={classes.container}>
                                        <div className="checkout-detail">
                                            <span className="confirm-value">Replacement fee:</span>
                                            <span className="confirm-value">{checkouInfo.details ? checkouInfo.details.replacement_card_fee : ''}</span>
                                        </div>
                                    </div>} */}
                                </div>
                                <div className={classes.container}>
                                    <div className="checkout-detail">
                                        <span className="confirm-value">{t('total')}</span>
                                        <span className="confirm-value">{checkouInfo.details ? checkouInfo.details.total_price : ''}</span>
                                    </div>
                                </div>
                                {!card_id && <div className={classes.container}>
                                    <div className="checkout-detail">
                                        <span className="confirm-value"> {t('monthly_sub')}<br />
                                            <span className={classes.sub}>
                                                {t('checkout_sub_msg1')}{checkouInfo.details ? checkouInfo.details.subscription_delay : '30'}  {t('checkout_sub_msg2')}
                                            </span>
                                        </span>
                                        <span className="confirm-value">
                                            {checkouInfo.details ? checkouInfo.details.subscription_price : ''}
                                        </span>
                                    </div>
                                </div>}
                            </div>
                            <BrainTree
                                submit={submit} error={error} trasactionDetails={trasactionDetails} btn={t('pay')} account_id={account_id}
                            />
                        </>
                        }  <Loader />
                    </div></Card>
            </Grid>
        </>)

}



const mapDispatchToProps = dispatch => {
    return {
        addSteps: (steps) => dispatch({ type: "ADD_STEPS", value: steps }),

    }
};




export default connect(null, mapDispatchToProps)(withTranslation()(CheckOut));
