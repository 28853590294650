import React from "react";
import {connect} from "react-redux";
import withWidth from "@material-ui/core/withWidth";
import {useTranslation, withTranslation} from "react-i18next";

const TermsChecklist = () => {
  const { t } = useTranslation();

  return <ul className={'tickList'}>
    <li><a href='/terms#privacy-policy' target='_blank' rel='noreferrer'>{t('account.privacyPolicy')}</a></li>
    <li><a href='/terms#card-terms' target='_blank' rel='noreferrer'>{t('account.cardTermsAndConditions')}</a></li>
    <li><a href='/terms#service-terms' target='_blank' rel='noreferrer'>{t('account.osperTermsAndConditions')}</a></li>
  </ul>
}

export default connect()(withWidth()(withTranslation()(TermsChecklist)));
