import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import AboutYourChildForm from "./aboutYourChildForm";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { getAPI, postAPI } from "../../common/api";
import { withTranslation, useTranslation } from "react-i18next";
import Loader from "../../ui-components/loader/loader";
import { AppHeader } from "../layout/index";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  CHILD_MAX_YEAR,
  CHILD_MIN_YEAR,
  getUsername,
} from "../../common/constants";

const AboutYourChild = (props) => {
  const { t } = useTranslation();
  const {
    childName,
    history,
    addChildSuccess,
    addChild,
    Order,
    saveChildren,
    saveStep,
    Kyc,
    Children,
    Steps,
  } = props;
  // const [children, setChildren] = useState([])
  const [errorCode, setErrorCode] = useState(0);
  const [username, setUsername] = useState(
    JSON.parse(sessionStorage.getItem("usernameTyped")) || []
  );
  const [initialValues, setInitalValues] = useState({ children: [] });
  const [error, setError] = useState(false);
  const additionalCard = sessionStorage.getItem("mode") === "additional";
  const [index, setIndex] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const validationSchema = Yup.object().shape({
    children: Yup.array()
      .of(
        Yup.object().shape({
          first_name: Yup.string()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t(
                "first-name-placeholder"
              )}`
            )
            .min(2, t("2-character-rqrd"))
            .max(12, t("first-name-max"))
            .matches(
              /^[a-zA-Z .'-]{2,30}$/,
              `${t("latin-letter-exclusion-error")} ${t(
                "child's-placeholder"
              )} ${t("first-name-placeholder")}`
            ),
          last_name: Yup.string()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t(
                "last-name-placeholder"
              )}`
            )
            .min(2, t("2-character-rqrd"))
            .max(30, t("last-name-max"))
            .matches(
              /^[a-zA-Z .'-]{2,30}$/,
              `${t("latin-letter-exclusion-error")} ${t(
                "child's-placeholder"
              )} ${t("last-name-placeholder")}`
            ),
          gender: Yup.string().required(t("gender-validation-child")),
          day: Yup.number()
            .nullable()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t("day")}`
            )
            .min(1, t("day-validation"))
            .max(31, t("day-validation")),
          month: Yup.number()
            .nullable()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t("month")}`
            )
            .min(1, t("month-validation"))
            .max(12, t("month-validation")),
          year: Yup.number()
            .nullable()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t("year")}`
            )
            .min(CHILD_MIN_YEAR, t("child-age-validation-max"))
            .max(CHILD_MAX_YEAR, t("child-age-validation")),
          username: Yup.string()
            .min(2, t("2-character-rqrd"))
            .required(t("user-name-error")),
          password: Yup.string()
            .min(8, t("password-validation-child"))
            .required(t("password-error-child")),
          email: Yup.string().email(t("email-validation")),
          mobile: Yup.string().matches(
            /^07[0-9]{9}$/,
            t("mobile-number-validation")
          ),
        })
      )
      .required("Must have children") // these constraints are shown if and only if inner constraints are satisfied
      .min(1, "Minimum of 1 child"),
  });

  useEffect(() => {
    if (additionalCard && addChildSuccess) history.push("/v2/dashboard");
    setInitalValues({ children: Children });
  }, [Children, addChildSuccess, history]);

  useEffect(() => {
    // if steps is not initialised redirect to the first page
    if (Steps.length > 0)
      setIndex(Steps.findIndex((p) => p.id === "about-your-child"));
    else history.push("/v2");
    // if no child's card is added redirect to the card design page

    if (Children.length <= 0) history.push("/v2/card-design");
  }, [Steps, Children.length, history]);

  const finalValidation = async (child, i, values) => {
    let response = await Promise.all([
      validateField(child.first_name, "name", i, values),
      validateField(child.mobile, "number", i, values),
      validateField(
        `${child.day}-${child.month}-${child.year}`,
        "age",
        i,
        values
      ),
      validateField(child.email, "email", i, values),
      validateField(child.username, "username", i, values),
      validateField(child.password, "password", i, values),
    ]);

    return response;
  };

  const submitValues = async (values) => {
    setError(errorCode);
    if (errorCode !== 0) return;

    setError("");

    let response = await Promise.all(
      values.children.map((item, i) => finalValidation(item, i, values))
    );
    for (let i = 0; i < response.length; i++) {
      for (let j = 0; j < response[i].length; j++) {
        if (response[i][j] === false)
          setError("There was an error with the request you sent");
        if (response[i][j] === false || response[i][j] === "error") return;
      }
    }

    saveChildren(values.children);

    saveStep({ completed: true, index: index });

    if (additionalCard) {
      additionalCardOrder(values);
      return;
    }
    if (Order === "B") {
      history.push("/v2/account");
      return;
    }

    // TODO: shouldn't it be part of reducer?
    if (Kyc.isAccount && Kyc.isAboutYou) {
      history.push("/v2/order-preview");
    } else {
      !Kyc.isAccount ? history.push("/v2/") : history.push("/v2/about-you");
    }
  };

  const additionalCardOrder = (values) => {
    let children = values.children;
    children.map(
      (child) =>
        (child.date_of_birth = `${child.year}-${child.month}-${child.day}`)
    );
    let body = {
      children,
      source: "website",
    };
    postAPI("v2/account/addchild", body).then((data) => {
      if (data.error_id) {
        setError(data.user_message);
        return;
      }
      history.push({
        pathname: "/v2/checkout",
        state: { additionalCard: true },
      });
      saveChildren([]);
      addChild(true);
    });
  };

  const validateField = async (value, type, i, values, LOADER_DISABLED) => {
    let children = [...values.children];
    let child = {};
    if (!children[i]) return false;
    child = children[i];
    let status = false;
    let resp;
    if (type === "name") {
      if (
        value.length < 2 ||
        !/^[a-zA-Z .'-]{2,30}$/.test(value) ||
        value.length > 12
      ) {
        return false;
      }

      resp = await getAPI(
        `name/${Kyc.first_name ? Kyc.first_name : "null"}/${value}`,
        "",
        false,
        LOADER_DISABLED
      ).then((data) => {
        switch (data.error_id) {
          case "INVALID_PARAM_VALUE":
            child.nameError = t("child-name-validation");
            setErrorCode("nameError");
            status = "error";
            break;
          case "ERROR":
            child.nameError = t("please-try-again");
            setErrorCode(400);
            status = "error";
            break;
          case "NO_CONTENT":
            child.username = getUsername(username, child, i);
            delete child.nameError;
            setErrorCode(0);
            status = true;
            break;
          default:
            break;
        }
        updateInitalValues(values, children, child, i);
        return status;
      });
    } else if (type === "number") {
      delete child.numberError;

      const regexp = /^07[0-9]{9}$/;
      if (!regexp.test(value)) return;
      if (value === Kyc.mobile) {
        child.numberError = t("same-number-parent-child-error");
        setErrorCode(400);
        updateInitalValues(values, children, child, i);
        return "error";
      }
      const childArray = children.filter(
        (child, index) => child.mobile === value && index !== i
      );
      if (childArray.length > 0) {
        child.numberError = `${child.first_name} ${t(
          "same-email-siblings-error1"
        )} number
          ${t("same-email-siblings-error2")} ${childArray[0].first_name}. ${t(
          "same-email-siblings-error3"
        )} ${child.first_name}
          ${t("same-email-siblings-error4")} number ${t(
          "same-email-siblings-error5"
        )}.`;
        setErrorCode(400);
        updateInitalValues(values, children, child, i);
        return "error";
      }
      resp = await getAPI(
        `mobile/${value}/?orders=true&accounts=true`,
        "",
        false,
        LOADER_DISABLED
      ).then((data) => {
        switch (data.error_id) {
          case "NOT_FOUND":
            delete child.numberError;
            setErrorCode(0);
            status = true;
            break;
          case 400:
            child.numberError =
              t("mobile-number-api-failed") + t("mobile-number-validation");
            setErrorCode(400);
            status = "error";
            break;
          case "NO_CONTENT":
            child.numberError = [
              t("mobile-number-api-exist"),
              <a
                href="/contact"
                target="_blank"
                style={{ color: "#fff" }}
                rel="noreferrer"
              >
                {t("contact-us")}
              </a>,
              ".",
            ];
            setErrorCode("numberError");
            status = "error";
            break;
          default:
            status = false;
            break;
        }
        updateInitalValues(values, children, child, i);
        return status;
      });
    } else if (type === "age") {
      if (!value) return;
      resp = await getAPI(
        `child_age/${value}`,
        "",
        false,
        LOADER_DISABLED
      ).then((data) => {
        switch (data.error_id) {
          case "InvalidAgeError":
            child.ageError = data.user_message;
            setErrorCode("ageError");
            status = "error";
            break;
          case "ERROR":
            child.ageError = t("please-try-again");
            setErrorCode(400);
            status = "error";
            break;
          case "TOO_MANY_REQUEST":
            child.ageError = t("too-many-request");
            setErrorCode(429);
            setAlertOpen(true);
            setAlert("Too many request. Please try after sometime");
            status = "error";
            break;
          case "NO_CONTENT":
            // child.username = !username[i] ? child.email || `${child.first_name.replace(/ /g, "")}${child.last_name.replace(/ /g, "")}${Math.floor(Math.random() * (999 - 100 + 1) + 100)}` : child.username;
            delete child.ageError;
            setErrorCode(0);
            status = true;
            break;
          default:
            status = false;
            break;
        }

        updateInitalValues(values, children, child, i);
        return status;
      });
    } else if (type === "email") {
      delete child.emailError;

      const regexp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
      if (!regexp.test(value)) return;
      if (value === Kyc.email) {
        setErrorCode(400);
        child.emailError = t("same-email-parent-child-error");
        updateInitalValues(values, children, child, i);
        return "error";
      }
      const childArray = children.filter(
        (child, index) =>
          (child.email === value || child.username === value) && index !== i
      );
      if (childArray.length > 0) {
        child.emailError = `${child.first_name} ${t(
          "same-email-siblings-error1"
        )} email/username
          ${t("same-email-siblings-error2")} ${childArray[0].first_name}. ${t(
          "same-email-siblings-error3"
        )}  ${child.first_name}
          ${t("same-email-siblings-error4")} email ${t(
          "same-email-siblings-error5"
        )}.`;
        setErrorCode(400);
        updateInitalValues(values, children, child, i);
        return "error";
      }
      resp = await getAPI(
        `email/${value}/?orders=true&accounts=true`,
        "",
        false,
        LOADER_DISABLED
      ).then((data) => {
        switch (data.error_id) {
          case "NOT_FOUND":
            child.username = !username[i] ? value : child.username;
            delete child.emailError;
            setErrorCode(0);
            status = true;
            break;
          case 400:
            child.emailError = t("email-api-failed");
            setErrorCode(400);
            status = "error";
            break;
          case "NO_CONTENT":
            child.emailError = [
              t("email-api-exist"),
              <a
                href="/contact"
                target="_blank"
                style={{ color: "#f44336" }}
                rel="noreferrer"
              >
                {t("contact-us")}
              </a>,
              ".",
            ];
            setErrorCode("emailError");
            status = "error";
            break;
          default:
            status = false;
            break;
        }

        updateInitalValues(values, children, child, i);
        return status;
      });

      /* 404: true # Email not currently in use
                         400: false # Request malformed
                         204: false # Email already in use */
    } else if (type === "username") {
      delete child.usernameError;
      if (!value || value.length <= 1) return false;
      if (value === Kyc.email) {
        setErrorCode(400);
        child.usernameError = t("same-username-parent-child-error");
        updateInitalValues(values, children, child, i);
        return "error";
      }
      const childArray = children.filter(
        (child, index) =>
          (child.username === value || child.email === value) && index !== i
      );
      if (childArray.length > 0) {
        child.usernameError = `${child.first_name} ${t(
          "same-username-siblings-error1"
        )} ${childArray[0].first_name}.${t("same-username-siblings-error2")} ${
          child.first_name
        }.`;
        setErrorCode(400);
        updateInitalValues(values, children, child, i);
        return "error";
      }
      resp = await getAPI(`username/${value}`, "", false, LOADER_DISABLED).then(
        (data) => {
          switch (data.error_id) {
            case "NOT_FOUND":
              delete child.usernameError;
              setErrorCode(0);
              status = true;
              break;
            case "ERROR":
              child.usernameError = t("please-try-again");
              setErrorCode(400);
              status = "error";
              break;
            case "NO_CONTENT":
              child.usernameError = "username is already taken.";
              setErrorCode("usernameError");
              status = "error";
              break;
            default:
              status = false;
              break;
          }

          updateInitalValues(values, children, child, i);
          return status;
        }
      );
    } else {
      delete child.passwordError;
      if (!value || value.length < 8) return false;
      if (/\s/g.test(value)) {
        child.passwordError = t("whitespace-error");
        return "error";
      }
      let body = {
        password: value,
        user_inputs: [
          child.first_name,
          child.last_name,
          child.email,
          child.username,
        ],
      };
      resp = await postAPI(
        "password_strength",
        body,
        false,
        LOADER_DISABLED
      ).then((data) => {
        if (data.error_id) {
          child.passwordError = data.user_message
            ? data.user_message
            : data.error_id == "ERROR"
            ? t("too-many-request")
            : t("please-try-again");
          setErrorCode(400);
          if(data.error_id == "ERROR"){
            setAlertOpen(true);
            setAlert("Too many request. Please try after sometime");
            setErrorCode(429);
          }
          updateInitalValues(values, children, child, i);
          return "error";
        } else {
          setErrorCode(0);
          delete child.passwordError;
        }
        /* password score check previous */
        // if (data.feedback) {
        //   let suggestion = data.feedback.suggestions.toString();
        //   suggestion = suggestion.concat(data.feedback.warning)
        //   suggestion = fixBackendTypo(suggestion)
        //   child.passwordWarning = suggestion
        // }
        child.passwordWarning = data.feedback;

        if (data.score < 3) {
          // setErrorCode('error')
          //not blocking the flow even if the password doesnt meet rules.
          status = true;
        } else {
          delete child.passwordWarning;

          status = true;

          // setErrorCode(0)
        }

        updateInitalValues(values, children, child, i);
        return status;
      });
    }

    return resp;
  };
  // const fieldChange = (type, i, values) => {
  //   let children = [...values.children]
  //   let child = {}
  //   if (!children[i])
  //     return
  //   child = children[i]
  //   console.log(child, "child")
  //   if (type === 'name') {
  //     delete child.nameError
  //   }
  //   else if (type === 'number') {
  //     delete child.numberError
  //   } else if (type === 'age') {
  //     delete child.ageError

  //   } else if (type === 'email') {
  //     delete child.emailError
  //   } else if (type === 'username') {
  //     delete child.usernameError
  //   } else {
  //     delete child.passwordError
  //     delete child.passwordWarning
  //   }
  //   // const updatedChild = initialValues.children
  //   // child.nameError = updatedChild.nameError
  //   // child.ageError = updatedChild.ageError
  //   // child.numberError = updatedChild.numberError
  //   // child.emailError = updatedChild.emailError
  //   // child.usernameError = updatedChild.usernameError
  //   // child.passwordError = updatedChild.passwordError
  //   // child.passwordWarning = updatedChild.passwordWarning

  //   setErrorCode(0)
  //   updateInitalValues(values, children, child, i)

  // }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const updateUsername = (i, values) => {
    let children = [...values.children];
    let child = {};
    if (!children[i]) return;
    child = children[i];
    // if (!child.username)
    //   return
    child.username = getUsername(username, child, i);
    updateInitalValues(values, children, child, i);
  };

  const updateInitalValues = (values, children, child, i) => {
    children[i] = child;
    values.children = children;
    setInitalValues({ ...values });
  };

  const onTypeUsername = (i) => {
    let array = [...username];
    array[i] = true;
    sessionStorage.setItem("usernameTyped", JSON.stringify(array));
    setUsername(array);
  };
  const title =
    Children.length > 1
      ? t("about-your-child.title-plural")
      : t("about-your-child.title");
  const subtitle =
    Children.length > 1
      ? t("about-your-child.subtitle-plural")
      : t("about-your-child.subtitle");

  return (
    <>
      <AppHeader selected={index} />

      <div id={"aboutYourChildScreen"}>
        <Grid item xs={12} sm={9} md={8} lg={6} className="contentWrapper">
          <h4 className="screen-title">{title}</h4>
          <h5 className="screen-subtitle">{subtitle}</h5>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={submitValues}
          >
            {(props) => (
              <AboutYourChildForm
                {...props}
                childName={childName}
                validateField={validateField}
                updateUsername={updateUsername}
                // childArray={initialValues.children}
                // errorCode={errorCode}
                // fieldChange={fieldChange}
                // setDateOfBirth={setDateOfBirth}
                error={error}
                setUsername={onTypeUsername}
              />
            )}
          </Formik>
          <Loader />
        </Grid>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={10000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert severity="error">{alert}</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  Children: state.children,
  Kyc: state.kyc,
  addChildSuccess: state.addChildSuccess,
  Order: state.order,
  Steps: state.steps,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveChildren: (value) => dispatch({ type: "SET_CHILDREN", value }),
    saveStep: (value) => dispatch({ type: "SAVE_STEP", value }),
    addChild: (value) => dispatch({ type: "ADD_CHILD_SUCCESS", value }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AboutYourChild));
