import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import { DefaultButton } from '../../ui-components/button/index';
import { useTranslation } from 'react-i18next';
import { FormRowWithLabel, FormLabel, FormSpacer, ErrorMessage } from '../../ui-components/formRows/formRows';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// const useStyles = makeStyles(() => ({
//     root: {
//         '& > *': {
//         },
//     },
// }));

const ChallengeForm = (props) => {
    // const classes = useStyles();
    const { t } = useTranslation();


    const {
        values: {
            code,
            label,
            trusted

        },

        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        error,
        errorMessage,
        codeChange
    } = props;

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }

    return (

        <div className='padDiv'>
            <p>Your security code has been sent to your email.</p>

            <FormRowWithLabel>
                <FormLabel>{t('code')}</FormLabel>
                <TextField id="code"
                    name="code"
                    placeholder={t('code-placeholder')}
                    // helperText={touched.code ? errors.code : ""}
                    error={(touched.code && Boolean(errors.code)) || error}
                    value={code}
                    onKeyDown={keyPress}
                    onChange={(e) => { handleChange(e); codeChange() }}
                    variant="filled"
                    onBlur={handleBlur}
                />
                <ErrorMessage name="code" error={error} />

            </FormRowWithLabel>
            <FormSpacer />

            <p>If it's a new device, you can give it a name</p>

            <FormRowWithLabel>
                <FormLabel>{t('device-name')}</FormLabel>

                <TextField id="label"
                    name="label"
                    placeholder={t('device-name-placeholder')}
                    value={label}
                    onKeyDown={keyPress}
                    onChange={handleChange}
                    variant="filled"
                    onBlur={handleBlur}
                />

            </FormRowWithLabel>
            <FormRowWithLabel className='checkbox'>
                <FormLabel />
                < FormControlLabel id="trustedLabel"
                    control={< Checkbox checked={trusted} onChange={handleChange} name="trusted"
                        id="trusted"
                        error={touched.trusted && Boolean(errors.trusted)} />}
                    label={t('challenge.trusted')}
                    error={touched.trusted && Boolean(errors.trusted)}
                />
            </FormRowWithLabel>
            {errorMessage && <FormRowWithLabel>
                        <FormLabel></FormLabel>
                        <p className='errorMessage'>{errorMessage}</p>
                    </FormRowWithLabel>}
            <FormSpacer />
            <FormRowWithLabel className='next-button-container'>
                <FormLabel />
                <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
            </FormRowWithLabel>
        </div>

    );
}



export default ChallengeForm;
