import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import { postAPI } from "../../common/api";
import Loader from '../../ui-components/loader/loader'
import { DefaultButton, AuxButton } from '../../ui-components/button/index';
import Card from '@material-ui/core/Card';
import './index.css'
import { AppHeader } from '../layout/index'
import { FormRowWithLabel, FormLabel, FormSpacer, ConfirmValue, ConfirmLabel } from "../../ui-components/formRows/formRows";
import PolicyIcon from '@material-ui/icons/Policy';
import { trackConversion } from "../../common/trackers";
import { getDecryptedData, setAccountId } from '../../common/constants';

const useStyles = makeStyles(() => ({
    center: {
        margin: 'auto'
    },
    change: {
        marginTop: '15px',
        marginRight: '13px',
        fontWeight: 500,
        color: 'rgb(255, 131, 0)',
        float: 'right',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    policyIcon: {
        color: '#ccddff',
        width: '3em',
        height: '3em',
    }
}));

const OrderPreview = (props) => {
    const { t } = useTranslation();
    const { history, Kyc, Order, Children, saveKyc, saveChildren, clearSteps } = props
    const [kyc] = useState(Kyc);
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState(false);
    const [sameEmailORMobile, setSameEmailORMobile] = useState(false);
    // const { t } = useTranslation();
    const classes = useStyles()

    const backToEdit = () => {
        if (sameEmailORMobile)
            history.push('/v2/about-your-child')
        else
            history.push(Order === 'B' ? '/v2' : '/v2?sequence=A')
    }
    const dateFormat = new Intl.DateTimeFormat(
        "en-GB",
        {
            year: "numeric",
            month: "long",
            day: "2-digit"
        })

    const dateFormatter = (d, m, y) => {
        const date = new Date(y, m - 1, d)
        let formatedDate = ''
        try {
            formatedDate = dateFormat.format(date)
        } catch {
            formatedDate = ''
        }
        return formatedDate
    }
    useEffect(() => {
        if (Object.keys(Kyc).length === 0)
            history.push(Order === 'B' ? '/v2' : '/v2?sequence=A')
    }, [Kyc, Order, history])

    const handleSubmit = () => {
        let kyc = Kyc
        delete kyc.url
        let sameEmailORMobile = false
        let children = Children
        if (localStorage.getItem('orderSuccess')) {
            history.push('/v2/checkout')
            return
        }
        localStorage.removeItem('orderSuccess')
        children.map(child => {
            if (child.email === kyc.email || child.mobile === kyc.mobile || child.username === kyc.email) {
                sameEmailORMobile = true
                setError("Please use a different email/username/mobile number for parent and child. If we don’t have a email/mobile number for your child, we will use the Parent’s.")
            }
            // else if (child.mobile === kyc.mobile) {
            //     sameEmailORMobile = true
            //     setError("Please use a different mobile number for parent and child. If we don’t have a mobile number for your child, we will use the Parent’s number.")
            // }

            return child.date_of_birth = `${child.year}-${child.month}-${child.day}`
        })
        setSameEmailORMobile(sameEmailORMobile)
        if (sameEmailORMobile)
            return
        let dob = `${kyc.year}-${kyc.month}-${kyc.day}`
        kyc.date_of_birth = dob
        let body = {
            "email": kyc.email,
            "mobile": kyc.mobile,
            "password": kyc.password,
            "display_name": kyc.display_name,
            kyc: kyc,
            children, "preferences": {
                "gdpr": kyc.gdpr,
                "marketing": kyc.marketing ? kyc.marketing : false
            },
            source: kyc.source ? `ref-${kyc.source}` : 'website'
        }

        if (kyc.isAccount && kyc.isAboutYou) {
            delete body.kyc.isAccount
            delete body.kyc.isAboutYou
            delete body.kyc.marketing
            // delete body.kyc.email
            // delete body.kyc.mobile
            // delete body.kyc.password
            delete body.kyc.gdpr
            delete body.kyc.source
            setIsSent(true)
            if (!body.kyc.gender)
                delete body.kyc.gender

            postAPI('createorder', body).then((data) => {
                setIsSent(false)
                if (Object.values(data).includes("ALREADY_USED")) {
                    setError('Data entered already used')
                    return
                }
                if (data.error_id) {
                    setError(data.user_message)
                    return
                }
                trackConversion('CardOrderFormSubmitted', children.length)
                // const decryptedData = getDecryptedData() || {}
                // if (decryptedData && decryptedData.adult) {
                //     trackConversion('OsperforSquidCardOrderFormSubmitted', children.length)

                // }
                saveKyc({});
                saveChildren([])
                clearSteps()
                switch (data.next) {
                    case "checkout":
                        setAccountId(data.account_id)
                        history.push({
                            pathname: '/v2/checkout',
                            email: kyc.email
                        })
                        localStorage.setItem('orderSuccess', true)
                        break
                    case "fail_soft":
                        history.push({
                            pathname: '/v2/fail-soft',
                            email: kyc.email
                        })
                        localStorage.setItem('orderSuccess', true)
                        break
                    case "fail_hard":
                        history.push({
                            pathname: '/v2/fail-hard',
                            email: kyc.email
                        })
                        localStorage.setItem('orderSuccess', true)
                        break
                    default:
                        setError('Entered email/number is already used')
                        break
                }
            })
        } else {
            console.log(kyc.isAccount, kyc.isAboutYou, children.isChildren)
        }
    }

    return (
        <>
            <AppHeader />
            <div id={'orderPreviewScreen'}>
                <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
                    <h4 className="screen-title">{t('preview.title')}</h4>
                    <h5 className="screen-subtitle">{t('preview.subtitle')}</h5>
                    <Card className={classes.cardcustom}>
                        <div className='padDiv'>
                            <FormRowWithLabel>
                                <FormLabel><PolicyIcon className={classes.policyIcon} /></FormLabel>
                                <p className="confirm-info"><br />
                                    {t('preview.message1')}<br />
                                    {t('preview.message2')}
                                </p>
                            </FormRowWithLabel>
                            <FormSpacer />
                            <FormRowWithLabel>
                                <ConfirmLabel>{t('preview.name')}:</ConfirmLabel>
                                <ConfirmValue>{kyc.first_name} {kyc.last_name}</ConfirmValue>
                            </FormRowWithLabel>
                            <FormRowWithLabel>
                                <ConfirmLabel>{t('preview.address')}:</ConfirmLabel>
                                <ConfirmValue>{kyc.address1} <br /> {kyc.address2} {kyc.address2 && <br />} {kyc.city} <br /> {kyc.postcode}</ConfirmValue>
                                {/* <ConfirmValue></ConfirmValue>
                                <ConfirmValue>{kyc.city}</ConfirmValue>
                                <ConfirmValue>{kyc.postcode}</ConfirmValue> */}
                            </FormRowWithLabel>
                            <FormRowWithLabel>
                                <ConfirmLabel>{t('date-of-birth')}:</ConfirmLabel>
                                <ConfirmValue>{dateFormatter(kyc.day, kyc.month, kyc.year)}</ConfirmValue>
                            </FormRowWithLabel>
                            <FormSpacer />
                            <Grid container className='next-button-container'>
                                <FormRowWithLabel>
                                    <AuxButton onClick={backToEdit} name='Change' id='change-details' />
                                    <DefaultButton onClick={!isSent ? handleSubmit : null} name='Confirm' id='next-btn'></DefaultButton>
                                </FormRowWithLabel>
                            </Grid>
                            <p className='errorMessage'>{error}</p>
                            <Loader />
                        </div>
                    </Card>
                </Grid>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    Children: state.children,
    Kyc: state.kyc,
    Order: state.order

})

const mapDispatchToProps = dispatch => {
    return {
        saveKyc: (value) => dispatch({ type: "SET_KYC", value }),
        saveChildren: (value) => dispatch({ type: "SET_CHILDREN", value }),
        clearSteps: () => dispatch({ type: "CLEAR_STEP" }),

    }
};



export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderPreview));
