import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordForm from './resetPasswordForm';
// import { connect } from 'react-redux';

// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import {orderSteps} from '../../common/constants'
import { useTranslation } from 'react-i18next';
import { getAPI, postAPI } from "../../common/api";
import Loader from '../../ui-components/loader/loader'
import { AppHeader } from '../layout/index'
import { Trans, withTranslation } from 'react-i18next';

const ResetPassword = (props) => {
    const { t } = useTranslation();

    const [initialValues] = useState({ password: '' })
    const [error, setError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [success, setSuccess] = useState(false)
    // const currentYear = parseInt(new Date().getFullYear())
    // const [passwordSuggestion, setPasswordSuggestion] = useState('')
    const [expired, setExpired] = useState(false)
    const { token } = props.match.params || {};

    const validationSchema = Yup.object({
        day: Yup.number().nullable()
            // .required(`${t('field-rqd')} ${t('day')}`)
            .max(31, t('day-validation'))
            .min(1, t('day-validation')),
        month: Yup.number().nullable()
            // .required(`${t('field-rqd')} ${t('month')}`)
            .max(12, t('month-validation'))
            .min(1, t('month-validation')),
        year: Yup.number().nullable(),
        // .required(`${t('field-rqd')} ${t('year')}`),
        // .min(currentYear - 100, t('parent-age-validation'))
        // .max(currentYear - 18, t('parent-age-validation')),
        password: Yup.string()
            .required(`${t('field-rqd')} ${t('password-placeholder')}`)
            // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^(~_+){.,/`+-}])[A-Za-z\d@$!%*?&#^(~_+){.,/`+-}]{8,}$/,
            //     t('password-error')
            .min(8, t('8-character-rqrd')),
    });



    const submitValues = async (values) => {
        setError('')
        let response = await Promise.all([
            checkPassword({
                password: values.password,
                user_inputs: [values.day]
            })
        ]);

        if (response.filter(data => !data).length > 0)
            return
        let body = { password: values.password, dob: `${values.year}-${values.month}-${values.day}` }


        postAPI(`help/forgot-password/${token}`, body).then((data) => {
            if (data.data === 'Success') {
                setSuccess(true)
                return
            }

            if (data.error_id) {
                setError(data.user_message)

                return
            }


        })

    }

    const checkPassword = async (body) => {
        setPasswordError('')
        setError('')
        if (/\s/g.test(body.password)) {
            setPasswordError(t('whitespace-error'))
            return false
        }
        let response = await postAPI(`password_strength`, body).then(data => {
            if (data.error_id) {
                setPasswordError(data.user_message ? data.user_message : t('please-try-again'))
                return false
            }
            // if (data.feedback) {
            //     let suggestion = data.feedback.suggestions.toString();
            //     suggestion = suggestion.concat(data.feedback.warning)
            //     suggestion = fixBackendTypo(suggestion)
            setPasswordError(data.feedback)
            // }
            if (data.score < 3) {
                return false

            }

            return true
        })
        return response
    }


    useEffect(() => {
        getAPI(`help/verification/${token}`).then(data => {
            if (data.error_id && data.error_id !== 'NO_CONTENT') {
                setExpired(true)


            }
        })

    }, [token])


    return (
        <>
            <AppHeader />
            <div id={'resetPasswordScreen'}>
                <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
                    {expired ? <>

                        <h4 className="screen-title">{t('reset-password.expired-title')}</h4>

                        <p className='subHead'>{t('reset-password.expired-subtitle')}</p>

                        <p className='subHead'>{t('reset-password.contactus')}<a href="tel:+442033229090" target="_blank" title="+442033229090" rel="noopener noreferrer">020 3322 9090</a> or at <a class="color-inherit underline" href="mailto:hello@osper.com">hello@osper.com</a>.</p>
                    </> :
                        <>
                            <h4 className="screen-title">{t('reset-password.title')}</h4>
                            <h5 className="screen-subtitle">{t('reset-password.subtitle')}</h5>
                            {success ? <p className='subHead'><Trans i18nKey="mail-sent">
                                {t('reset-password.success')}
                            </Trans></p> :
                                <Formik
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                    initialValues={initialValues}
                                    onSubmit={submitValues}>{
                                        (props) =>
                                            <ForgotPasswordForm {...props} error={error} passwordError={passwordError}
                                                // setPasswordSuggestion={() => setPasswordSuggestion('')}
                                                // passwordSuggestion={passwordSuggestion}
                                                checkPassword={checkPassword}
                                                fieldChange={() => { setPasswordError(''); setError('') }}
                                            />
                                    }
                                </Formik>}</>}
                    <Loader />
                </Grid>
            </div>
        </>
    );
}



export default withTranslation()(ResetPassword);
