import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { DefaultButton } from '../../ui-components/button/index';

const useStyles = makeStyles(() => ({
    center: {
        margin: 'auto',
        width: '100%',
        fontSize: '18px',
        fontWeight: 500,
        textAlign: 'center',
        padding: '10px 0px 10px'
    },
}));


const EmailConfirm = (props) => {

    const { t } = useTranslation();
    const classes = useStyles()

    const handleOk = () => {
        props.history.push('/v2/dashboard')
    }


    return (

        <div>
            <div className="module-content heading">
                <div className="container">

                    <h4><span className="icon-tick" style={{ fontSize: '20px', marginRight: '5px' }}></span>Got it!</h4>
                </div>
            </div>
            <Grid item xs={12} sm={10} md={8} lg={6} className={classes.center}>
                <Card className='customCard'>

                    <div className='padDiv'>

                        <Grid item xs={12} sm={10} md={10} lg={10} className={classes.center}>

                            {/* <h4 style={{ lineHeight: "1.4" }}>
                                            {t('email-verified')}
                                        </h4> */}

                            <h3 >
                                {t('email-verified')}
                            </h3>
                        </Grid>
                        <Grid container className='next-button-container'>
                            <Grid item xs={12} sm={4} md={3} lg={3} className={classes.center}>
                                <DefaultButton onClick={handleOk} name='Ok' btnType="button" id='next-btn'></DefaultButton>
                            </Grid>
                        </Grid>

                    </div>
                </Card>
            </Grid>
        </div>)

}



export default (withTranslation()(EmailConfirm));

