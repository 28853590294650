import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { withTranslation, useTranslation } from 'react-i18next';
import withWidth from '@material-ui/core/withWidth';
import Loader from '../../ui-components/loader/loader'
import { DefaultButton } from '../../ui-components/button/index';
import { Gallery, CardPreview } from "../cardDesign2/gallery";
import '../cardDesign2/cardDesign.css'
import { connect } from "react-redux";
import { FormRowWithLabel, FormSpacer, FormLabel } from '../../ui-components/formRows/formRows';
import { AppHeader } from '../layout/index'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TermsChecklist from '../account/termsChecklist'

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        color: '#fff',
    },
    titleBar: {
        background: 'rgba(0, 0, 0, 0.5)'
    },
    contentWrapper: {
        maxWidth: '650px',
        width: '100%',
        margin: 'auto'
    },
}));



const CardDesign = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const UnknownCard = {
        card_design_id: "0",
        description: "",
        label: "Unknown Card",
        price: "included",
        groupname: "osper",
        url: ""
    }
    const [designGroups, setDesignGroups] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const { loadDesigns, designs, addSteps } = props
    const cardDesignsList = designGroups.reduce((groupsAcc, group) => {
        const list = groupsAcc.concat(group.designs.map(design => ({ ...design, groupname: group.groupname })))
        /* temp solution until API returns correct URLs */
        // list.forEach(d => d.url = d.url ? d.url.replace('.s3.eu-west-1.amazonaws.com', '') : '')
        list.forEach(d => d.url = d?.url?.replace(/(\.jpg)?$/, '.jpg'))
        return list;
    }, [])
    const [galleryPosition, setGalleryPosition] = useState(true)
    const { childName, card_id, cancel_old } = props.history.location.state || {}
    const tos_accepted = sessionStorage.getItem('tos_accepted') === 'true'
    const [card_design_id, setDesign] = useState('')
    const [tc, setTc] = useState(false)
    const screens = [
        { id: "card-design" },
        { id: "checkout" }

    ]

    useEffect(
        () => {
            addSteps(screens.map(s => s.id))
        }, [screens, addSteps]
    )


    const ChildCardModule = () => {
        const onSelect = (design_id) => selectDesign(design_id)
        const toggleGallery = () => setGalleryPosition(true)
        return <>
            <Grid container className='childCardModule'>
                <Grid item xs={12} sm={3} md={3} lg={3} className='description'>
                    <h3>{childName}'s new card:</h3>
                    {/* <span className="cardButton" onClick={onRemove}>remove</span> */}
                    {card_design_id === UnknownCard.card_design_id ? null :
                        <><span></span><span className="cardButton" onClick={toggleGallery}>{t('change')}</span></>}
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                    <CardPreview design={getDesign(card_design_id)}
                        disabled={cardDesignsList.length < 1}
                        onClick={toggleGallery}
                    />
                </Grid>
            </Grid>
            {cardDesignsList.length < 1 ?
                <div>Loading...</div> :
                <Gallery onSelect={onSelect}
                    isVisible={galleryPosition}
                    replacement={true}
                    initItems={cardDesignsList} />
            }
        </>
    }

    useEffect(() => {
        setDesignGroups(designs)
    }, [designs])
    useEffect(() => {
        if (designs.length <= 0)
            loadDesigns()
    }, [loadDesigns, designs])

    const selectDesign = (card_design_id) => {
        setErrorMessage('')
        setGalleryPosition(false)
        setDesign(card_design_id)
    }
    const getDesign = (id) => {
        const idNum = parseInt(id)
        if (!designGroups || !designGroups.length) return UnknownCard;
        const d = cardDesignsList.find(d => (d.card_design_id === idNum))
        return d || UnknownCard
    }
    const handleSubmit = () => {
        if (card_design_id === '') {
            setErrorMessage(t('choose-design'))
            return
        }
        if (!tc && !tos_accepted) {
            setErrorMessage(t('terms-and-conditions'))
            return

        }
        props.saveStep({ completed: true })
        let body = {}
        body.card_design_id = card_design_id
        props.history.push({
            pathname: '/v2/checkout',
            state: { card_design_id: card_design_id, card_id: card_id, cancel_old: cancel_old }
        })
    }

    const TermsAndConditions = () => {
        return (<>{!tos_accepted && <>
            <FormRowWithLabel>
                <div />
                <span className={classes.termsChanged}>{t('terms-changed')}</span>
            </FormRowWithLabel>
            <FormRowWithLabel className='checkbox'>
                <FormLabel />
                < FormControlLabel className={classes.checkbox}
                    id="gdprLabel"
                    control={< Checkbox checked={tc}
                        onChange={(e) => setTc(e.target.checked)}
                        name="gdpr"
                        id="gdpr"
                    />
                    }
                    label={t('account.dataProcessingConsent')}
                />
                <TermsChecklist />
            </FormRowWithLabel>
        </>}</>)
    }

    return (
        <>
            <AppHeader selected={0} />

            <div id={'cardDesignScreen'} className="gallery2">
                <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
                    <h4 className="screen-title">{t('card-design.title')}</h4>
                    <h5 className="screen-subtitle">{t('card-design.subtitle')}</h5>
                    <Card className={classes.cardcustom}>
                        <div className='padDiv'>
                            <ChildCardModule />
                            <TermsAndConditions />
                            <FormSpacer />
                            <FormRowWithLabel><div />
                                <p className='errorMessage'>{errorMessage}</p>
                            </FormRowWithLabel>
                            <FormRowWithLabel>
                                <FormLabel />
                                <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                            </FormRowWithLabel>
                            <FormSpacer />
                            <FormSpacer />
                        </div>
                    </Card>
                    <Loader />
                </Grid>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        designs: state.designs,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadDesigns: () => dispatch({ type: "GET_DESIGNS" }),
        addSteps: (steps) => dispatch({ type: "ADD_STEPS", value: steps }),
        saveStep: (value) => dispatch({ type: "SAVE_STEP", value }),



    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withTranslation()(CardDesign)));


