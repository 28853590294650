import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
// const { t } = this.props;
import { AppHeader } from '../layout/index'

const useStyles = makeStyles(() => ({

    container: {
        marginTop: '60px',
        fontWeight: 500,
        textAlign: 'center',
        '& h2': {
            fontSize: '40px'
        },
        '& h3': {
            fontSize: '30px'
        },
        '& p': {
            fontSize: '20px'
        }
    }
}));


const Error = (props) => {

    const { t } = useTranslation();
    const classes = useStyles()
    let code = props.match.params.code

    return (
        <>
            <AppHeader />

            <div className={classes.container}>
                <span className="icon-warning" style={{ fontSize: '50px' }}></span>
                <br />
                <br />
                <h3>{code}</h3>
                <br />
                <h2>{t('errorScreen.server_error')}</h2>
                <br />
                {/* <p>Try heading back to the Osper <a href="/">homepage</a> or email us at <a href="mailto:help@osper.com">help@osper.com</a>.</p> */}
                <br />
            </div>
        </>
    )

}


export default (withTranslation()(Error));

