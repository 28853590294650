import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import OrderForm from "./orderForm";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { getAPI, postAPI } from "../../common/api";
import { withTranslation, useTranslation } from "react-i18next";
import Loader from "../../ui-components/loader/loader";
import { getDecryptedData, getUsername } from "../../common/constants";
// import { validationSchema } from "./utils";
import * as Yup from "yup";
import { CHILD_MAX_YEAR, CHILD_MIN_YEAR } from "../../common/constants";

const OrderFormIndex = (props) => {
  const { t } = useTranslation();
  const { childName, history, saveChildren, Kyc, Children, saveKyc } = props;

  const [errorCode, setErrorCode] = useState(0);

  const [username, setUsername] = useState(
    JSON.parse(sessionStorage.getItem("usernameTyped")) || []
  );
  const [initialValues, setInitalValues] = useState({
    children: [],
    first_name: "",
    last_name: "",
    email: "",
    confirmEmail: "",
    password: "",
    source: window.referralCode || "",
    marketing: false,
    gdpr: false,
    postcode: "",
    address1: "",
    address2: "",
    city: "",
    mobile: "",
    day: "",
    month: "",
    year: "",
    gender: "",
    display_name: "",
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordSuggestion, setPasswordSuggestion] = useState("");
  const [address, setAddress] = useState([]);
  const [ageError, setAgeError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [postCodeError, setPostCodeError] = useState("");
  const [index, setIndex] = useState("");
  const currentYear = parseInt(new Date().getFullYear());

  const validationSchema = Yup.object().shape({
    children: Yup.array()
      .of(
        Yup.object().shape({
          first_name: Yup.string()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t(
                "first-name-placeholder"
              )}`
            )
            .min(2, t("2-character-rqrd"))
            .max(12, t("first-name-max"))
            .matches(
              /^[a-zA-Z .'-]{2,30}$/,
              `${t("latin-letter-exclusion-error")} ${t(
                "child's-placeholder"
              )} ${t("first-name-placeholder")}`
            ),
          last_name: Yup.string()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t(
                "last-name-placeholder"
              )}`
            )
            .min(2, t("2-character-rqrd"))
            .matches(
              /^[a-zA-Z .'-]{2,30}$/,
              `${t("latin-letter-exclusion-error")} ${t(
                "child's-placeholder"
              )} ${t("last-name-placeholder")}`
            ),
          gender: Yup.string().required(t("gender-validation-child")),
          day: Yup.number()
            .nullable()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t("day")}`
            )
            .min(1, t("day-validation"))
            .max(31, t("day-validation")),
          month: Yup.number()
            .nullable()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t("month")}`
            )
            .min(1, t("month-validation"))
            .max(12, t("month-validation")),
          year: Yup.number()
            .nullable()
            .required(
              `${t("field-rqd")} ${t("child's-placeholder")} ${t("year")}`
            )
            .min(CHILD_MIN_YEAR, t("child-age-validation-max"))
            .max(CHILD_MAX_YEAR, t("child-age-validation")),
          username: Yup.string()
            .min(2, t("2-character-rqrd"))
            .required(t("user-name-error")),
          password: Yup.string()
            .min(8, t("password-validation-child"))
            .required(t("password-error-child")),
          email: Yup.string().email(t("email-validation")),
          mobile: Yup.string().matches(
            /^07[0-9]{9}$/,
            t("mobile-number-validation")
          ),
        })
      )
      .required("Must have children") // these constraints are shown if and only if inner constraints are satisfied
      .min(1, "Minimum of 1 child"),
    first_name: Yup.string()
      .required(`${t("field-rqd")} ${t("first-name-placeholder")}`)
      .min(2, t("2-character-rqrd"))
      .matches(
        /^[a-zA-Z .'-]{2,30}$/,
        `${t("latin-letter-exclusion-error")} ${t("first-name-placeholder")}`
      ),
    last_name: Yup.string()
      .required(`${t("field-rqd")} ${t("last-name-placeholder")}`)
      .min(2, t("2-character-rqrd"))
      .matches(
        /^[a-zA-Z .'-]{2,30}$/,
        `${t("latin-letter-exclusion-error")} ${t("last-name-placeholder")}`
      ),
    email: Yup.string()
      .required(`${t("field-rqd")} ${t("email-placeholder")}`)
      .email(t("email-validation")),
    confirmEmail: Yup.string()
      .required(t("confirm-email-error"))
      .test("email-match", t("confirm-email-match"), function (value) {
        return this.parent.email === value;
      }),
    password: Yup.string()
      .required(`${t("field-rqd")} ${t("password-placeholder")}`)
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^(~_+){.,/`+-}])[A-Za-z\d@$!%*?&#^(~_+){.,/`+-}]{8,}$/,
      //     t('password-error')
      .min(8, t("8-character-rqrd")),
    gdpr: Yup.bool().oneOf([true], t("terms-and-conditions")),
    postcode: Yup.string()
      .min(5, t("post-code-short"))
      .max(8, t("post-code-long"))
      .required(`${t("field-rqd")} ${t("post-code-text")}`),
    address1: Yup.string()
      .min(3, t("address-min"))
      .max(120, t("address-max"))
      .required(t("address1-error")),
    address2: Yup.string().min(3, t("address-min")).max(120, t("address-max")),
    city: Yup.string()
      .min(3, t("city-min"))
      .max(120, t("city-max"))
      .matches(
        /^[a-zA-Z0-9 .'-]+$/,
        `${t("latin-letter-exclusion-error")} ${t("city")}`
      )
      .required(`${t("field-rqd")} ${t("city-placeholder")}`),
    mobile: Yup.string()
      .required(`${t("field-rqd")} ${t("mobile-number-placeholder")}`)
      .matches(/^07[0-9]{9}$/, t("mobile-number-validation")),
    display_name: Yup.string()
      .min(2, t("2-character-rqrd"))
      .max(12, t("display-name-validation"))
      .matches(
        /^[a-zA-Z .'-]{2,12}$/,
        `${t("letter-exclusion-error")} ${t("display-name")}`
      )
      .required(`${t("field-rqd")} ${t("display-name")}`),
    day: Yup.number()
      .nullable()
      .required(`${t("field-rqd")} ${t("day")}`)
      .max(31, t("day-validation"))
      .min(1, t("day-validation")),
    month: Yup.number()
      .nullable()
      .required(`${t("field-rqd")} ${t("month")}`)
      .max(12, t("month-validation"))
      .min(1, t("month-validation")),
    year: Yup.number()
      .nullable()
      .required(`${t("field-rqd")} ${t("year")}`)
      .min(currentYear - 100, t("parent-age-validation"))
      .max(currentYear - 18, t("parent-age-validation")),
    // gender: Yup.string().required(t('gender-validation')),
  });

  useEffect(() => {
    const decryptedData = getDecryptedData() || {};

    if (
      Object.values(Kyc).every((x) => x === null || x === "" || x === false) &&
      decryptedData &&
      decryptedData.adult
    ) {
      const decryptedChildrenData = decryptedData.children
        .slice(0, 3)
        .map((child) => ({
          ...child,
          first_name: child.first_name || "",
          last_name: child.last_name || "",
          ...(child.birth_date
            ? child.birth_date
            : { day: null, month: null, year: null }),
          gender: child.gender || "DONTSAY",
          above_minimum_age: true,
          tier_id: 14,
          username: child.email || getUsername([], child, 0),
          password: "",
          email: child.email || "",
          mobile: child.mobile || "",
        }));
      setInitalValues({
        children: Children.length <= 0 ? decryptedChildrenData : Children,
        ...decryptedData.adult,
        ...decryptedData.adult.birth_date,
        confirmEmail: decryptedData.adult.email,
        password: "",
        gdpr: false,
        display_name: "",
      });
    } else
      setInitalValues({
        children: Children,
        ...Kyc,
        source: window.referralCode || Kyc.source,
      });
  }, [Children, Kyc]);

  const finalValidation = async (child, i, values) => {
    let response = await Promise.all([
      validateField(child.first_name, "name", i, values),
      validateField(child.mobile, "childnumber", i, values),
      validateField(
        `${child.day}-${child.month}-${child.year}`,
        "childage",
        i,
        values
      ),
      validateField(child.email, "email", i, values),
      validateField(child.username, "username", i, values),
      validateField(child.password, "password", i, values),
    ]);
    return response;
  };

  const submitValues = async (values) => {
    setError("");
    if (errorCode !== 0 || errorMessage || passwordSuggestion) return;
    saveChildren(values.children);
    const { children, ...rest } = values;
    saveKyc(rest);

    if (
      values.children.filter(
        (child) => child.first_name && !child.card_design_id
      ).length >= 1
    ) {
      setError("Please choose a design for the child");
      return;
    }

    setError("");

    let response = await Promise.all([
      validateEmail(values.email),
      checkPassword({
        password: values.password,
        user_inputs: [values.first_name, values.last_name, values.email],
      }),
      values.children.map((item, i) => finalValidation(item, i, values)),
      validatePostCode(values.postcode),
      validateField(values.mobile, "number"),
      validateField(`${values.day}-${values.month}-${values.year}`, "age"),
    ]);

    if (response.filter((data) => !data).length > 0) return;

    for (let i = 0; i < response.length; i++) {
      for (let j = 0; j < response[i].length; j++) {
        if (response[i][j] === false)
          setError("There was an error with the request you sent");
        if (response[i][j] === false || response[i][j] === "error") return;
      }
    }

    rest.isAccount = true;
    rest.isAboutYou = true;

    saveKyc(rest);

    history.push("/v2/order-preview");
  };

  const validateField = async (value, type, i, values) => {
    let children = values ? [...values.children] : [];
    let child = {};
    if (values && !children[i]) return false;
    child = children[i];
    let status = false;
    let resp;
    if (type === "name") {
      if (
        value.length < 2 ||
        !/^[a-zA-Z .'-]{2,30}$/.test(value) ||
        value.length > 12
      ) {
        return false;
      }

      resp = await getAPI(
        `name/${Kyc.first_name ? Kyc.first_name : "null"}/${value}`
      ).then((data) => {
        switch (data.error_id) {
          case "INVALID_PARAM_VALUE":
            child.nameError = t("child-name-validation");
            setErrorCode("nameError");
            status = "error";
            break;
          case "ERROR":
            child.nameError = t("please-try-again");
            setErrorCode(400);
            status = "error";
            break;
          case "NO_CONTENT":
            child.username = getUsername(username, child, i);
            delete child.nameError;
            setErrorCode(0);
            status = true;
            break;
          default:
            break;
        }
        updateInitalValues(values, children, child, i);
        return status;
      });
    } else if (type === "childnumber") {
      delete child.numberError;

      const regexp = /^07[0-9]{9}$/;
      if (!regexp.test(value)) return;
      if (value === Kyc.mobile) {
        child.numberError = t("same-number-parent-child-error");
        setErrorCode(400);
        updateInitalValues(values, children, child, i);
        return "error";
      }
      const childArray = children.filter(
        (child, index) => child.mobile === value && index !== i
      );
      if (childArray.length > 0) {
        child.numberError = `${child.first_name} ${t(
          "same-email-siblings-error1"
        )} number
          ${t("same-email-siblings-error2")} ${childArray[0].first_name}. ${t(
          "same-email-siblings-error3"
        )} ${child.first_name}
          ${t("same-email-siblings-error4")} number ${t(
          "same-email-siblings-error5"
        )}.`;
        setErrorCode(400);
        updateInitalValues(values, children, child, i);
        return "error";
      }
      resp = await getAPI(`mobile/${value}/?orders=true&accounts=true`).then(
        (data) => {
          switch (data.error_id) {
            case "NOT_FOUND":
              delete child.numberError;
              setErrorCode(0);
              status = true;
              break;
            case 400:
              child.numberError =
                t("mobile-number-api-failed") + t("mobile-number-validation");
              setErrorCode(400);
              status = "error";
              break;
            case "NO_CONTENT":
              child.numberError = [
                t("mobile-number-api-exist"),
                <a
                  href="/contact"
                  target="_blank"
                  style={{ color: "#fff" }}
                  rel="noreferrer"
                >
                  {t("contact-us")}
                </a>,
                ".",
              ];
              setErrorCode("numberError");
              status = "error";
              break;
            default:
              status = false;
              break;
          }
          updateInitalValues(values, children, child, i);
          return status;
        }
      );
    } else if (type === "childage") {
      if (!value) return;
      resp = await getAPI(`child_age/${value}`).then((data) => {
        switch (data.error_id) {
          case "InvalidAgeError":
            child.ageError = data.user_message;
            setErrorCode("ageError");
            status = "error";
            break;
          case "ERROR":
            child.ageError = t("too-many-request");
            setErrorCode(400);
            status = "error";
            break;
          case "NO_CONTENT":
            // child.username = !username[i] ? child.email || `${child.first_name.replace(/ /g, "")}${child.last_name.replace(/ /g, "")}${Math.floor(Math.random() * (999 - 100 + 1) + 100)}` : child.username;
            delete child.ageError;
            setErrorCode(0);
            status = true;
            break;
          default:
            status = false;
            break;
        }

        updateInitalValues(values, children, child, i);
        return status;
      });
    } else if (type === "email") {
      delete child.emailError;

      const regexp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
      if (!regexp.test(value)) return;
      if (value === Kyc.email) {
        setErrorCode(400);
        child.emailError = t("same-email-parent-child-error");
        updateInitalValues(values, children, child, i);
        return "error";
      }
      const childArray = children.filter(
        (child, index) =>
          (child.email === value || child.username === value) && index !== i
      );
      if (childArray.length > 0) {
        child.emailError = `${child.first_name} ${t(
          "same-email-siblings-error1"
        )} email/username
          ${t("same-email-siblings-error2")} ${childArray[0].first_name}. ${t(
          "same-email-siblings-error3"
        )}  ${child.first_name}
          ${t("same-email-siblings-error4")} email ${t(
          "same-email-siblings-error5"
        )}.`;
        setErrorCode(400);
        updateInitalValues(values, children, child, i);
        return "error";
      }
      resp = await getAPI(`email/${value}/?orders=true&accounts=true`).then(
        (data) => {
          switch (data.error_id) {
            case "NOT_FOUND":
              child.username = !username[i] ? value : child.username;
              delete child.emailError;
              setErrorCode(0);
              status = true;
              break;
            case 400:
              child.emailError = t("email-api-failed");
              setErrorCode(400);
              status = "error";
              break;
            case "NO_CONTENT":
              child.emailError = [
                t("email-api-exist"),
                <a
                  href="/contact"
                  target="_blank"
                  style={{ color: "#f44336" }}
                  rel="noreferrer"
                >
                  {t("contact-us")}
                </a>,
                ".",
              ];
              setErrorCode("emailError");
              status = "error";
              break;
            default:
              status = false;
              break;
          }

          updateInitalValues(values, children, child, i);
          return status;
        }
      );

      /* 404: true # Email not currently in use
                               400: false # Request malformed
                               204: false # Email already in use */
    } else if (type === "username") {
      delete child.usernameError;
      if (!value || value.length <= 1) return false;
      if (value === Kyc.email) {
        setErrorCode(400);
        child.usernameError = t("same-username-parent-child-error");
        updateInitalValues(values, children, child, i);
        return "error";
      }
      const childArray = children.filter(
        (child, index) =>
          (child.username === value || child.email === value) && index !== i
      );
      if (childArray.length > 0) {
        child.usernameError = `${child.first_name} ${t(
          "same-username-siblings-error1"
        )} ${childArray[0].first_name}.${t("same-username-siblings-error2")} ${
          child.first_name
        }.`;
        setErrorCode(400);
        updateInitalValues(values, children, child, i);
        return "error";
      }
      resp = await getAPI(`username/${value}`).then((data) => {
        switch (data.error_id) {
          case "NOT_FOUND":
            delete child.usernameError;
            setErrorCode(0);
            status = true;
            break;
          case "ERROR":
            child.usernameError = t("please-try-again");
            setErrorCode(400);
            status = "error";
            break;
          case "NO_CONTENT":
            child.usernameError = "username is already taken.";
            setErrorCode("usernameError");
            status = "error";
            break;
          default:
            status = false;
            break;
        }

        updateInitalValues(values, children, child, i);
        return status;
      });
    } else if (type === "password") {
      delete child.passwordError;
      if (!value || value.length < 8) return false;
      if (/\s/g.test(value)) {
        child.passwordError = t("whitespace-error");
        return "error";
      }
      let body = {
        password: value,
        user_inputs: [
          child.first_name,
          child.last_name,
          child.email,
          child.username,
        ],
      };
      resp = await postAPI("password_strength", body).then((data) => {
        if (data.error_id) {
          child.passwordError = data.user_message
            ? data.user_message
            : t("please-try-again");
          setErrorCode(400);
          updateInitalValues(values, children, child, i);
          return "error";
        } else {
          setErrorCode(0);
          delete child.passwordError;
        }
        child.passwordWarning = data.feedback;

        if (data.score < 3) status = true;
        else {
          delete child.passwordWarning;

          status = true;
        }

        updateInitalValues(values, children, child, i);
        return status;
      });
    } else if (type === "number") {
      const regexp = /^07[0-9]{9}$/;
      if (regexp.test(value))
        resp = await getAPI(`mobile/${value}/?orders=true&accounts=true`).then(
          handleMobileNumberValidation
        );
    } else if (type === "age") {
      if (value !== "") {
        resp = await getAPI(`age/${value}`).then(handleDateOfBirthValidation);
      }
    }

    return resp;
  };

  const checkPassword = async (body) => {
    if (/\s/g.test(body.password)) {
      setPasswordSuggestion(t("whitespace-error"));
      return false;
    }
    let response = await postAPI(`password_strength`, body).then((data) => {
      if (data.error_id) {
        setPasswordSuggestion(
          data.user_message ? data.user_message : t("please-try-again")
        );
        return false;
      }

      if (data.score < 3) {
        setPasswordSuggestion(data.feedback);
        return false;
      }
      setPasswordSuggestion("");

      return true;
    });
    return response;
  };

  const validateEmail = async (email) => {
    let response;
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    if (regexp.test(email))
      response = await getAPI(`email/${email}/?orders=true&accounts=true`).then(
        (data) => {
          switch (data.error_id) {
            case "NOT_FOUND":
              setErrorMessage("");
              return true;
            case 400:
              setErrorMessage(t("email-api-failed"));
              return false;
            case "INVALID_PARAM_VALUE":
              setErrorMessage(data.user_message);
              return false;
            case "NO_CONTENT":
              setErrorMessage([
                t("email-api-exist"),
                <a
                  href="/contact"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#f44336" }}
                  key={"1"}
                >
                  {t("contact-us")}
                </a>,
                ".",
              ]);
              return false;
            default:
              return false;
          }
          /* 404: true # Email not currently in use
                                    400: false # Request malformed
                                    204: false # Email already in use */
        }
      );
    return response;
  };

  const getAddressesForPostCode = (postCode) => {
    getAPI(`postcodes/${postCode}`).then((data) => {
      handleGetAddressesResponse(data);
      setAddress(data.addresses);
    });
  };

  const validatePostCode = async (value) => {
    let response = await getAPI(`postcode/${value}`).then(
      handlePostCodeValidation
    );
    return response;
  };

  const updateUsername = (i, values) => {
    let children = [...values.children];
    let child = {};
    if (!children[i]) return;
    child = children[i];
    // if (!child.username)
    //   return
    child.username = getUsername(username, child, i);
    updateInitalValues(values, children, child, i);
  };

  const updateInitalValues = (values, children, child, i) => {
    children[i] = child;
    values.children = children;
    setInitalValues({ ...values });
  };

  const onTypeUsername = (i) => {
    let array = [...username];
    array[i] = true;
    sessionStorage.setItem("usernameTyped", JSON.stringify(array));
    setUsername(array);
  };

  const handleGetAddressesResponse = (data) => {
    switch (data.error_id) {
      case "InvalidPostCode":
        setErrorCode(400);
        setPostCodeError(data.user_message);
        break;
      default:
        break;
    }
  };

  const handlePostCodeValidation = (data) => {
    switch (data.error_id) {
      case "INVALID_PARAM_VALUE":
        setErrorCode(404);
        setPostCodeError(t("post-code-invalid"));
        return false;
      case "ERROR":
        setErrorCode(400);
        setPostCodeError(t("post-code-api-failed"));
        return false;
      case "NO_CONTENT":
        setErrorCode(0);
        setPostCodeError("");
        return true;
      default:
        return false;
    }
  };

  const handleMobileNumberValidation = (data) => {
    switch (data.error_id) {
      case "NOT_FOUND":
        setErrorCode(0);
        setNumberError("");
        return true;
      case 400:
        setErrorCode(400);
        setNumberError(t("mobile-number-api-failed"));
        return false;
      case "NO_CONTENT":
        setErrorCode(204);
        setNumberError([
          t("mobile-number-api-exist"),
          <a
            href="/contact"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#fff" }}
          >
            {t("contact-us")}
          </a>,
          ".",
        ]);
        return false;
      default:
        return false;
    }
  };

  const handleDateOfBirthValidation = (data) => {
    switch (data.error_id) {
      case "InvalidAgeError":
        setErrorCode(404);
        setAgeError(t("parent-age-validation"));
        return false;
      case "ERROR":
        setErrorCode(400);
        setAgeError(t("please-try-again"));
        return false;
      case "NO_CONTENT":
        setErrorCode(0);
        setAgeError("");
        return true;
      default:
        return false;
    }
  };
  const selectAddress = (address, postCode, values) => {
    setAddress([]);
    let newAddress = {
      postcode: postCode,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
    };
    setInitalValues({ ...values, ...newAddress });
  };

  return (
    <div id={"aboutYourChildScreen"}>
      <Grid item xs={12} sm={9} md={8} lg={6} className="contentWrapper">
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={submitValues}
        >
          {(props) => (
            <OrderForm
              {...props}
              childName={childName}
              validateField={validateField}
              updateUsername={updateUsername}
              error={error}
              setUsername={onTypeUsername}
              validateEmail={validateEmail}
              emailChange={() => {
                setErrorMessage("");
              }}
              passwordSuggestion={passwordSuggestion}
              checkPassword={checkPassword}
              change={(e) => console.log(error)}
              // setError={(error) => setError(error)}
              setPasswordSuggestion={() => setPasswordSuggestion("")}
              history={history}
              emailError={errorMessage}
              getAddressesForPostCode={getAddressesForPostCode}
              addresses={address}
              selectAddress={selectAddress}
              fieldChange={(type) => {
                setErrorCode(0);
                type === "number"
                  ? setNumberError("")
                  : type === "age"
                  ? setAgeError("")
                  : setPostCodeError("");
              }}
              numberError={numberError}
              postCodeError={postCodeError}
              ageError={ageError}
            />
          )}
        </Formik>
        {/* <Snackbar
          open={alertOpen}
          autoHideDuration={10000}
          onClose={() => setAlertOpen(false)}
        >
          <Alert severity="error">{alert}</Alert>
        </Snackbar> */}
        <Loader />
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  Children: state.children,
  Kyc: state.kyc,
  addChildSuccess: state.addChildSuccess,
  Order: state.order,
  Steps: state.steps,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveChildren: (value) => dispatch({ type: "SET_CHILDREN", value }),
    saveStep: (value) => dispatch({ type: "SAVE_STEP", value }),
    addChild: (value) => dispatch({ type: "ADD_CHILD_SUCCESS", value }),
    saveKyc: (value) => dispatch({ type: "SET_KYC", value }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OrderFormIndex));
