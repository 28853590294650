import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ResetUsernameForm from './resetUsernameForm';
// import { connect } from 'react-redux';

// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import {orderSteps} from '../../common/constants'
import { useTranslation } from 'react-i18next';
import { postAPI } from "../../common/api";
import Loader from '../../ui-components/loader/loader'
import { AppHeader } from '../layout/index'
import { getEmail } from '../../common/constants'
import { Trans, withTranslation } from 'react-i18next';

const ResetUsername = (props) => {
  const { t } = useTranslation();

  const [initialValues] = useState({ email: '' })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState(false)

  const validationSchema = Yup.object({
    email: Yup.string()
      .required(`${t('field-rqd')} ${t('email-placeholder')}`)
      .email(t('email-validation')),
  });



  const submitValues = (values) => {
    setError('')
    postAPI('help/forgot-username', values).then((data) => {
      if (data.data === 'Success') {
        setSuccess(true)
        setEmail(values.email)
        return
      }

      if (data.error_id) {
        setError(data.user_message)

        return
      }

    })

  }



  return (
    <>
      <AppHeader />
      <div id={'resetPasswordScreen'}>
        <Grid item xs={12} sm={9} md={8} lg={6} className='contentWrapper' >
          <h4 className="screen-title">{t('reset-username.title')}</h4>
          <h5 className="screen-subtitle">{t('reset-username.subtitle')}</h5>
          {success ? <p className='subHead'><Trans i18nKey="mail-sent">
            {t('reset-password.email-sent-to')} {getEmail(email)}
          </Trans></p> :
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={submitValues}>{
                (props) =>
                  <ResetUsernameForm {...props} error={error} />
              }
            </Formik>}
          <Loader />
        </Grid>
      </div>
    </>
  );
}


export default withTranslation()(ResetUsername);
