import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import { DefaultButton } from '../../ui-components/button/index';
import { FormRowWithLabel, FormLabel, FormSpacer, ErrorMessage } from '../../ui-components/formRows/formRows';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import "react-datepicker/dist/react-datepicker.css";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import clsx from 'clsx';
// import { Field, getIn } from 'formik';
import { useTranslation } from "react-i18next";
import './aboutYou.css'
import FocusUtils from "../../common/focusUtils";
import DOB from '../../ui-components/dob';
import { keyPressCommon } from '../../common/constants'

const useStyles = makeStyles(() => ({
    root: {
        '& > *': {
        },
        center: {
            margin: 'auto'
        },
        width: '100%',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    icon: {
        fontFamily: '"Museo Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important',
        fontSize: '15px',
        width: '100%',
        // height: 'auto',
        padding: '5px 0px',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: 'orange',
        color: 'white',
        'input:hover ~ &': {
            backgroundColor: 'orange',
        },
    },

}));

const AboutYouForm = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        values: {
            address1,
            address2,
            city,
            mobile,
            display_name,
            postcode,
            gender
        },
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        getAddressesForPostCode,
        addresses,
        selectAddress,
        validateField,
        fieldChange,
        numberError,
        postCodeError
    } = props;

    const handleNumberChange = (e, type) => {
        handleBlur(e)
        validateField(e.target.value, type,false)
    }

    const handleNumberOnChange = (e, type) => {
        e.preventDefault()
        handleChange(e)
        fieldChange(type)
    }

    const keyPress = (e) => {
        keyPressCommon(e, focus)
    }

    let focus
    useEffect(
        () => {
            focus = FocusUtils()
        }
    )

    return (
        <Card className={classes.cardcustom}>
            <div className='padDiv'>
                <FormRowWithLabel>
                    <FormLabel>{t('post-code')}</FormLabel>
                    <div className="postcode-component">
                        <TextField id="postcode"
                            name="postcode"
                            placeholder={t('post-code-placeholder')}
                            // helperText={touched.postcode ? errors.postcode : ""}
                            error={touched.postcode && Boolean(errors.postcode)}
                            value={postcode}
                            onKeyDown={keyPress}
                            onChange={(e) => handleNumberOnChange(e, "postcode")}
                            variant="filled"
                            onBlur={handleBlur}
                            autoComplete="billing postal-code"
                        />
                        <DefaultButton onClick={() => getAddressesForPostCode(postcode)}
                            name={t('find-address')}
                            id="postcode-btn"
                            address={true} />
                    </div>
                    <ErrorMessage name="postcode" error={postCodeError} />

                </FormRowWithLabel>
                {addresses && addresses.length > 0 && <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='address-container center'>
                        <ul className='address-selection' >
                            {addresses && addresses.map((address, i) => {
                                return (<li key={i} onClick={() => selectAddress(address, postcode, values)}>
                                    {address.address1}, {address.address2}, {address.city}

                                </li>)
                            })}
                        </ul>
                    </Grid>
                </Grid>}
                <FormRowWithLabel>
                    <FormLabel>{t('street-address-1')}</FormLabel>
                    <TextField id="address1"
                        name="address1"
                        placeholder={t('street-address-1-placeholder')}
                        helperText={touched.address1 ? errors.address1 : ""}
                        error={touched.address1 && Boolean(errors.address1)}
                        value={address1}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing address-line1"
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel optional='yes'>{t('street-address-2')}</FormLabel>
                    <TextField id="address2"
                        name="address2"
                        placeholder={t('street-address-2-placeholder')}
                        helperText={touched.address2 ? errors.address2 : ""}
                        error={touched.address2 && Boolean(errors.address2)}
                        value={address2}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing address-line2"
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel>{t('city')}</FormLabel>
                    <TextField id="city"
                        name="city"
                        placeholder={t('city-placeholder')}
                        helperText={touched.city ? errors.city : ""}
                        error={touched.city && Boolean(errors.city)}
                        value={city}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing locality"
                    />
                </FormRowWithLabel>
                <FormSpacer />
                <FormRowWithLabel>
                    <FormLabel>{t('mobile-number')}</FormLabel>
                    <TextField id="mobile"
                        name="mobile"
                        placeholder={t('mobile-number-placeholder')}
                        // helperText={touched.mobile ? errors.mobile : ""}
                        error={touched.mobile && Boolean(errors.mobile)}
                        value={mobile || "07"}
                        onKeyDown={keyPress}
                        onChange={(e) => handleNumberOnChange(e, "number")}
                        variant="filled"
                        onBlur={(e) => handleNumberChange(e, "number")}
                        maxLength={11}
                        autoComplete="tel"
                    />
                    <ErrorMessage name="mobile" error={numberError} />

                </FormRowWithLabel>
                <FormSpacer />


                <DOB {...props} handleOnChange={fieldChange} dobChange={() => validateField(`${values.day}-${values.month}-${values.year}`, 'age',false)
                } />

                <FormRowWithLabel>
                    <FormLabel optional='yes' noMargin={true}>{t('gender')}</FormLabel>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <RadioGroup aria-label="gender"
                            row
                            id="gender"
                            name="gender"
                            value={gender}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            variant="filled"
                            onBlur={handleBlur}
                        >

                            <FormControlLabel value="FEMALE" control={<Radio color="default" />} label={t('female')} />
                            <FormControlLabel value="MALE" control={<Radio color="default" />} label={t('male')} />
                        </RadioGroup>
                    </FormControl>
                    <ErrorMessage name="gender" />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel>{t('display-name')}</FormLabel>
                    <TextField id="display_name"
                        name="display_name"
                        placeholder={`${gender === 'FEMALE' ? 'Mum' : 'Dad'}`}
                        helperText={touched.display_name ? errors.display_name : ""}
                        error={touched.display_name && Boolean(errors.display_name)}
                        value={display_name}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <br />
                    <span className="help">{t('display-name-placeholder') + ' ' + (gender === 'FEMALE' ? 'Mum' : 'Dad')}</span>
                </FormRowWithLabel>
                <FormSpacer />
                <FormRowWithLabel className='next-button-container'>
                    <FormLabel />
                    <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                </FormRowWithLabel>
                <FormSpacer />
                <FormSpacer />
            </div>
        </Card>
    );
}

export default AboutYouForm;
