import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { DefaultButton } from '../../ui-components/button/index';
import { getIn, FieldArray } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
// import "react-datepicker/dist/react-datepicker.css";
// import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import { FormRowWithLabel, FormLabel, FormSpacer, ErrorMessage } from '../../ui-components/formRows/formRows';
import FocusUtils from "../../common/focusUtils";
import Checkbox from '@material-ui/core/Checkbox';
import { getAPI } from '../../common/api'
import { CHILD_MAX_YEAR, CHILD_MIN_YEAR } from '../../common/constants';
import TermsChecklist from '../account/termsChecklist';
import DOB from '../../ui-components/dob';

const useStyles = makeStyles({

    checkbox: {
        marginTop: '30px'
    },
    root: {
        width: '100%',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& hr': {
            margin: '3em 0'
        }
    },

    icon: {
        fontFamily: '"Museo Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important',
        fontSize: '15px',
        width: '100%',
        // height: 'auto',
        padding: '5px 0px',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: 'orange',
        color: 'white',
        'input:hover ~ &': {
            backgroundColor: 'orange',
        },
    },
    btnIndicator: {
        fontSize: '25px',
        margin: '0 12px',
        fontWeight: 'bold',
        position: 'absolute',
        top: '-5px'
    },
    btnIndicatorAdd: {
        fontSize: '20px',
        marginLeft: '-20px',
        fontWeight: 'bold',
        position: 'absolute',
        top: '-1px'
    },
    cardDesign: {
        width: '100%',
        display: 'grid',
        maxWidth: '392px',
        margin: 'auto'
    }

});


const OrderForm = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [invitecodeValue, setInviteCode] = useState('')
    const [invitecodeMessage, setInviteCodeMessage] = useState('')
    const {
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        validateField,
        // fieldChange,
        updateUsername,
        setUsername,
        values,
        error,
        validateEmail,
        emailError,
        emailChange,
        passwordSuggestion,
        setPasswordSuggestion,
        getAddressesForPostCode,
        addresses,
        selectAddress,
        fieldChange,
        numberError,
        postCodeError,
        values: {
            children,
            first_name,
            last_name,
            email,
            confirmEmail,
            password,
            source,
            marketing,
            gdpr,
            address1,
            address2,
            city,
            mobile,
            display_name,
            postcode,
            gender
        }
    } = props;

    useEffect(() => {
        if (source)
            refCodeApi(source)
    }, [source])


    const handleEmailChange = (e) => {
        handleBlur(e)
        validateEmail(e.target.value)
    }
    const handleEmailOnChange = (e) => {
        handleChange(e)
        emailChange(e.target.value)
    }

    const handleNumberChange = (e, type) => {
        handleBlur(e)
        validateField(e.target.value, type)
    }

    const handleNumberOnChange = (e, type) => {
        e.preventDefault()
        handleChange(e)
        fieldChange(type)
    }


    const handleFieldChange = (e, type, i) => {
        let value = e.target.value
        let tests = {
            'name': value => (value.length > 2),
            'username': value => (value.length > 1),
            'email': value => ((/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(value)),
            'childnumber': value => ((/^07[0-9]{9}$/).test(value)),
            'password': value => (value.length > 6)
        }
        let success = tests[type] && tests[type](value);
        handleBlur(e)
        if (success) {
            //props.saveChildren(children)
            validateField(e.target.value, type, i, values)
        }
    }
    const handleOnChange = (e, type, i, child, error, arrayHelpers) => {
        if (type === 'childage' && e.target.value.length > 4)
            return
        if (type === 'username')
            setUsername(i)

        if (type === 'name') {
            delete child.nameError
        }
        else if (type === 'childnumber') {
            delete child.numberError
        } else if (type === 'childage') {
            delete child.ageError
        } else if (type === 'email') {
            delete child.emailError
        } else if (type === 'username') {
            delete child.usernameError
        } else if (type === 'password') {
            delete child.passwordError
            delete child.passwordWarning
        }

        arrayHelpers.replace(i, { ...child })
        handleChange(e)

    }

    const keyPress = (e) => {
        if (focus && (
            e.keyCode === focus.KEYS.ENTER || (
                e.target.type === 'number' &&
                !focus.isNumeric(e.keyCode) &&
                !focus.isCursorMove(e.keyCode)
            ))) {
            e.preventDefault()
        }
    }

    const keyUp = (e) => {
        if (
            focus &&
            focus.isCompleted(e.target) &&
            focus.isNumeric(e.keyCode)
        ) {
            if (e.target.name.includes("year")) {
                if (CHILD_MIN_YEAR <= e.target.value && e.target.value <= CHILD_MAX_YEAR) {
                    focus.focusNext(e.target)
                }
            } else {
                focus.focusNext(e.target)
            }
            e.preventDefault()
        }
    }

    const dobChange = (e, i) => {
        handleBlur(e)
        const child = values.children[i]

        // prevent API call if the date doesn't satisfy the Pre-Conditions
        if (
            !child.day || child.day > 31 ||
            !child.month || child.month > 12 ||
            !child.year || child.year < CHILD_MIN_YEAR || child.year > CHILD_MAX_YEAR
        ) return

        validateField(`${child.day}-${child.month}-${child.year}`, 'childage', i, values)
    }

    const hasName = (child) => {
        let res = child.first_name ? child.first_name.replace(/\s+/g, '') : "" &&
            child.last_name ? child.last_name.replace(/\s+/g, '') : "";
        return res;
    }


    const checkReferralCode = (e) => {
        handleBlur(e)
        setInviteCodeMessage('')
        if (e.target.value === '') {
            setInviteCode('')
            return
        }
        refCodeApi(e.target.value)
    }

    const refCodeApi = (value) => {
        getAPI(`referrer/ref-${value}?channel_code=`).then(data => {
            switch (data.error_id) {
                case 'NOT_FOUND':
                    setInviteCode('fail')
                    break
                case 400:
                    setInviteCode('fail')
                    break
                default:
                    if (data.referral_link) {
                        setInviteCode('success')
                    }
                    if (data.campaign_details && data.campaign_details.campaign_type === 'marketing')
                        setInviteCodeMessage(data.campaign_details.campaign_text)
                    break
            }
        })
    }

    const checkPassword = (e) => {
        handleBlur(e)
        if (e.target.value.length < 8)
            return
        if (e.target.value === '') {
            setPasswordSuggestion('')
            return
        }
        let body = {
            password: e.target.value,
            user_inputs: [first_name, last_name, email]
        }
        props.checkPassword(body)
    }



    let focus
    useEffect(
        () => {
            focus = FocusUtils()
        }
    )
    const title = children.length > 1 ? t('about-your-child.title-plural') : t('about-your-child.title')
    const subtitle = children.length > 1 ? t('about-your-child.subtitle-plural') : t('about-your-child.subtitle')

    return (
        <Card className={classes.root}>
            <div className='padDiv'>
                {children.length > 0 && <h4 className="screen-title">{title}</h4>}
                {children.length > 0 && <h5 className="screen-subtitle">{subtitle}</h5>}
                <FieldArray
                    name="children"
                    render={(arrayHelpers) => (
                        <div >
                            {
                                children &&
                                children.map((child, index) => (
                                    <div key={index}>
                                        <FormRowWithLabel>
                                            <FormLabel>{`${index + 1}. ${t("child-first-name")}`}</FormLabel>
                                            <TextField
                                                id={`children.${index}.first_name`}
                                                name={`children.${index}.first_name`}
                                                placeholder={`${t("child's-placeholder")} ${t('first-name-placeholder')}`}
                                                helperText={touched.first_name ? errors.first_name : ""}
                                                error={touched.first_name && Boolean(errors.first_name)}
                                                value={child.first_name}
                                                onKeyDown={keyPress}
                                                onChange={handleChange}
                                                variant="filled"
                                                onBlur={(e) => handleFieldChange(e, 'name', index)}
                                                autoComplete={`child${index}.first_name`}
                                            />
                                            <ErrorMessage name={`children[${index}].first_name`} />
                                        </FormRowWithLabel>
                                        <FormRowWithLabel>
                                            <FormLabel>{`${t("child-last-name")}`}</FormLabel>
                                            <TextField id={`children.${index}.last_name`}
                                                name={`children.${index}.last_name`}
                                                placeholder={`${t("child's-placeholder")} ${t('last-name-placeholder')}`}
                                                error={getIn(errors, `children[${index}].last_name`) && getIn(touched, `children[${index}].last_name`)}
                                                value={child.last_name}
                                                onKeyDown={keyPress}
                                                onChange={handleChange}
                                                variant="filled"
                                                autoComplete={`child${index}.last_name`}
                                                onBlur={(e) => { handleBlur(e); updateUsername(index, values) }}
                                            />
                                            <ErrorMessage name={`children[${index}].last_name`} />
                                        </FormRowWithLabel>
                                        <FormRowWithLabel>
                                            <FormLabel>{t('gender')}</FormLabel>
                                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                                <RadioGroup aria-label="gender"
                                                    id={`children.${index}.gender`}
                                                    name={`children.${index}.gender`}
                                                    error={getIn(errors, `children[${index}].gender`) && getIn(touched, `children[${index}].gender`)}
                                                    onKeyDown={keyPress}
                                                    onChange={handleChange}
                                                    value={child.gender}
                                                    autoComplete={`child${index}.gender`}
                                                    row
                                                >
                                                    <FormControlLabel value="FEMALE" control={<Radio color="default" />} label={t('girl')} />
                                                    <FormControlLabel value="MALE" control={<Radio color="default" />} label={t('boy')} />
                                                    <FormControlLabel value="DONTSAY" control={<Radio color="default" />} label={t('prefer-not-to-say')} />
                                                </RadioGroup>
                                            </FormControl>
                                            <ErrorMessage name={`children[${index}].gender`} />
                                        </FormRowWithLabel>
                                        <FormRowWithLabel>
                                            <FormLabel>{t('date-of-birth')}</FormLabel>
                                            <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12}>
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <TextField id={`children.${index}.day`}
                                                        name={`children.${index}.day`}
                                                        placeholder='dd'
                                                        error={getIn(errors, `children[${index}].day`) && getIn(touched, `children[${index}].day`)}
                                                        value={child.day || child.day === 0 ? child.day : ''}
                                                        onKeyDown={keyPress}
                                                        onKeyUp={keyUp}
                                                        onChange={(e) => { if (e.target.value.length <= 2) { handleChange(e); } }}
                                                        type="number"
                                                        variant="filled"
                                                        onBlur={e => dobChange(e, index)}
                                                        maxLength={2}
                                                        autoComplete={`child${index}.dob.day`}
                                                    />
                                                    <ErrorMessage name={`children[${index}].day`} />
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <TextField id={`children.${index}.month`}
                                                        name={`children.${index}.month`}
                                                        placeholder='mm'
                                                        error={getIn(errors, `children[${index}].month`) && getIn(touched, `children[${index}].month`)}
                                                        value={child.month || child.month === 0 ? child.month : ''}
                                                        onKeyDown={keyPress}
                                                        onKeyUp={keyUp}
                                                        onChange={(e) => { if (e.target.value.length <= 2) { handleChange(e); } }}
                                                        type="number"
                                                        variant="filled"
                                                        onBlur={e => dobChange(e, index)}
                                                        maxLength={2}
                                                        autoComplete={`child${index}.dob.month`}
                                                    />
                                                    <ErrorMessage name={`children[${index}].month`} />
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <TextField id={`children.${index}.year`}
                                                        name={`children.${index}.year`}
                                                        placeholder='yyyy'
                                                        error={getIn(errors, `children[${index}].year`) && getIn(touched, `children[${index}].year`)}
                                                        value={child.year || child.year === 0 ? child.year : ''}
                                                        onKeyDown={keyPress}
                                                        onKeyUp={keyUp}
                                                        type="number"
                                                        onChange={(e) => handleOnChange(e, 'age', index, child, child.ageError, arrayHelpers)}
                                                        variant="filled"
                                                        onBlur={e => dobChange(e, index)}
                                                        maxLength={4}
                                                        autoComplete={`child${index}.dob.year`}
                                                    />
                                                    <ErrorMessage name={`children[${index}].year`} />
                                                </Grid>
                                            </Grid>
                                            {child.ageError && <ErrorMessage
                                                name={`children.${index}.first_name`}
                                                error={child.ageError} />}
                                        </FormRowWithLabel>
                                        <div className={hasName(child) ? "expandable expand" : "expandable collapse"}>
                                            <FormRowWithLabel >
                                                <FormLabel />
                                                <span className='help'>{t('why-child-credentials')}</span>
                                            </FormRowWithLabel>
                                            <FormRowWithLabel>
                                                <FormLabel>{t('user-name')}</FormLabel>
                                                <TextField id={`children.${index}.username`}
                                                    name={`children.${index}.username`}
                                                    placeholder={t('user-name-placeholder')}
                                                    error={getIn(errors, `children[${index}].username`) && getIn(touched, `children[${index}].username`)}
                                                    value={child.username}
                                                    onKeyDown={keyPress}
                                                    onChange={(e) => handleOnChange(e, 'username', index, child, child.usernameError, arrayHelpers)}
                                                    variant="filled"
                                                    onBlur={(e) => handleFieldChange(e, 'username', index)}
                                                    autoComplete={`child${index}.username`}
                                                />
                                                <ErrorMessage name={`children[${index}].username`} error={child.usernameError} />
                                            </FormRowWithLabel>
                                            <FormRowWithLabel>
                                                <FormLabel>{t('password')}</FormLabel>

                                                <TextField
                                                    id={`children.${index}.password`}
                                                    name={`children.${index}.password`}
                                                    type="password"
                                                    placeholder={t('password-placeholder')}
                                                    error={getIn(errors, `children[${index}].password`) && getIn(touched, `children[${index}].password`)}
                                                    value={child.password}
                                                    onKeyDown={keyPress}
                                                    onChange={(e) => handleOnChange(e, 'password', index, child, child.passwordError, arrayHelpers)}
                                                    onBlur={(e) => handleFieldChange(e, 'password', index)}
                                                />
                                                <ErrorMessage name={`children[${index}].password`} error={child.passwordError} />
                                            </FormRowWithLabel>
                                            {child.passwordWarning &&
                                                <FormRowWithLabel>
                                                    <FormLabel />
                                                    <span className='help'>{child.passwordWarning}</span>
                                                </FormRowWithLabel>}
                                        </div>


                                        <FormRowWithLabel expandOnClick={`Does ${child.first_name || 'the child'} use an email?`}
                                            collapse={child.email ? false : true}>
                                            <FormLabel optional='yes'>{`${t("child-email")}`}</FormLabel>
                                            <TextField id={`children.${index}.email`}
                                                name={`children.${index}.email`}
                                                placeholder={t('email-placeholder')}
                                                error={getIn(errors, `children[${index}].email`) && getIn(touched, `children[${index}].email`)}
                                                value={child.email}
                                                onKeyDown={keyPress}
                                                onChange={(e) => handleOnChange(e, 'email', index, child, child.emailError, arrayHelpers)}
                                                variant="filled"
                                                onBlur={(e) => handleFieldChange(e, 'email', index)}
                                                autoComplete={`child${index}.email`}
                                            />
                                            <ErrorMessage name={`children[${index}].email`} error={child.emailError} />
                                        </FormRowWithLabel>
                                        <FormRowWithLabel expandOnClick={`Does ${child.first_name || 'the child'} have a mobile phone?`}
                                            collapse={child.mobile ? false : true}>
                                            <FormLabel optional='yes'>{`${t("child-mobile-number")}`}</FormLabel>
                                            <TextField id={`children.${index}.mobile`}
                                                name={`children.${index}.mobile`}
                                                placeholder={t('mobile-number-placeholder')}
                                                error={getIn(errors, `children[${index}].mobile`) && getIn(touched, `children[${index}].mobile`)}
                                                value={child.mobile}
                                                onKeyDown={keyPress}
                                                onChange={(e) => handleOnChange(e, 'childnumber', index, child, child.numberError, arrayHelpers)}
                                                maxLength={11}
                                                variant="filled"
                                                onBlur={(e) => handleFieldChange(e, 'childnumber', index)}
                                                autoComplete={`child${index}.tel`}
                                            />
                                            <ErrorMessage name={`children[${index}].mobile`} error={child.numberError} />

                                        </FormRowWithLabel>
                                        {index < children.length - 1 && <hr />
                                        }

                                    </div>
                                ))
                            }

                        </div>
                    )
                    }
                />

                <h4 className="screen-title">{t('account.title')}</h4>
                <h5 className="screen-subtitle">{t('account.subtitle')}</h5>
                <FormRowWithLabel>
                    <FormLabel>{t('first-name')}</FormLabel>
                    <TextField id="first_name"
                        name="first_name"
                        placeholder={t('first-name')}
                        helperText={touched.first_name ? errors.first_name : ""}
                        error={touched.first_name && Boolean(errors.first_name)}
                        value={first_name}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="given-name"
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel>{t('last-name')}</FormLabel>
                    <TextField id="last_name"
                        name="last_name"
                        placeholder={t('last-name')}
                        helperText={touched.last_name ? errors.last_name : ""}
                        error={touched.last_name && Boolean(errors.last_name)}
                        value={last_name}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="family-name"
                    />
                </FormRowWithLabel>
                <FormSpacer />
                <FormRowWithLabel>
                    <FormLabel>{t('email')}</FormLabel>
                    <TextField id="email"
                        name="email"
                        placeholder={t('email')}
                        error={(touched.email && Boolean(errors.email)) || emailError}
                        value={email}
                        onKeyDown={keyPress}
                        onChange={handleEmailOnChange}
                        variant="filled"
                        onBlur={handleEmailChange}
                        autoComplete="email"
                    />
                    <ErrorMessage name="email" error={emailError} />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel></FormLabel>
                    <TextField id="confirmEmail"
                        name="confirmEmail"
                        placeholder={t('confirm-email')}
                        helperText={touched.confirmEmail ? errors.confirmEmail : ""}
                        error={touched.confirmEmail && Boolean(errors.confirmEmail)}
                        value={confirmEmail}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                    />
                </FormRowWithLabel>
                <FormSpacer />
                <FormRowWithLabel>
                    <FormLabel>{t('password')}</FormLabel>
                    <TextField
                        id="password"
                        name="password"
                        type="password"
                        placeholder={t('password')}
                        error={(touched.password && Boolean(errors.password)) || passwordSuggestion}
                        value={password}
                        onKeyDown={keyPress}
                        onChange={(e) => { setPasswordSuggestion(''); handleChange(e) }}
                        onBlur={checkPassword}
                    />
                    <ErrorMessage name="password" error={passwordSuggestion} />
                </FormRowWithLabel>


                <FormRowWithLabel>
                    <FormLabel>{t('post-code')}</FormLabel>
                    <div className="postcode-component">
                        <TextField id="postcode"
                            name="postcode"
                            placeholder={t('post-code-placeholder')}
                            // helperText={touched.postcode ? errors.postcode : ""}
                            error={touched.postcode && Boolean(errors.postcode)}
                            value={postcode}
                            onKeyDown={keyPress}
                            onChange={(e) => handleNumberOnChange(e, "postcode")}
                            variant="filled"
                            onBlur={handleBlur}
                            autoComplete="billing postal-code"
                        />
                        <DefaultButton onClick={() => getAddressesForPostCode(postcode)}
                            name={t('find-address')}
                            id="postcode-btn"
                            address={true} />
                    </div>
                    <ErrorMessage name="postcode" error={postCodeError} />

                </FormRowWithLabel>
                {addresses && addresses.length > 0 && <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='address-container center'>
                        <ul className='address-selection' >
                            {addresses && addresses.map((address, i) => {
                                return (<li key={i} onClick={() => selectAddress(address, postcode, values)}>
                                    {address.address1}, {address.address2}, {address.city}

                                </li>)
                            })}
                        </ul>
                    </Grid>
                </Grid>}
                <FormRowWithLabel>
                    <FormLabel>{t('street-address-1')}</FormLabel>
                    <TextField id="address1"
                        name="address1"
                        placeholder={t('street-address-1-placeholder')}
                        helperText={touched.address1 ? errors.address1 : ""}
                        error={touched.address1 && Boolean(errors.address1)}
                        value={address1}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing address-line1"
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel optional='yes'>{t('street-address-2')}</FormLabel>
                    <TextField id="address2"
                        name="address2"
                        placeholder={t('street-address-2-placeholder')}
                        helperText={touched.address2 ? errors.address2 : ""}
                        error={touched.address2 && Boolean(errors.address2)}
                        value={address2}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing address-line2"
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel>{t('city')}</FormLabel>
                    <TextField id="city"
                        name="city"
                        placeholder={t('city-placeholder')}
                        helperText={touched.city ? errors.city : ""}
                        error={touched.city && Boolean(errors.city)}
                        value={city}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                        autoComplete="billing locality"
                    />
                </FormRowWithLabel>
                <FormSpacer />
                <FormRowWithLabel>
                    <FormLabel>{t('mobile-number')}</FormLabel>
                    <TextField id="mobile"
                        name="mobile"
                        placeholder={t('mobile-number-placeholder')}
                        // helperText={touched.mobile ? errors.mobile : ""}
                        error={touched.mobile && Boolean(errors.mobile)}
                        value={mobile || "07"}
                        onKeyDown={keyPress}
                        onChange={(e) => handleNumberOnChange(e, "number")}
                        variant="filled"
                        onBlur={(e) => handleNumberChange(e, "number")}
                        maxLength={11}
                        autoComplete="tel"
                    />
                    <ErrorMessage name="mobile" error={numberError} />

                </FormRowWithLabel>
                <FormSpacer />


                <DOB {...props} handleOnChange={fieldChange} dobChange={() => validateField(`${values.day}-${values.month}-${values.year}`, 'age')
                } />

                <FormRowWithLabel>
                    <FormLabel optional='yes' noMargin={true}>{t('gender')}</FormLabel>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <RadioGroup aria-label="gender"
                            row
                            id="gender"
                            name="gender"
                            value={gender}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            variant="filled"
                            onBlur={handleBlur}
                        >

                            <FormControlLabel value="FEMALE" control={<Radio color="default" />} label={t('female')} />
                            <FormControlLabel value="MALE" control={<Radio color="default" />} label={t('male')} />
                        </RadioGroup>
                    </FormControl>
                    <ErrorMessage name="gender" />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <FormLabel>{t('display-name')}</FormLabel>
                    <TextField id="display_name"
                        name="display_name"
                        placeholder={`${gender === 'FEMALE' ? 'Mum' : 'Dad'}`}
                        helperText={touched.display_name ? errors.display_name : ""}
                        error={touched.display_name && Boolean(errors.display_name)}
                        value={display_name}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={handleBlur}
                    />
                </FormRowWithLabel>
                <FormRowWithLabel>
                    <br />
                    <span className="help">{t('display-name-placeholder') + ' ' + (gender === 'FEMALE' ? 'Mum' : 'Dad')}</span>
                </FormRowWithLabel>
                <FormSpacer />

                <FormRowWithLabel>
                    <FormLabel optional='yes'>{t('invite-code')}</FormLabel>
                    <TextField
                        name="source"
                        placeholder={t('invite-code')}
                        id='referral-code'
                        className={invitecodeValue}
                        helperText={touched.source ? errors.source : ""}
                        error={touched.source && Boolean(errors.source)}
                        value={source}
                        onKeyDown={keyPress}
                        onChange={handleChange}
                        variant="filled"
                        onBlur={checkReferralCode}

                    />

                </FormRowWithLabel>
                <FormRowWithLabel>
                    <></>
                    {invitecodeValue !== 'success' && <span className='help'>{t('invite-code-note')}</span>
                    }
                    {invitecodeValue === 'success'
                        && <>{invitecodeMessage ? <p className="referral-input-success">
                            <b>{invitecodeMessage}</b>
                        </p>
                            : <p className="referral-input-success">
                                <b>{t('invite-code-success-note1')}</b>
                                {t('invite-code-success-note2')}
                            </p>}</>}
                </FormRowWithLabel>

                <FormSpacer />
                <FormRowWithLabel className='checkbox'>
                    <FormLabel />
                    < FormControlLabel
                        id="gdprLabel"
                        control={< Checkbox checked={gdpr}
                            onChange={handleChange}
                            name="gdpr"
                            id="gdpr"
                            error={touched.gdpr && Boolean(errors.gdpr)} />}
                        label={t('account.dataProcessingConsent')}
                        error={touched.gdpr && Boolean(errors.gdpr)}
                    />
                    <TermsChecklist />
                    <ErrorMessage name='marketing' />
                    <ErrorMessage name='gdpr' />
                </FormRowWithLabel>
                <FormRowWithLabel className='checkbox'>
                    <FormLabel />
                    < FormControlLabel id="marketingLabel"
                        control={< Checkbox checked={marketing} onChange={handleChange} name="marketing"
                            id="marketing"
                            error={touched.marketing && Boolean(errors.marketing)} />}
                        label={t('account.marketingConsent')}
                        error={touched.marketing && Boolean(errors.marketing)}
                    />
                </FormRowWithLabel>

                {error && <FormRowWithLabel>
                    <FormLabel></FormLabel>
                    <p className='errorMessage'>{error}</p>
                </FormRowWithLabel>}
                <FormSpacer />
                <FormRowWithLabel className='next-button-container'>
                    <FormLabel />
                    <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                </FormRowWithLabel>
                <FormSpacer />
                <FormSpacer />



            </div >
        </Card >
    );
}
const mapStateToProps = state => ({
    Kyc: state.kyc,
    kids: state.children
})

const mapDispatchToProps = dispatch => {
    return {
        // saveChildren: (value) => dispatch({ type: "SET_CHILDREN", value }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderForm));
