import store from "../store/store";

const BASE_URL = window.API || "/api/";

/*
internal method wrapped with events to signal that the API is busy
and the UI should display the Loader layer
 */
async function fetchWithLoader(path, init, blockUI = true) {
  setTimeout(function () {
    store.dispatch({ type: "API_BUSY", blockUI: blockUI });
  }, 500);
  try {
    const response = await fetch(BASE_URL + path, init);
    setTimeout(function () {
      store.dispatch({ type: "API_IDLE", blockUI: blockUI });
    }, 500);
    if (response.status === 500 || response.status === 502) {
      store.dispatch({
        type: "API_FAIL",
        value: `${response.status} Bad response from server`,
      });
      window.location.href =
        window.location.origin + `/v2/error/${response.status}`;
    }
    return response;
  } catch (e) {
    console.error(e);
    // showing random error in UI, that's why commented
    // store.dispatch({
    //   type: 'API_FAIL', value: e.message
    // });
  }
}

export async function postAPI(path, body, gifter, blockUI = true) {
  const response = await fetchWithLoader(
    path,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
    blockUI
  );
  if (!response) {
    store.dispatch({
      type: "API_FAIL",
      value: "SERVER_ERROR",
    });
    return false;
  }

  if (gifter && response.status === 401) {
    return { error_id: "401" };
  }
  if (response.status === 401) {
    clearStorage();
  }
  if (path === "login") {
    let cookie = response.headers.get("Set-Cookie");
    localStorage.setItem("Set-Cookie", cookie);
  }
  if (path === "logout") {
    clearStorage(true);
    return;
  }
  let data = {};
  try {
    data = await response.json();
  } catch (e) {
    if (response.status === 204 || response.status === 200)
      data = { data: "Success" };
    else data = { error_id: "ERROR" };
  }
  return data;
}
export async function scaLogin(path, body, nonce, blockUI = true) {
  const response = await fetchWithLoader(
    path,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        nonce: nonce,
      },
      body: JSON.stringify(body),
    },
    blockUI
  );

  if (!response) return;

  if (response.status === 401) {
    clearStorage();
  }

  let cookie = response.headers.get("Set-Cookie");
  localStorage.setItem("Set-Cookie", cookie);
  let data = {};
  data = await response.json();
  if (response.status === 405) data = { error_id: "SCA_DISABLED" };

  return data;
}
export async function scaChallenge(path, data, nonce, blockUI = true) {
  const response = await fetchWithLoader(
    path,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        nonce: nonce,
      },
      body: JSON.stringify(data),
    },
    blockUI
  );

  if (response.status === 401) clearStorage();
  let cookie = response.headers.get("Set-Cookie");
  localStorage.setItem("Set-Cookie", cookie);
  return response && response.json();
}

export async function putAPI(path, body, blockUI = true) {
  const response = await fetchWithLoader(
    path,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
    blockUI
  );

  if (response.status === 401) clearStorage();
  let data = {};
  try {
    data = await response.json();
  } catch (e) {
    if (response.status === 204 || response.status === 200)
      data = { error_id: "NO_CONTENT" };
    else data = { error_id: "ERROR" };
  }
  return data;
}

export async function deleteAPI(path, blockUI = true) {
  const response = await fetchWithLoader(
    path,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
    blockUI
  );
  if (response.status === 401) clearStorage();
  let data = {};
  try {
    data = await response.json();
  } catch (e) {
    if (response.status === 204 || response.status === 200)
      data = { error_id: "NO_CONTENT" };
    else data = { error_id: "ERROR" };
  }
  return data;
}

export async function getAPI(path, version, isGifter, blockUI = true) {
  const response = await fetchWithLoader(
    path,
    version
      ? {
          method: "GET",
          headers: {
            "OSPER-API-VERSION": "13.1.0",
          },
        }
      : {
          method: "GET",
        },
    blockUI
  );
  let data = {};
  if (response && response.status === 401) {
    if (isGifter) return { error_id: "UNAUTHENTICATED" };
    clearStorage();
  }
  try {
    data = await response.json();
  } catch (e) {
    if (!response) data = { error_id: "ERROR" };
    else if (response.status === 204 || response.status === 200)
      data = { error_id: "NO_CONTENT" };
    else if (response.status === 405) data = { error_id: "SCA_DISABLED" };
    else if (response.status === 429) data = { error_id: "TOO_MANY_REQUEST" };
    else data = { error_id: "ERROR" };
  }
  return data;
}

export async function getDownloadFile(path, version, blockUI = true) {
  const response = await fetchWithLoader(
    path,
    {
      method: "GET",
      headers: {
        "OSPER-API-VERSION": "13.1.0",
      },
    },
    blockUI
  ).then((r) => {
    if (r && r.status === 401) clearStorage();
    else return r.blob();
  });

  return response;
}

export const geClientToken = async () => {
  const data = await getAPI("braintree_token");
  return data;
};

const clearStorage = (isLogout) => {
  store.dispatch({ type: "LOGIN_FAILED" });
  store.dispatch({
    type: "ADD_STEPS",
    value: [],
  });
  if (!isLogout) {
    store.dispatch({
      type: "API_FAIL",
      value: localStorage.getItem("account_id")
        ? "Please log in again."
        : "Please log in.",
    });
  }

  localStorage.clear();
  sessionStorage.clear();
  window.location.href = window.location.origin + "/v2/login";
};
