import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../ui-components/textField/textField';
import { DefaultButton } from '../../ui-components/button/index';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import { FormRowWithLabel, FormSpacer, FormLabel } from '../../ui-components/formRows/formRows';

const useStyles = makeStyles(() => ({
    root: {
        '& > *': {
        },
    },
}));

const ForgotPasswordForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        values: {
            username_or_email,
        },
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        error
    } = props;
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }

    return (
        <Card className={classes.cardcustom}>

            <div className='padDiv'>
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}>
                    <FormRowWithLabel>
                        <FormLabel>{t('osper-username')}</FormLabel>
                        <TextField id="username_or_email"
                            name="username_or_email"
                            placeholder={t('osper-username')}
                            helperText={touched.username_or_email ? errors.username_or_email : ""}
                            error={touched.username_or_email && Boolean(errors.username_or_email)}
                            value={username_or_email}
                            onKeyDown={keyPress}
                            onChange={handleChange}
                            variant="filled"
                            onBlur={handleBlur}
                        />
                    </FormRowWithLabel>
                    {error && <FormRowWithLabel>
                        <FormLabel></FormLabel>
                        <p className='errorMessage'>{error}</p>
                    </FormRowWithLabel>}
                    <FormSpacer/>
                    <FormRowWithLabel className='next-button-container'>
                        <FormLabel />
                        <DefaultButton onClick={handleSubmit} name={t('submit')} id='submit-btn'></DefaultButton>
                    </FormRowWithLabel>

                </form>
            </div>
        </Card>
    );
}

export default ForgotPasswordForm;
